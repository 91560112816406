import React, { createContext } from 'react';
import { notification } from 'antd';
import IArticle from '../containers/home/IArticle';
import WhishlistService from '../containers/home/WishlistService';
import IWishlist from '../containers/home/IWishlist';
import IWishlistArticle from '../containers/home/IWishlistArticles';
import IWishlistTotal from '../containers/home/IWishlistTotal';
import ISalesOrder from '../containers/shoppingcart/ISalesOrder';
import ShippingMethodService from '../containers/shoppingcart/ShippingMethodService';
import ITecdocSearch from '../containers/tecdoc/model/ITecdocSearch';
import ICustomProfil from '../containers/tecdoc/model/ICustomProfil';
import ICatalogSearch from '../containers/catalog/model/ICatalogSearch';
import IFilterQuery from './IFilterQuery';
import IShippingMethod from '../containers/shoppingcart/IShippingMethod';

const initWishlistTotal = {
  "totalGrossAmount": 0,
  "totalDiscount": 0,
  "totalAmountExclTaxes": 0,
  "totalVat": 0,
  "totalAmountInclTaxes": 0,
  "totalGrossAmountSelected": 0,
  "totalDiscountSelected": 0,
  "totalAmountExclTaxesSelected": 0,
  "totalVatSelected": 0,
  "totalAmountInclTaxesSelected": 0,
  "articleCount": 0,
  "totalQuantity": 0,
  "articleCountSelected": 0,
  "totalQuantitySelected": 0
}

const initFilterQuery: IFilterQuery = {
  extended: false,
  families: [],
  manufacturers: []
}

const initTecdocSearch: ITecdocSearch = {
  queryType: '',
  query: {
    plateNumber: '',
    mineType: '',
    motorCode: '',
    vin: '',
  },
  vehicle: undefined,
  searchStructure: undefined
}

const initProfil: ICustomProfil = {
  id: -1,
  name: "",
  uid: "",
  searchByPlateNumber: false,
  searchByMineType: false,
  state: "",
  customAccount: undefined
}

const initCatalogSearch: ICatalogSearch = {
  queryType: '',
  level1Id: undefined,
  level2Id: undefined,
  level3Id: undefined,
  level1Name: undefined,
  level2Name: undefined,
  level3Name: undefined,
  level4Id: undefined,
  level4Name: undefined,
  level5Id: undefined,
  level5Name: undefined,
  modelYear: undefined
}

export const BasketContext = createContext({

  loading: false,

  userRole: '',
  setUserRole: (role: string) => { },

  query: '',
  setQuery: (query: string) => { },
  setAdvancedQuery: (advancedQuery: boolean) => { },
  /*
  0: no search 
  1: query search
  2: TECDOC search
  3: Catalogue F1
  4: ETAI serach
  */
  typeSearch: 0,
  advancedQuery: false,
  filterQuery: initFilterQuery,
  setTypeSearch: (typeSearch: number) => { },
  setFilterQuery: (filterQuery: IFilterQuery) => { },
  clearFilterQuery: () => { },

  tecdocSearch: initTecdocSearch,
  setTecdocSearch: (tecdocSearch: ITecdocSearch | undefined) => { },
  clearTecdocSearch: () => { },

  catalogSearch: initCatalogSearch,
  setCatalogSearch: (catalogSearch: ICatalogSearch | undefined) => { },
  clearCatalogSearch: () => { },

  customProfil: initProfil,
  setCustomProfil: (customProfil: ICustomProfil) => { },

  selectedArticle: undefined,
  setSelectedArticle: (selectedArticle: IArticle) => { },
  articleView: 'list',
  setArticleView: (view: string) => { },

  favoritePlatformShippingMethods: [],
  wishlistTotal: initWishlistTotal,
  basketContent: [],
  articleWishlistList: [],
  salesOrder: undefined,
  listShippingMethods: [],
  addArticle: (item: IArticle, quantity: number, platformId: number, sourceWishlistArticleId: number | undefined) => { },
  updateArticle: (item: IWishlistArticle, wishlistId: string, quantity: number) => { },
  deleteArticle: (wishlistArticleId: number) => { },
  loadUserWhishList: () => { },
  refreshAvailability: () => { },
  refreshWishlistTotal: () => { },
  refreshWishlist: () => { },
  updateSalesOrder: (salesOrder: ISalesOrder) => { },
  clearSalesOrder: () => { },
  updateWhishlist: (wishlist: IWishlist) => { }
});


class BasketProvider extends React.Component {

  private wishlistService: WhishlistService = new WhishlistService();
  private shippingMethodService: ShippingMethodService = new ShippingMethodService();

  state = {
    loading: false,

    userRole: '',
    setUserRole: (role: string) => this.setState({ userRole: role }),

    query: '',
    setQuery: (query: string) => this.setState({ query: query }),
    setAdvancedQuery: (advancedQuery: boolean) => this.setState({ advancedQuery: advancedQuery }),

    typeSearch: 0,
    setTypeSearch: (typeSearch: number) => this.setState({ typeSearch: typeSearch }),
    advancedQuery: false,
    filterQuery: initFilterQuery,
    setFilterQuery: (filterQuery: IFilterQuery) => this.setState({ filterQuery: filterQuery }),
    clearFilterQuery: () => this.setState({ filterQuery: initFilterQuery }),

    tecdocSearch: initTecdocSearch,
    setTecdocSearch: (tecdocSearch: ITecdocSearch | undefined) => this.setState({ tecdocSearch: tecdocSearch }),
    clearTecdocSearch: () => {
      this.setState({
        tecdocSearch: {
          refresh: undefined,
          queryType: '',
          query: undefined,
          vehicle: undefined,
          searchStructure: undefined
        }
      });
    },

    catalogSearch: initCatalogSearch,
    setCatalogSearch: (catalogSearch: ICatalogSearch | undefined) => this.setState({ catalogSearch: catalogSearch }),
    clearCatalogSearch: () => {
      this.setState({
        catalogSearch: {
          queryType: '',
          level1Id: undefined,
          level2Id: undefined,
          level3Id: undefined,
          level1Name: undefined,
          level2Name: undefined,
          level3Name: undefined,
          level4Id: undefined,
          level4Name: undefined,
          level5Id: undefined,
          level5Name: undefined,
          modelYear: undefined
        }
      });
    },

    customProfil: initProfil,
    setCustomProfil: (customProfil: ICustomProfil) => this.setState({ customProfil: customProfil }),

    selectedArticle: undefined,
    setSelectedArticle: (selectedArticle: IArticle) => this.setState({ selectedArticle: selectedArticle }),
    articleView: 'list',
    setArticleView: (view: string) => this.setState({ articleView: view }),

    favoritePlatformShippingMethods: [],
    wishlistTotal: initWishlistTotal,
    basketContent: [],
    articleWishlistList: [],
    salesOrder: undefined,
    listShippingMethods: [],
    addArticle: (item: IArticle, quantity: number, platformId: number, sourceWishlistArticleId: number | undefined) => {
      this.addArticle(item, quantity, platformId, sourceWishlistArticleId);
    },
    updateArticle: (item: IWishlistArticle, wishlistId: string, quantity: number) => {
      this.updateArticle(item, wishlistId, quantity);
    },
    deleteArticle: (wishlistArticleId: number) => {
      this.deleteArticle(wishlistArticleId);
    },
    loadUserWhishList: (): Promise<any> => {
      return this.loadUserWhishList();
    },
    refreshAvailability: () => {
      this.refreshAvailability();
    },
    refreshWishlistTotal: () => {
      this.refreshWishlistTotal();
    },
    refreshWishlist: () => {
      this.refreshWishlist();
    },
    setLoading: (loading: boolean) => this.setState({ loading: loading }),
    updateSalesOrder: (salesOrder: ISalesOrder) => this.setState({ salesOrder: salesOrder }),
    clearSalesOrder: () => this.setState({ salesOrder: undefined }),
    updateWhishlist: (wishlist: IWishlist) => {
      this.updateWhishlist(wishlist);
    },
  };

  constructor(props: {}) {
    super(props);
    this.loadShippingMethods();
  }

  render() {
    return (
      <BasketContext.Provider value={this.state}>
        {this.props.children}
      </BasketContext.Provider>
    );
  }

  private deleteArticle = (wishlistArticleId: number) => {

    this.setState({
      loading: true,
    });

    this.wishlistService.deleteArticleWishlist(wishlistArticleId)
      .then((response: any) => {

        if (response.status === 204) {
          this.wishlistService.findByCustomerId()
            .then((data: any) => {

              this.totalCalculation(data);
            });
        }

      });
  }

  private updateArticle = (item: IWishlistArticle, wishlistId: string, quantity: number) => {

    this.setState({
      loading: true,
    });

    this.wishlistService.updateArticleWhishlist(item, wishlistId, quantity)
      .then((response: any) => {

        if (response.status === 200) {

          this.wishlistService.findByCustomerId()
            .then((data: any) => {

              this.totalCalculation(data);
            });
        }
        else {
          notification.error({ message: 'Panier', description: 'Impossible de mettre à jour le panier.' });

          this.setState({
            loading: false,
          });
        }

      })
      .catch((err: any) => {
        notification.error({ message: 'Panier', description: 'Impossible de mettre à jour le panier.' });
        this.setState({
          loading: false,
        });
      });

  }

  private addArticle = (item: IArticle, quantity: number, platformId: number, sourceWishlistArticleId: number | undefined) => {

    if (quantity === undefined) {
      quantity = 1;
    }

    this.setState({
      loading: true,
    });

    this.wishlistService.addArticleWhishlist(item, quantity, platformId, sourceWishlistArticleId)
      .then((response: any) => {

        if (response.status === 201) {
          this.wishlistService.findByCustomerId()
            .then((data: any) => {
              notification.info({ message: 'Panier', description: 'Article ajouté au panier.', duration: 2 });
              this.loadShippingMethods();
              this.totalCalculation(data);
            });
        }
        else {
          notification.error({ message: 'Panier', description: 'Impossible d\'ajouter l\'article au panier.' });
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err: any) => {
        notification.error({ message: 'Panier', description: 'Impossible d\'ajouter l\'article au panier.' });
        this.setState({
          loading: false,
        });
      });

  }

  private loadUserWhishList = (): Promise<any> => {

    this.setState({
      loading: true,
    });

    return this.wishlistService.findByCustomerId()
      .then((data: any) => {

        if (data.content !== undefined) {

          this.totalCalculation(data);
          return true;
        }
        else {
          this.setState({
            loading: false,
          });
        }

        return false;
      });
  }

  private refreshAvailability = () => {
    this.setState({});
  }

  private refreshWishlistTotal = () => {
    this.wishlistService.wishlistTotal()
      .then((wishlistTotal: IWishlistTotal) => {
        this.setState({
          wishlistTotal: wishlistTotal,
          loading: false,
        });
      });
  }

  private refreshWishlist = () => {

    if (!this.state.loading) {
      this.setState({
        loading: true
      });
    }

    this.wishlistService.findByCustomerId()
      .then((data: any) => {

        let countArticles = 0;
        data.content.forEach((wishlist: IWishlist) => {
          countArticles = countArticles + wishlist.articleCount;
        });


        this.refreshFavoritePlatformShippingMethods(data);

        this.setState({
          countArticles: countArticles,
          basketContent: data.content.sort((a: any, b: any) => { return a.platform.rank - b.platform.rank }),
          loading: false,
        });

      });
  }

  private refreshFavoritePlatformShippingMethods = (data: any) => {
    let favoritePlatformShippingMethods: IShippingMethod[] = [];
    this.shippingMethodService.getFavoritesShippingMethods()
      .then((shippingMethodData: any) => {
        if (shippingMethodData !== undefined && shippingMethodData.content !== undefined) {
          shippingMethodData.content.forEach((shippingMethod: IShippingMethod) => {
            let wishlist: IWishlist = data.content.find((wishlist: IWishlist) => wishlist.shippingMethod.id === shippingMethod.id);
            if (wishlist === undefined) {
              favoritePlatformShippingMethods.push(shippingMethod);
            }
          });

          this.setState({
            favoritePlatformShippingMethods: favoritePlatformShippingMethods,
          });
        }
      });
  }

  private updateWhishlist = (wishlist: IWishlist) => {

    let wishlistUpdate = {
      id: wishlist.id,
      name: wishlist.name,
      reference: wishlist.reference,
      reference2: wishlist.reference2,
      platform: {
        id: wishlist.platform.id
      },
      shippingMethod: {
        id: wishlist.shippingMethod.id
      }
    }

    this.setState({
      loading: true
    });

    this.wishlistService.updateWhishlist(wishlistUpdate)
      .then((result: any) => {
        this.refreshWishlist();
      });
  }

  private totalCalculation = (data: any) => {

    let articleWishlistList: any = [];
    data.content.forEach((wishlist: IWishlist) => {
      articleWishlistList.push(wishlist);
    });

    this.refreshFavoritePlatformShippingMethods(data);

    this.wishlistService.wishlistTotal()
      .then((wishlistTotal: IWishlistTotal) => {

        this.setState({
          basketContent: data.content.sort((a: any, b: any) => { return a.platform.rank - b.platform.rank }),
          articleWishlistList: articleWishlistList,
          wishlistTotal: wishlistTotal,
          loading: false,
        });

      });
  }

  private loadShippingMethods = () => {
    this.shippingMethodService.getWishlistsShippingMethods()
      .then((listShippingMethods: any) => {
        this.setState({
          listShippingMethods: listShippingMethods,
        });
      });
  }
}

export default BasketProvider;

export const withBasket = (Component: any) => (props: any) => (
  <BasketContext.Consumer>
    {store => <Component {...props} {...store} />}
  </BasketContext.Consumer>
);
