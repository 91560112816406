import React from 'react';
import '../../App.css';
import { Row, Col, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { ContainerOutlined, ReadOutlined, RollbackOutlined } from '@ant-design/icons';
import { withAppMenu } from '../../Utils/NavigationProvider';
import { MarketParameterContext } from '../../Utils/MarketParameterContext';

class CardsDocuments extends React.Component<
  {
    history: any,
    updateMenuKey: any,
  },
  {}>{



  render() {

    return (

      <MarketParameterContext.Consumer>
        {(appParms) => (

          <Row gutter={12} justify="center" style={{ marginTop: 25, minHeight: 300, marginBottom: 200 }}>
            <Col flex={'380px'} style={{ marginTop: 5 }} >
              <Button style={{ height: 100, width: 250, fontSize: "1.3em" }} onClick={() => this.onClickMenu('/documents-history')} >
                <ReadOutlined />
                Mes documents
              </Button>
            </Col>

            {appParms.CUSTOM_DOCUMENTS_HISTORY &&
              <Col flex={'380px'} style={{ marginTop: 5 }} >
                <Button style={{ height: 100, width: 250, fontSize: "1.3em" }} onClick={() => this.onClickMenu('/custom-documents-history')} >
                  <ContainerOutlined />
                  {appParms.CUSTOM_DOCUMENTS_HISTORY}
                </Button>
              </Col>
            }

            <Col flex={'380px'} style={{ marginTop: 5 }}  >
              <Button style={{ height: 100, width: 250, fontSize: "1.3em" }} onClick={() => this.onClickMenu('/returns')} >
                <RollbackOutlined />
                Demandes de retour
              </Button>
            </Col>
          </Row>
        )}
      </MarketParameterContext.Consumer>

    );
  }

  private onClickMenu = (menuKey: string) => {
    this.props.updateMenuKey(menuKey);
    this.props.history.push(menuKey);
  }
}

export default withRouter(withAppMenu(CardsDocuments));