import React from 'react';
import '../../App.css';
import { withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import ReactHtmlParser from 'react-html-parser';

import { withBasket } from '../../Utils/BasketProvider';
import IArticle from './IArticle';
import FlybyUtils from '../../Utils/FlybyUtils';
import IData from '../../Utils/IData';
import LoginService from './../login/LoginService';
import ParameterService from '../../Utils/ParameterService';
import CardsSearch from './CardsSearch';
import HeadbandSearch from './HeadbandSearch';
import ArticleDisplay from './ArticleDisplay';
import ITecdocSearch from '../tecdoc/model/ITecdocSearch';
import TecdocService from '../tecdoc/TecdocService';
import CatalogHome from '../catalog/CatalogHome';
import CardsDocuments from './CardsDocuments';
import CardsNotifications from './CardsNotifications';
import CardsSlider from './CardsSlider';
import { MarketParameterContext } from '../../Utils/MarketParameterContext';
import CardsDocument from './component/CardsDocument';
class Home extends React.Component<
  {
    history: any,
    addArticle: any,
    setQuery: any,
    setAdvancedQuery: any,
    query: string,
    advancedQuery: boolean,
    setTypeSearch: any,
    tecdocSearch: ITecdocSearch | undefined,
    setTecdocSearch: any,
    clearTecdocSearch: any,
    clearCatalogSearch: any,
    typeSearch: number,
    setSelectedArticle: any,
    selectedArticle: IArticle | undefined,
    setUserRole: any,
  },
  {
    data: IData,
    dataLoading: boolean,
    showGdpr: boolean,
    typeSearch: number
  }
>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private loginService: LoginService = new LoginService();
  private parameterService: ParameterService = new ParameterService();
  private tecdocService: TecdocService = new TecdocService();

  constructor(props: {
    history: any,
    addArticle: any, setQuery: any, setAdvancedQuery: any, query: string, advancedQuery: boolean, setTypeSearch: any,
    tecdocSearch: ITecdocSearch | undefined, setTecdocSearch: any, clearTecdocSearch: any, clearCatalogSearch: any, typeSearch: number,
    setSelectedArticle: any, selectedArticle: IArticle | undefined, setUserRole: any,
  }) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: false,
      showGdpr: false,
      typeSearch: 0
    };
  }

  componentDidMount() {

    let typeSearch = this.props.typeSearch;

    if (typeSearch !== 0) {
      this.props.clearTecdocSearch();
      this.props.clearCatalogSearch();
      this.props.setTypeSearch(0);
      typeSearch = 0;
    }

    this.getUserRole();

    if (sessionStorage.getItem("gdprAccepted") === "false") {

      this.parameterService.findValue("GDPR_TITLE")
        .then((value: string) => {
          let title = value;

          this.parameterService.findValue("GDPR_TEXT")
            .then((value: string) => {
              let text = value;

              Modal.info({
                title: (ReactHtmlParser(title)),
                content: (<p>{ReactHtmlParser(text)}</p>),
                onOk: this.onClickGdprOk,
                okText: 'Accepter'
              });
            });
        });
    }

    this.setState({
      typeSearch: typeSearch
    });

  }

  render() {

    return (
      <MarketParameterContext.Consumer>
        {(appParms) => (
          <div>

            {this.state.typeSearch === 0 && !appParms.DISABLE_MARKET &&
              <>
                <CardsSlider />

                <CardsSearch dataLoading={this.state.dataLoading} showTecdoc={appParms.CATALOG_TECDOC} showEtai={appParms.CATALOG_ETAI} showCatalogF1={appParms.CATALOG_F1}
                  onSearchArticle={this.onSearchArticle} onAdvancedQuery={this.onAdvancedQuery} onSearchTecdoc={this.onSearchTecdoc} onSearchEtai={this.onSearchEtai} />

                <CardsNotifications type={'HOME_INFO_1'} />
              </>
            }

            {appParms.CATALOG_F1 && this.state.typeSearch === 0 &&
              <CatalogHome />
            }

            {this.state.typeSearch === 0 && !appParms.DISABLE_MARKET &&
              <CardsDocument />
            }

            {(this.state.typeSearch === 1 || this.state.typeSearch === 2 || this.state.typeSearch === 4) &&
              <div>

                {(this.state.typeSearch === 1 || this.state.typeSearch === 4) &&
                  <HeadbandSearch showEtai={appParms.CATALOG_ETAI} showTecdoc={appParms.CATALOG_TECDOC} onSearchArticle={this.onSearchArticle} onAdvancedQuery={this.onAdvancedQuery}
                    onSearchEtai={this.onSearchEtai} cancelSearch={this.cancelSearchArticle} title={undefined} />
                }

                <ArticleDisplay data={this.state.data} />

              </div>
            }

            {appParms.DISABLE_MARKET &&
              <>
                <CardsSlider />
                <CardsNotifications type={'HOME_INFO_1'} />
                <CardsDocuments />
              </>
            }

            {(this.state.typeSearch !== 1 && this.state.typeSearch !== 2 && this.state.typeSearch !== 4) &&
              <CardsNotifications type={'HOME_INFO_2'} />
            }
          </div >
        )}
      </MarketParameterContext.Consumer>
    );
  }

  private onClickGdprOk = () => {
    sessionStorage.setItem("gdprAccepted", "true");
    this.loginService.acceptGdpr();
  }

  private onSearchEtai = () => {

    let accessToken = sessionStorage.getItem("accessToken");
    let customerId = sessionStorage.getItem("customerId");
    window.open("/api/etai/ui?access_token=" + accessToken + "&customer=" + customerId + "&baseUri=/api", "ETAI");

    // Create IE + others compatible event handler
    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent, (e: any) => {

      let data = e.data.data;

      if (data !== undefined && data.content !== undefined) {
        let idValue = -1;
        data.content.forEach((article: IArticle) => {
          article.quantity = 1;

          if (article.id === null) {
            article.id = idValue;
            idValue = idValue - 1;
          }
        });

        if (data.size === 0) {
          data.size = data.totalElements;
        }

        this.setState({
          data: data,
          dataLoading: false,
          typeSearch: 4,
        });

        this.props.setQuery('');
        this.props.setSelectedArticle(undefined);
        this.props.setTypeSearch(4);
      }

    }, false);
  }

  private onAdvancedQuery = (value: boolean) => {
    this.props.setAdvancedQuery(value);
  }

  private onSearchArticle = (query: any) => {
    this.props.setTypeSearch(1);
    this.props.setQuery(query);
    this.props.setSelectedArticle(undefined);
    this.setState({ typeSearch: 1 });
  }

  private cancelSearchArticle = () => {

    this.props.clearTecdocSearch();
    this.props.clearCatalogSearch();
    this.props.setSelectedArticle(undefined);

    if (this.props.selectedArticle === undefined) {
      this.props.setTypeSearch(0);
      this.setState({ typeSearch: 0 });
    }

    if (this.props.selectedArticle !== undefined) {
      this.props.setTypeSearch(1);
      this.setState({ typeSearch: 1 });
    }
  }

  private onSearchTecdoc = (query: any, queryType: string) => {

    if (query !== undefined && query !== '') {
      this.tecdocService.searchVehicle(query, queryType)
        .then((tecdocSearch: ITecdocSearch) => {
          this.props.setTypeSearch(0);
          this.props.setSelectedArticle(undefined);
          this.props.setTecdocSearch(tecdocSearch);
          this.props.history.push('/tecdoc');
        });
    }
  }

  private getUserRole = () => {

    let accessToken = sessionStorage.getItem("accessToken");

    if (accessToken !== null) {
      this.loginService.session(accessToken).then((sessionData: any) => {

        this.props.setUserRole(sessionData ? sessionData.user.role.name : '');
      });
    }

  };
}

export default withRouter(withBasket(Home));