import React from 'react';
import '../../App.css';
import { Spin, Row, Col } from 'antd';

import MyAccountForm from './MyAccountForm';
import LoginService from '../login/LoginService';
import ISession from './ISession';

class MyAccount extends React.Component<
  {
  },
  {
    data: ISession | undefined,
    dataLoading: boolean,
  }
>{

  private loginService: LoginService = new LoginService();

  constructor(props: {}) {
    super(props);

    this.state = {
      data: undefined,
      dataLoading: true
    };
  }

  componentDidMount() {

    this.findSessionData();
  }

  render() {

    return (
      <div>
        <h3>Mon compte</h3>
        <Spin spinning={this.state.dataLoading}>
          <Row style={{ marginTop: 10, marginBottom: 15 }}>
            <Col span={24} >
              {this.state.data !== undefined &&
                <MyAccountForm data={this.state.data} />
              }
            </Col>
          </Row>
        </Spin>
      </div>
    );
  }

  private findSessionData = () => {
    this.loginService.session()
      .then((session: ISession) => {
        if (session !== undefined) {
          this.setState({
            data: session,
            dataLoading: false
          });
        }
      });
  }
}

export default MyAccount;        