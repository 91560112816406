import { Typography } from "antd";
import React, { useEffect, useState } from "react";

import IWishlist from "../containers/home/IWishlist";
import IStep from "../containers/shoppingcart/IStep";
import FlybyUtils from "./FlybyUtils";
import { useMarketParameter } from "./MarketParameterContext";

const { Text } = Typography;


interface FrancoViewProps {
  wishlist: IWishlist;
}

const FrancoView: React.FC<FrancoViewProps> = (props: {
  wishlist: IWishlist;
}) => {

  const {SHOPPING_CART_CONFIGURATION} = useMarketParameter();
  const flybyUtils: FlybyUtils = new FlybyUtils();
  const [showShippingCost, setShowShippingCost] = useState(false);

  const { wishlist } = props;

  const {
    freeShipping,
    freeShippingAmount,
    remainingFreeShippingAmount,
    freeShippingQuantity,
    remainingFreeShippingQuantity,
  } = wishlist;

  useEffect(() => {
    if(SHOPPING_CART_CONFIGURATION !== undefined){
      const params: IStep[] = JSON.parse(SHOPPING_CART_CONFIGURATION);
      setShowShippingCost(params[0].option.shippingCost);
    }

 }, [SHOPPING_CART_CONFIGURATION]);  

  return (
    <>

      {showShippingCost && freeShipping &&
        <Text type="warning">Franco de port atteint</Text>}

      {showShippingCost && !freeShipping && freeShippingAmount !== undefined && freeShippingAmount !== null && (
        <Text type="warning">
          Encore{" "}
          {flybyUtils.numberFormat(remainingFreeShippingAmount, 2)} € HT
          avant un franco de port
          <br />
        </Text>
      )}

      {showShippingCost && !freeShipping && freeShippingQuantity !== undefined && freeShippingQuantity !== null && (
        <Text type="warning">
          Encore {remainingFreeShippingQuantity} article(s) avant un franco de
          port
          <br />
        </Text>
      )}
    </>
  );
};


export default FrancoView;
