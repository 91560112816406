import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { useMarketParameter } from '../Utils/MarketParameterContext';
import { Switch } from 'antd';
import { CloseOutlined, ShoppingCartOutlined } from '@ant-design/icons';

interface BasketSwitchProps {
  onChange: (checked: any) => void;
  checked: boolean;
  role: string;
}

const BasketSwitch: React.FC<BasketSwitchProps> = (props: {
  onChange: (checked: any) => void;
  checked: boolean;
  role: string;
}, ref: Ref<any>) => {

  const { onChange, checked, role } = props;
  const { SHOW_BASKET } = useMarketParameter();
  const [showWishlist, setShowWishlist] = useState(role === 'ROLE_GUEST' ? false : SHOW_BASKET);
  const [initShowWishlist, setInitShowWishlist] = useState<boolean | undefined>(role === 'ROLE_GUEST' ? false : undefined);


  useEffect(() => {
    if (role !== 'ROLE_GUEST') {
      if (initShowWishlist === undefined) {
        setShowWishlist(SHOW_BASKET);
        setInitShowWishlist(SHOW_BASKET);
        onChange(SHOW_BASKET);
      }
      else {
        setShowWishlist(!checked);
      }
    }
    else {
      onChange(false);
    }
  }, [checked, SHOW_BASKET, onChange, initShowWishlist, role]);

  return (
    <Switch
      checkedChildren={<ShoppingCartOutlined />}
      unCheckedChildren={<CloseOutlined />}
      onChange={onChange}
      checked={showWishlist}
      disabled={role === 'ROLE_GUEST'}
    />
  );
};

export default forwardRef(BasketSwitch);