
import React from 'react';
import { Carousel, Col, List, Row, Skeleton, Typography } from 'antd';

import FlybyUtils from '../../../Utils/FlybyUtils';
import CatalogService from '../../catalog/CatalogService';
import IData from '../../../Utils/IData';
import { BasketContext, withBasket } from '../../../Utils/BasketProvider';
import ICriterion, { Format } from '../model/ICriterion';

const { Text } = Typography;

class CatalogArticleCriterion extends React.Component<
  {
    articleId: number
  },
  {
    dataLoading: boolean,
    data: IData
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private catalogService: CatalogService = new CatalogService();

  constructor(props: { articleId: number }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: this.flybyUtils.getEmptyData(),
    };
  }

  componentDidMount() {
    this.loadData(this.props.articleId, 30, 0);
  }

  componentWillReceiveProps(newProps: { articleId: number }) {
    if (newProps.articleId !== this.props.articleId) {
      this.loadData(newProps.articleId, 30, 0);
    }
  }

  render() {
    return (

      <Skeleton loading={this.state.dataLoading} active>
        {(!this.state.data.content || this.state.data.content.length === 0) &&
          <Text type="secondary">Nous n'avons pas trouvé de caractéristique.</Text>
        }

        {this.state.data.content && this.state.data.content.length > 0 &&
          <BasketContext.Consumer>
            {(contextValues) =>
              <Row>
                <Col span={18}>
                  <List
                    loading={this.state.dataLoading}
                    grid={{ gutter: 16, column: 3 }}
                    dataSource={this.state.data.content}
                    renderItem={(criteria: ICriterion) => (
                      <>
                        {criteria.format !== Format.IMAGE && criteria.format !== Format.PRINCIPAL_IMAGE && criteria.values &&
                          <List.Item>
                            <Text strong>{criteria.description} :</Text> {criteria.values.toString()}
                          </List.Item>
                        }
                      </>
                    )}
                  />
                </Col>
                <Col span={6} >
                  <Carousel>
                    {this.state.data.content.filter((c: ICriterion) => { return c.format === Format.IMAGE }).map((criteria: ICriterion) =>
                      <div key={"dimg-" + criteria.id}>
                        {criteria.values?.map((image: string) =>
                          <img src={'/api/public/catalog/' + image} alt={image} style={{ minWidth: 120, maxWidth: 200, width: 180 }} key={"img-" + criteria.id} />
                        )}

                      </div>
                    )}
                  </Carousel>
                </Col>
              </Row>

            }
          </BasketContext.Consumer>
        }
      </Skeleton>

    );
  }


  private loadData = (articleId: number, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findCriterionByArticle(articleId, pageSize, page, 'description,asc')
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  }
}

export default withBasket(CatalogArticleCriterion);