import Modal from 'antd/lib/modal/Modal';
import React, { ReactElement } from 'react';
import { Viewer, Worker, LocalizationMap } from '@react-pdf-viewer/core';
import {
  defaultLayoutPlugin,
  ToolbarProps,
  ToolbarSlot,
} from '@react-pdf-viewer/default-layout';
import fr_FR from '@react-pdf-viewer/locales/lib/fr_FR.json';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { CloseSquareOutlined } from '@ant-design/icons';



const PdfViewer = (props: {
  file: Blob | string;
  urlType?: boolean;
  visible: boolean;
  onClosePrint: () => void;
}): React.ReactElement => {
  const { visible, file, urlType, onClosePrint } = props;

  const renderToolbar = (Toolbar: (pdfProps: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {(slots: ToolbarSlot) => {
        const {
          CurrentPageInput,
          Download,
          GoToNextPage,
          GoToPreviousPage,
          NumberOfPages,
          Print,
          ShowSearchPopover,
          Zoom,
          ZoomIn,
          ZoomOut,
        } = slots;
        return (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div style={{ padding: '0px 2px' }}>
              <ShowSearchPopover />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomOut />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Zoom />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <ZoomIn />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <GoToPreviousPage />
            </div>
            <div
              style={{ padding: '0px 2px', whiteSpace: 'nowrap', width: 80 }}
            >
              <CurrentPageInput /> / <NumberOfPages />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 30 }}>
              <GoToNextPage />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <Download />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <Print />
            </div>
            <div style={{ padding: '0px 2px' }}>
              <div>
                <button
                  aria-label="Fermer"
                  className="rpv-core__minimal-button"
                  type="button"
                  onClick={onClosePrint}
                >
                  <CloseSquareOutlined
                    style={{ verticalAlign: 0, width: 20 }}
                  />
                </button>
              </div>
            </div>
          </div>
        );
      }}
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
  });

  return (
    <Modal
      destroyOnClose
      closable={false}
      width={850}
      visible={visible}
      okButtonProps={{ disabled: true, hidden: true }}
      cancelButtonProps={{ disabled: true, hidden: true }}
      bodyStyle={{ margin: 0, padding: 0 }}
      style={{ top: 20 }}
    >
      <Worker workerUrl="pdf.worker.min.js">
        <div
          style={{
            height: '800px',
          }}
        >
          <Viewer
            localization={(fr_FR as unknown) as LocalizationMap}
            fileUrl={urlType ? file.toString() : URL.createObjectURL(file)}
            plugins={[defaultLayoutPluginInstance]}
          />
        </div>
      </Worker>
    </Modal>
  );
};

export default PdfViewer;
