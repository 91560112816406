import React, { useCallback, useState } from "react";
import "../../App.css";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, notification, Modal } from "antd";
import LoginService from "./LoginService";
import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
import ResetPasswordForm from "./ResetPasswordForm";


interface LoginFormProps {
  onLogin: (isLoging: boolean) => void;
}

const LoginForm: React.FC<LoginFormProps> = (props: {
  onLogin: (isLoging: boolean) => void;
}) => {

  const { onLogin } = props;

  const loginService = new LoginService();
  const [loading, setLoading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const onFinish = useCallback((values: any) => {
    setLoading(true);

    loginService.login(values).then((success: boolean) => {
      if (success) {
        setLoading(false);
        onLogin(true);
      } else {
        notification.error({
          message: "Connexion",
          description: "Impossible de vous identifier.",
        });

        setLoading(false);
      }
    });

  }, [loginService, onLogin]);

  const onResetPassword = useCallback(() => {
    setResetPassword(false);
  }, []);

  const onOpen = useCallback(() => {
    setResetPassword(true);
  }, []);

  return (
    <div id="loginContainer">
      <Form {...FlybyLayoutForm} onFinish={onFinish} className="login-form">
        <Form.Item name="username" rules={[{ required: true, message: 'L\'identifiant est obligatoire.' }]}>
          <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Identifiant" autoFocus={true} />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Le mot de passe est obligatoire.",
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Mot de passe"

          />
        </Form.Item>
        <Form.Item>
          <Button
            type="link"
            className="login-form-forgot"
            onClick={onOpen}
          >
            Mot de passe oublié
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Connexion
          </Button>
        </Form.Item>
      </Form>

      <Modal visible={resetPassword} destroyOnClose
        title="Modifié mon mot de passe"
        closable={false}
        width={450}
        bodyStyle={{ height: 350 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}>
        <ResetPasswordForm onResetPassword={onResetPassword} />
      </Modal>
    </div>
  );
};


export default LoginForm;
