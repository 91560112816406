import React from 'react';
import '../App.css';
import { DeleteOutlined, InfoCircleFilled, ShoppingCartOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Layout,
  Popconfirm,
  Typography,
  Spin,
  Row,
  Col,
  Button,
  Popover,
  Tag,
  Tooltip,
  notification,
  Statistic,
} from 'antd';
import { Link } from 'react-router-dom';

import { BasketContext, withBasket } from '../Utils/BasketProvider';
import IWishlistArticle from './home/IWishlistArticles';
import IWishlist from './home/IWishlist';
import WhishlistService from '../containers/home/WishlistService';
import FlybyUtils from '../Utils/FlybyUtils';
import FrancoView from '../Utils/FrancoView';
import { withAppMenu } from '../Utils/NavigationProvider';
import IShippingMethod from './shoppingcart/IShippingMethod';
import ParameterService from '../Utils/ParameterService';

const { Countdown } = Statistic;
const { Content } = Layout;
const { Title, Text } = Typography;

class Basket extends React.Component<
  {
    addArticle: any,
    deleteArticle: any,
    loadUserWhishList: any,
    refreshWishlist: any,
    updateMenuKey: any
  },
  {
    basketList: any,
    showCutoff: boolean,
    showCutoffList: boolean,
  }>{

  private wishlistService: WhishlistService = new WhishlistService();
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private parameterService: ParameterService = new ParameterService();
  private showPlatformList: boolean = sessionStorage.getItem("showPlatformList") === 'false' ? false : true;

  constructor(props: { addArticle: any, deleteArticle: any, loadUserWhishList: any, refreshWishlist: any, updateMenuKey: any }) {
    super(props);

    this.state = {
      basketList: [],
      showCutoff: false,
      showCutoffList: false,
    };
  }

  componentDidMount() {
    this.parameterService.findValue("SHOW_COUNTDOWN_CUTOFF")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            showCutoff: JSON.parse(value)
          });
        }
        else {
          this.setState({
            showCutoff: false
          });
        }
      });

    this.parameterService.findValue("SHOW_COUNTDOWN_CUTOFF_LIST")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            showCutoffList: JSON.parse(value)
          });
        }
        else {
          this.setState({
            showCutoffList: false
          });
        }
      });


    this.props.loadUserWhishList();
  }

  render() {

    return (
      <Layout style={{ overflowY: "auto", height: '100%', background: '#fff', borderLeft: 'solid', borderLeftColor: '#e8e8e8', borderLeftWidth: '1px', paddingLeft: 5, marginRight: 10 }}>

        <div style={{ backgroundColor: '#fff', textAlign: "left", fontSize: 12, padding: 0, lineHeight: 2 }}>
          <BasketContext.Consumer>
            {(values) =>
              <>
                {this.state.showCutoff && this.state.showCutoffList && values.favoritePlatformShippingMethods.length > 0 &&
                  <div style={{ border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px', marginBottom: 8 }}>
                    <Row>
                      <Col span={24} style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
                        {!this.showPlatformList &&
                          <span>Mes modes d'expédition privilegiés</span>
                        }
                        {this.showPlatformList &&
                          <span>Mes modes d'expédition privilegiés par plateformes</span>
                        }
                      </Col>
                    </Row>
                  </div>
                }
                {this.state.showCutoff && this.state.showCutoffList && values.favoritePlatformShippingMethods.map((shippingMethod: IShippingMethod) =>
                  <Row style={{ paddingBottom: 5 }} key={shippingMethod.id}>
                    <Col span={16} style={{ fontSize: 12 }}>
                      <span style={{ fontWeight: "bold" }}>{shippingMethod.platform.name} </span><br />
                      {shippingMethod.name}
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                      <Countdown valueStyle={{ fontSize: 12, fontWeight: "bold" }} value={shippingMethod.deadline} format="HH:mm:ss" />
                    </Col>
                  </Row>
                )}

                <div style={{ border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px', padding: 5, backgroundColor: "#FAFAFA", marginBottom: 5 }}>
                  <div style={{ float: "right" }}>
                    <Tooltip title="Rafraichir mon panier">
                      <Button type="link" style={{ margin: 0, padding: 0 }} onClick={this.onRefreshWishlist} ><SyncOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="Voir mon panier">
                      <Button type="link" style={{ margin: 0, padding: 0 }} ><Link to="/shopping-cart" onClick={this.onShoppingCart} ><ShoppingCartOutlined /></Link></Button>
                    </Tooltip>
                  </div>
                  <Title level={4}>Mon panier</Title>

                  <Text type="secondary">Nombre articles : {this.flybyUtils.numberFormat(values.wishlistTotal.articleCount, 0)}</Text><br />
                  <Text type="secondary">Total € HT : {this.flybyUtils.numberFormat(values.wishlistTotal.totalAmountExclTaxes, 2)}</Text>
                </div>
              </>
            }
          </BasketContext.Consumer>
        </div>

        <Layout style={{ background: '#fff' }}>
          <Content style={{ background: '#fff' }}>

            <BasketContext.Consumer>

              {(values) =>

                <Spin spinning={values.loading} >

                  {values.basketContent.map((wishlist: IWishlist) =>
                    <div style={{ border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px', marginBottom: 8 }} key={wishlist.id}>
                      <Row>
                        <Col span={22} style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
                          {wishlist.name}
                        </Col>
                        <Col span={2} style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
                          <div style={{ float: "right" }}>
                            <Popconfirm title={"Supprimer tous les articles de la plateforme " + wishlist.name + " ?"} okText="Oui" cancelText="Non" onConfirm={() => this.onDeleteWishlist(wishlist.id)}>
                              <Button type="link" style={{ margin: 0, padding: 0, height: 16 }} ><DeleteOutlined /></Button>
                            </Popconfirm>
                          </div>
                        </Col>
                        {wishlist.shippingMethod.deadline != null && !wishlist.shippingMethod.cutoff && this.state.showCutoff &&
                          <Col span={24} style={{ padding: 2, border: 'solid', borderColor: '#1890FF', borderWidth: '1px', textAlign: "center" }}>
                            <Countdown onFinish={this.onRefreshWishlist} valueStyle={{ fontSize: 16, fontWeight: "bold", color: "#1890FF" }} value={wishlist.shippingMethod.deadline} format="HH:mm:ss" />
                            <span style={{ fontSize: 10, color: "#1890FF" }}>{wishlist.shippingMethod.name}</span>
                          </Col>
                        }
                        <Col span={24} style={{ fontSize: 12, padding: 5 }}>
                          {wishlist.wishlistArticles.map((item: IWishlistArticle) =>

                            <div key={item.id} >
                              <b>{item.article.reference}</b>
                              <div style={{ float: "right" }}>
                                <Popover placement="left" content={
                                  <span>
                                    Article : {item.article.reference}<br />
                                    Date interro. plateforme : {this.flybyUtils.formatJsonDate(item.lastAvailabilityUpdateDate)}<br />
                                    Date liv. estimée : {this.flybyUtils.formatJsonDate(item.deliveredAt)}<br />
                                    <br />
                                    <Tag color={this.flybyUtils.getAvailabilityColor(item.state, item.locallyAvailable, item.deliveredAt, item.quantityBackorder, item.longerAvailabilityAlert)}>
                                      {this.flybyUtils.getAvailabilityText(item.state, item.deliveredAt, item.quantityBackorder, item.longerAvailabilityAlert)}
                                    </Tag>
                                    <br />
                                  </span>
                                } title={'Statut dernière Interrogation de la plateforme'}>
                                  <Button type="link" style={{ color: this.flybyUtils.getAvailabilityColor(item.state, item.locallyAvailable, item.deliveredAt, item.quantityBackorder, item.longerAvailabilityAlert), margin: 0, paddingTop: 0, paddingLeft: 0, paddingBottom: 0, paddingRight: 2 }} ><InfoCircleFilled /></Button>
                                </Popover>
                                <Popconfirm title={"Supprimer l'article " + item.article.reference + " de la plateforme " + wishlist.name + " ?"} okText="Oui" cancelText="Non" onConfirm={() => this.onDelete(item.id)}>
                                  <Button type="link" style={{ margin: 0, padding: 0 }} ><DeleteOutlined /></Button>
                                </Popconfirm>
                              </div>
                              <br />
                              Qté : {item.quantity} | Prix : {this.flybyUtils.numberFormat(item.netAmount, 2)} €<br />
                            </div>

                          )}
                        </Col>
                        <Col span={24} style={{ fontSize: 12, padding: 5, backgroundColor: "#FAFAFA", borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}>
                          <Text type="secondary">Nombre articles : {wishlist.articleCount} | Total € HT : {this.flybyUtils.numberFormat(wishlist.totalAmountExclTaxes, 2)}</Text><br />
                          <FrancoView wishlist={wishlist} />
                        </Col>
                      </Row>
                    </div>

                  )}

                </Spin>

              }

            </BasketContext.Consumer>

          </Content>
        </Layout>
      </Layout >
    );
  }

  private onDeleteWishlist = (wishlistId: number) => {
    this.wishlistService.deleteWishlist(wishlistId)
      .then((response: any) => {
        if (response.status !== 204) {
          notification.error({ message: 'Panier', description: 'Erreur lors de la suppression du panier.' });
        }
        this.props.loadUserWhishList();
      });
  }

  private onDelete = (wishlistArticleId: number) => {
    this.props.deleteArticle(wishlistArticleId);
  }

  private onShoppingCart = () => {
    this.props.updateMenuKey('/shopping-cart');
  }

  private onRefreshWishlist = () => {
    this.props.refreshWishlist();
  }
}

export default withAppMenu(withBasket(Basket));        