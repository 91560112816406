import React from 'react';
import '../../../App.css';
import { Descriptions, Row, Col, Card } from 'antd';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import ICatalogSearch from '../model/ICatalogSearch';
import IVehicleNode from '../model/IVehicleNode';
import IVehicleType from '../model/IVehicleType';
import CatalogService from '../CatalogService';


class CatalogVehicleInfo extends React.Component<
  {
    catalogSearch: ICatalogSearch,
  },
  {
    dataLoading: boolean,
    manufacturer: IVehicleNode | undefined,
    vehicleModel: IVehicleNode | undefined,
    vehicleType: IVehicleType | undefined
  }>{

  private catalogService: CatalogService = new CatalogService();

  constructor(props: { catalogSearch: ICatalogSearch }) {
    super(props);

    this.state = {
      dataLoading: true,
      manufacturer: undefined,
      vehicleModel: undefined,
      vehicleType: undefined
    };
  }

  componentDidMount() {

    if (this.props.catalogSearch.level1Id !== undefined) {
      this.loadVehicleNode('manufacturer', this.props.catalogSearch.level1Id);
    }

    if (this.props.catalogSearch.level2Id !== undefined) {
      this.loadVehicleNode('vehicleModel', this.props.catalogSearch.level2Id);
    }

    if (this.props.catalogSearch.level3Id !== undefined) {
      this.loadVehicleType(this.props.catalogSearch.level3Id);
    }
  }

  componentWillReceiveProps(newProps: { catalogSearch: ICatalogSearch }) {

    if (newProps.catalogSearch.level1Id !== undefined && newProps.catalogSearch.level1Id !== this.props.catalogSearch.level1Id) {
      this.loadVehicleNode('manufacturer', newProps.catalogSearch.level1Id);
    }

    if (newProps.catalogSearch.level2Id !== undefined && newProps.catalogSearch.level2Id !== this.props.catalogSearch.level2Id) {
      this.loadVehicleNode('vehicleModel', newProps.catalogSearch.level2Id);
    }

    if (newProps.catalogSearch.level3Id !== undefined && newProps.catalogSearch.level3Id !== this.props.catalogSearch.level3Id) {
      this.loadVehicleType(newProps.catalogSearch.level3Id);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => 
          <Card size="small" title={contextValues.catalogSearch.queryType === 'vehicleNode' ? 'Véhicule sélectionné' : 'Sélection'} style={{ marginTop: 5 }} bordered={true} headStyle={{ background: "rgb(236, 236, 236)" }}>
            <div style={{ marginLeft: -13, marginRight: -13, marginBottom: -13, marginTop: -13 }}>
              <Descriptions column={2} layout="vertical" size="small" bordered className="custom-description">
                {this.state.manufacturer !== undefined && contextValues.catalogSearch.level1Id !== undefined &&
                  <Descriptions.Item label={contextValues.catalogSearch.queryType === 'vehicleNode' ?  'Marque' : 'Type' } span={2}>
                    <Row align="middle">
                      <Col span={5} >
                        <img src={'/api/public/catalog/' + this.state.manufacturer.imageName} alt={this.state.manufacturer.description} style={{ width: '100%' }} />
                      </Col>
                      <Col span={19} style={{ paddingLeft: 5 }}>
                        {this.state.manufacturer?.description}
                      </Col>
                    </Row>
                  </Descriptions.Item>
                }
                {this.state.vehicleModel !== undefined && contextValues.catalogSearch.level2Id !== undefined &&
                  <Descriptions.Item label={contextValues.catalogSearch.queryType === 'vehicleNode' ? 'Modèle' : 'Marque'} span={2}>
                    <Row align="middle">
                      <Col span={5} >
                        {this.state.vehicleModel.imageName !== null && contextValues.catalogSearch.level2Id !== undefined &&
                          <img src={'/api/public/catalog/' + this.state.vehicleModel.imageName} alt={this.state.vehicleModel.description} style={{ width: '100%' }} />
                        }
                      </Col>
                      <Col span={19} style={{ paddingLeft: 5 }}>
                        {this.state.vehicleModel.description}
                      </Col>
                    </Row>
                  </Descriptions.Item>
                }
                {this.state.vehicleType !== undefined && contextValues.catalogSearch.level3Id !== undefined &&
                  <Descriptions.Item label={contextValues.catalogSearch.queryType === 'vehicleNode' ? 'Type' : 'Modèle'} span={2}>
                    <div style={{ width: '95%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {this.state.vehicleType.description}
                    </div>
                  </Descriptions.Item>
                }
              </Descriptions>

            </div>
          </Card>
        }
      </BasketContext.Consumer>
    );

  }

  private loadVehicleType = (vehicleTypeId: number) => {

    this.catalogService.findVehicleTypeById(vehicleTypeId)
      .then((result: any) => {
        if (result !== undefined) {
          this.setState({
            dataLoading: false,
            vehicleType: result,
          });
        }
      });
  }

  private loadVehicleNode = (type: string, vehicleNodeId: number) => {

    this.catalogService.findVehicleNodeById(vehicleNodeId)
      .then((result: any) => {
        if (result !== undefined) {
          switch (type) {
            case 'manufacturer':
              this.setState({
                dataLoading: false,
                manufacturer: result,
              });
              break;
            case 'vehicleModel':
              this.setState({
                dataLoading: false,
                vehicleModel: result,
              });
              break;
          }

        }
      });
  }

}

export default withBasket(CatalogVehicleInfo);     