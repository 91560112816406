import React, { useState, useEffect, useCallback } from 'react';
import { List, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import IFamily from '../model/IFamily';

interface ISubFamilies {
  parent: IFamily,
  childrens: IFamily[],
  onSearch: any
}

export const SubFamilies: React.FC<ISubFamilies> = ({ parent, childrens, onSearch }) => {

  const maxElement = 30;

  const [more, setMore] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [list, setList] = useState<IFamily[]>([]);

  useEffect(() => {
    if (childrens.length <= maxElement) {
      setMore(false);
    }

    let childs = childrens.slice(0, maxElement);
    childs = childs.sort((a: IFamily, b: IFamily) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    setList(childs);
    setCurrentIndex(maxElement);
  }, [childrens]);

  const onLoadMore = useCallback(() => {

    let idx = currentIndex + maxElement;
    if (idx > childrens.length) {
      idx = childrens.length;
    }

    let childs = list.concat(childrens.slice(currentIndex, idx))

    if (idx === childrens.length) {
      setMore(false);
    }

    childs = childs.sort((a: IFamily, b: IFamily) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    setList(childs);
    setCurrentIndex(idx);
    window.dispatchEvent(new Event('resize'));
  }, [childrens, currentIndex, list]);

  const loadMore = more ? (
    <div style={{ marginTop: 12, height: 32, lineHeight: '32px' }}>
      <Button style={{ padding: 0, backgroundColor: 'rgb(250, 250, 250)', border: 0 }} onClick={onLoadMore} icon={<PlusCircleOutlined />} type="ghost" block> Voir plus</Button>
    </div>
  ) : null;

  return (
    <List size="small" loadMore={loadMore} split={false} dataSource={list}
      renderItem={(children: IFamily) => (
        <List.Item style={{ border: 0, paddingTop: 4, paddingBottom: 4, paddingLeft: 10, paddingRight: 10 }}>
          <Button onClick={() => onSearch('family', parent.id, parent.name, children.id, children.name)}
            style={{ paddingLeft: 0, textAlign: 'left', width: '100%', whiteSpace: 'initial', height: 'fit-content' }} type='link'>
            {children.name}
          </Button>
        </List.Item>
      )}
    />);
};

