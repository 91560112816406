import React from "react";
import "../../App.css";
import "@ant-design/compatible/assets/index.css";
import {
  Form,
  Table,
  DatePicker,
  Radio,
  Button,
  Input,
  Select,
  Row,
  Col,
  Spin,
} from "antd";
import { ColumnProps } from "antd/lib/table";

import moment, { Moment } from "moment";
import "moment/locale/fr";

import FlybyUtils from "../../Utils/FlybyUtils";
import DocumentHistoryService from "./DocumentHistoryService";
import IData from "../../Utils/IData";
import IDocumentHistory from "./IDocumentHistory";
import DocumentHistoryDetails from "./DocumentHistoryDetails";
import FlybyLayoutForm from "../../Utils/FlybyLayoutForm";
import LoginService from "../login/LoginService";
import ICustomerPlatform from "./ICustomerPlatform";
import { FilePdfOutlined } from "@ant-design/icons";
import PdfViewer from "../../Utils/PdfViewer";

const RadioGroup = Radio.Group;
const Option = Select.Option;
const monthFormat = "MM/YYYY";

class DocumentHistory extends React.Component<
  {
  },
  {
    data: IData;
    dataLoading: boolean;
    queryDate: Moment;
    queryType: string;
    queryText: string;
    queryPlatformId: string;
    customerPlatforms: ICustomerPlatform[];
    pdfLoading: boolean;
    pdfFile: Blob | undefined,
  }
> {
  private flybyUtils: FlybyUtils = new FlybyUtils();
  private documentHistoryService: DocumentHistoryService = new DocumentHistoryService();
  private loginService: LoginService = new LoginService();
  private accessToken = sessionStorage.getItem("accessToken");
  private showPlatformList: boolean = sessionStorage.getItem("showPlatformList") === "false" ? false : true;

  private columns: ColumnProps<IDocumentHistory>[] = [
    {
      title: "N° document",
      dataIndex: "number",
      width: 80,
    },
    {
      title: "Plateforme",
      dataIndex: "plateforme",
      render: (text: string, record: any) => (
        <div>
          {record.platform !== null && record.platform.name !== null
            ? record.platform.name
            : ""}
        </div>
      ),
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text: string, record: any) => (
        <div>{this.flybyUtils.getDocumentHistoryType(record.type)}</div>
      ),
      width: 100,
    },
    {
      title: "Référence",
      dataIndex: "reference",
      width: 200,
    },
    {
      title: "Date",
      dataIndex: "date",
      width: 100,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.formatJsonDateDDMMYYYY(record.date)}
        </div>
      ),
      align: "right",
    },
    {
      title: "Total HT",
      dataIndex: "totalAmountExclTaxes",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.hideZeroValue(
            this.flybyUtils.numberFormat(record.totalAmountExclTaxes, 2)
          )}
        </div>
      ),
      align: "right",
    },
    {
      title: "Total TTC",
      dataIndex: "totalAmountInclTaxes",
      width: 80,
      render: (text: string, record: any) => (
        <div style={{ textAlign: "right" }}>
          {this.flybyUtils.hideZeroValue(
            this.flybyUtils.numberFormat(record.totalAmountInclTaxes, 2)
          )}
        </div>
      ),
      align: "right",
    },
    {
      title: () => this.state.queryType === 'ORDER' ? "Statut" : "",
      width: 100,
      render: (text: string, record: IDocumentHistory) => (
        <div style={{ textAlign: "right" }}>
          {this.state.queryType === 'ORDER' && record.flag === "6" &&
            <div>Commande expédiée</div>
          }
          {this.state.queryType === 'ORDER' && record.flag !== "6" &&
            <div>En cours de traitement</div>
          }
          {this.state.queryType !== 'ORDER' &&
            <Button onClick={() => this.onGetPdf(record)}>
              <FilePdfOutlined />
              PDF
            </Button>
          }
        </div>
      ),
    },
  ];

  constructor(props: {}) {
    super(props);

    moment.locale("fr");

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      queryDate: moment(),
      queryType: "ORDER",
      queryText: "",
      queryPlatformId: "",
      customerPlatforms: [],
      pdfLoading: false,
      pdfFile: undefined
    };
  }

  componentDidMount() {
    if (this.accessToken !== null) {
      this.loginService.session(this.accessToken).then((data: any) => {
        this.setState({ customerPlatforms: data.customerPlatforms });
      });
    }

    this.searchDocumentHistory(
      this.state.queryText,
      this.state.queryPlatformId,
      20,
      0,
      "date,desc",
      this.state.queryDate,
      this.state.queryType
    );
  }

  render() {
    return (
      <div>
        <h3>Mes documents</h3>
        <Spin spinning={this.state.pdfLoading}>
          <Form
            {...FlybyLayoutForm}
            layout="inline"
            style={{ marginTop: 15, marginBottom: 20 }}
            onFinish={this.onSubmit}
            initialValues={{
              type: "ORDER",
              date: null,
              text: "",
              platform: "",
            }}
          >
            <Row gutter={8} style={{ marginTop: 5 }}>
              {this.showPlatformList && (
                <Col span={6}>
                  <Form.Item name="platform">
                    <Select
                      placeholder="Plateforme"
                      style={{ minWidth: 180, width: "100%" }}
                      allowClear
                      dropdownMatchSelectWidth={370}
                    >
                      {this.state.customerPlatforms.map(
                        (customerPlatform: ICustomerPlatform) => (
                          <Option
                            value={customerPlatform.platform.id}
                            key={customerPlatform.platform.id.toString()}
                          >
                            {customerPlatform.platform.name}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={18}>
                <Form.Item name="type">
                  <RadioGroup>
                    <Radio value={"ORDER"}>En cours</Radio>
                    <Radio value={"INVOICE"}>Factures</Radio>
                    <Radio value={"CREDIT_NOTE"}>Avoirs</Radio>
                    <Radio value={"RETURN"}>Accords de retour</Radio>
                    <Radio value={"DELIVERY_NOTE"}>Bons de livraison</Radio>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={8} style={{ marginTop: 5 }}>
              <Col span={8}>
                <Form.Item name="date">
                  <DatePicker
                    picker="month"
                    style={{ width: "100%" }}
                    placeholder="Date du document"
                    format={monthFormat}
                    allowClear={true}
                    disabled={this.state.queryType === "ORDER"}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="text">
                  <Input
                    placeholder="Référence, n° de document, fabricant ...."
                    style={{ minWidth: 200, width: "100%" }}
                    disabled={this.state.queryType === "ORDER"}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Rechercher
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Table
            rowKey="id"
            loading={this.state.dataLoading}
            columns={this.columns}
            dataSource={this.state.data.content}
            onChange={this.onChange}
            pagination={{
              current: this.state.data.number + 1,
              pageSize: this.state.data.size,
              total: this.state.data.totalElements,
            }}
            expandedRowRender={(
              record: IDocumentHistory,
              index: number,
              indent: number,
              expanded: boolean
            ) => (
              <div>
                {expanded && (
                  <DocumentHistoryDetails
                    documentHistoryId={record.id}
                    documentType={record.type}
                  />
                )}
              </div>
            )}
            style={{
              border: "1px solid #e8e8e8",
              borderWidth: "1px 1px 1px 1px",
            }}
            size="middle"
          />
        </Spin>
        {this.state.pdfFile && (
          <PdfViewer
            file={this.state.pdfFile}
            visible={!!this.state.pdfFile}
            onClosePrint={this.onClosePdf}
          />
        )}
      </div>
    );
  }

  private onClosePdf = () => {
    this.setState({
      pdfFile: undefined,
    });
  }

  private onGetPdf = (record: IDocumentHistory) => {
    this.setState({
      pdfLoading: true,
    });

    this.documentHistoryService
      .getPdf(record.id)
      .then((data) => {
        this.setState({
          pdfLoading: false,
          pdfFile: data
        });
      })
      .catch((err: any) => {
        console.log(err);
        this.setState({
          pdfLoading: false,
        });
      });
  };


  private onChange = (
    pagination: any,
    filters: Record<never, string[]>,
    sorter: any,
    extra: any
  ) => {

    pagination = this.flybyUtils.getCurrentPage(pagination);

    this.searchDocumentHistory(
      this.state.queryText,
      this.state.queryPlatformId,
      pagination.pageSize,
      pagination.current,
      "date,desc",
      this.state.queryDate,
      this.state.queryType
    );
  };

  private searchDocumentHistory = (
    query: string,
    platformId: string,
    pageSize: number | undefined,
    current: number | undefined,
    sort: string,
    date: Moment,
    type: string
  ) => {
    if (!this.state.dataLoading) {
      this.setState({
        dataLoading: true,
      });
    }

    this.documentHistoryService
      .search(query, platformId, pageSize, current, sort, date, type)
      .then((data: any) => {
        if (data !== undefined) {
          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  };

  private onSubmit = (values: any) => {
    let date = values.type !== "ORDER" ? values.date : undefined;
    this.setState({
      dataLoading: true,
      queryDate: date,
      queryText: values.text,
      queryType: values.type,
      queryPlatformId: values.platform,
    });

    this.searchDocumentHistory(
      values.text,
      values.platform,
      20,
      0,
      "date,desc",
      date,
      values.type
    );
  };
}

export default DocumentHistory;
