import React from 'react';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import { Breadcrumb, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import ICatalogSearch from '../model/ICatalogSearch';
import { withRouter } from 'react-router';

class CatalogBreadcrumb extends React.Component<
  {
    history: any,
    setTypeSearch: any,
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
    clearCatalogSearch: any,
    setSelectedArticle: any,
  }, {}>{

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => contextValues.catalogSearch.level1Id !== undefined &&
          <Breadcrumb style={{ marginBottom: 15 }}>

            <Breadcrumb.Item >
              <FontAwesomeIcon icon={faSearch} style={{ marginRight: 5 }} />
              <Button onClick={this.onCancelSearch} type={"link"} style={{ margin: 0, padding: 0 }}>Nouvelle recherche</Button>
            </Breadcrumb.Item>

            {contextValues.catalogSearch.level1Name !== undefined && (contextValues.catalogSearch.queryType === 'vehicleNode' || contextValues.catalogSearch.queryType === 'characteristic') &&
              <Breadcrumb.Item >
                <Button onClick={this.onCancelLevel2} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.catalogSearch.level1Name}</Button>
              </Breadcrumb.Item>
            }

            {contextValues.catalogSearch.level1Name !== undefined && contextValues.catalogSearch.queryType === 'family' &&
              <Breadcrumb.Item >
                <Button onClick={this.onCancelSearch} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.catalogSearch.level1Name}</Button>
              </Breadcrumb.Item>
            }

            {contextValues.catalogSearch.level2Name !== undefined && (contextValues.catalogSearch.queryType === 'vehicleNode' || contextValues.catalogSearch.queryType === 'characteristic') &&
              <Breadcrumb.Item >
                <Button onClick={this.onCancelLevel3} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.catalogSearch.level2Name}</Button>
              </Breadcrumb.Item>
            }

            {contextValues.catalogSearch.level2Name !== undefined && contextValues.catalogSearch.queryType === 'family' &&
              <Breadcrumb.Item >
                <span>{contextValues.catalogSearch.level2Name}</span>
              </Breadcrumb.Item>
            }            

            {contextValues.catalogSearch.level3Name !== undefined &&
              <Breadcrumb.Item >
                <Button onClick={this.onCancelLevel3} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.catalogSearch.level3Name}</Button>
              </Breadcrumb.Item>
            }

            {contextValues.catalogSearch.level4Name !== undefined &&
              <Breadcrumb.Item >
                <Button onClick={this.onCancelLevel4} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.catalogSearch.level4Name}</Button>
              </Breadcrumb.Item>
            }

            {contextValues.catalogSearch.level5Name !== undefined &&
              <Breadcrumb.Item >
               <Button onClick={this.onCancelLevel5} type={"link"} style={{ margin: 0, padding: 0 }}>{contextValues.catalogSearch.level5Name}</Button>
              </Breadcrumb.Item>
            }

            {contextValues.catalogSearch.modelYear !== undefined &&
              <Breadcrumb.Item >
                <span>{contextValues.catalogSearch.modelYear}</span>
              </Breadcrumb.Item>
            }            

          </Breadcrumb>

        }
      </BasketContext.Consumer>
    );
  }


  private onCancelLevel2 = () => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level2Id: undefined,
      level2Name: undefined,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined
    }
    this.props.setCatalogSearch(catalogSearch);
    this.props.setSelectedArticle(undefined);
  }

  private onCancelLevel3 = () => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined
    }
    this.props.setCatalogSearch(catalogSearch);
    this.props.setSelectedArticle(undefined);
  }

  private onCancelLevel4 = () => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      /*modelYear: undefined*/
    }
    this.props.setCatalogSearch(catalogSearch);
    this.props.setSelectedArticle(undefined);
  }

  private onCancelLevel5 = () => {
    
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      /*modelYear: undefined*/
    }
    this.props.setCatalogSearch(catalogSearch);
    this.props.setSelectedArticle(undefined);
  }  

  private onCancelSearch = () => {
    this.props.clearCatalogSearch();
    this.props.setTypeSearch(0);
    this.props.setSelectedArticle(undefined);
    this.props.history.push('/home');
  }
}

export default withRouter(withBasket(CatalogBreadcrumb));     