import React, { useCallback, useEffect, useRef, useState } from "react";
import LoginForm from "./LoginForm";
import ParameterService from "../../Utils/ParameterService";
import { Modal } from "antd";
import LoginService from "./LoginService";
import OpenAccount from "./OpenAccount";

const defaultLoginTemplate = `
<style>
  #logoLogin {
    margin-top : 150px;
    width: 100%;
    text-align: center;
    display:block;
  }
  #logoLogin img {
    width: 150px;
    margin-bottom: 5px;
  }
  #loginContainer {
    background: white;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>
<div id='logoLogin'><img src='/api/market/logo' alt='logo' /></div>
`;
interface LoginProps {
  onLogin: (isLoging: boolean) => void;
}

const Login: React.FC<LoginProps> = (props: {
  onLogin: (isLoging: boolean) => void;
}) => {

  const { onLogin } = props;
  const [page, setPage] = useState<string>(defaultLoginTemplate);
  const [openModalAccount, setOpenModalAccount] = useState(false);

  const wrapper = useRef<HTMLDivElement>(null);

  const parameterService: ParameterService = new ParameterService();
  const loginService: LoginService = new LoginService();

  const addEventListener = useCallback(
    (selector: string, listener: () => void) =>
      wrapper.current?.querySelector(selector)?.addEventListener("click", listener)
    ,
    // eslint-disable-next-line
    [wrapper.current]);

  const closeModalAccount = useCallback(
    () => {
      setOpenModalAccount(false);
    }, []);

  const loginToGuest = useCallback(
    () => {

      const loginInfos = {
        username: 'GUEST',
        password: '3!Y$zZ96rdT($tM#'
      }

      loginService.login(loginInfos).then((success: boolean) => {
        if (success) {
          onLogin(true);
        }
      });
    }, [loginService, onLogin]);

  useEffect(() => {

    parameterService.findInitParameters().then((data: any[]) => {
      if (data) {
        data.forEach(p => {
          switch (p.name) {
            case "TITLE":
              document.title = p.value;
              break;
            case "FAVICO":
              var link: any = document.querySelector("link[rel~='icon']");

              if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
              }
              link.href = p.value;
              break;
          }
        });
      }
    });

    parameterService.getPublicHtmlPage("login-template.html")
      .then((response: string) => {
        if (response !== '') {
          setPage(response);
        }
      });

  }, [parameterService]);


  useEffect(() => {
    addEventListener("#guestLogin", () => {
      loginToGuest();
    });

    addEventListener("#openAccount", () => {
      setOpenModalAccount(true);
    });



  }, [addEventListener, loginToGuest])

  return (
    <div ref={wrapper}>

      <div dangerouslySetInnerHTML={{ __html: page }}></div>

      <LoginForm onLogin={onLogin} />

      <Modal visible={openModalAccount} destroyOnClose
        closable={false}
        title="Demande d'ouverture d'un compte"
        style={{ top: 20 }}
        width={450}
        bodyStyle={{ height: 650 }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}>
        <OpenAccount closeModal={closeModalAccount} />
      </Modal>
    </div >
  );
}

export default Login;
