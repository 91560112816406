import { Moment } from "moment";

class DocumentHistoryService {
  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");

  public async search(
    query: string,
    platformId: string,
    size: number | undefined,
    page: number | undefined,
    sort: string,
    date: Moment | null,
    type: string,
    includeDetails?: boolean,
  ): Promise<any> {
    let url =
      "size=" +
      size +
      "&page=" +
      page +
      "&type=" +
      type +
      "&customer=" +
      this.customerId;

    if (sort !== undefined && sort !== "") {
      url = url + "&sort=" + sort;
    }

    if (date !== null && date !== undefined) {
      let fromDate = date.startOf("month").toISOString();
      let toDate = date.clone().endOf("month").toISOString();
      url += "&fromDate=" + fromDate + "&toDate=" + toDate;
    }

    if (query !== "") {
      url += "&query=" + query;
    }

    if (platformId !== undefined && platformId !== "") {
      url += "&platformId=" + platformId;
    }

    if (includeDetails) {
      url += "&include=documentHistoryDetails";
    }

    return fetch("/api/market/documentHistories?" + url, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getDocumentHistoryDetailsCustom(
    size: number | undefined,
    page: number | undefined,
  ): Promise<any> {

    let url = "customer=" +
      this.customerId +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=documentHistory.date,ASC"

    url += "&documentHistoryType=CUSTOM&include=documentHistory";

    return fetch(
      "/api/market/documentHistoryDetails?" + url,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getDocumentHistoryDetails(
    documentHistoryId: number,
    size: number | undefined,
    page: number | undefined,
  ): Promise<any> {

    let url = "customer=" +
      this.customerId +
      "&size=" +
      size +
      "&page=" +
      page +
      "&sort=lineNumber,ASC"

    return fetch(
      "/api/market/documentHistories/" +
      documentHistoryId +
      "/documentHistoryDetails?" + url,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getDocumentHistoryReturnReason(
    documentHistoryDetailId: number
  ): Promise<any> {
    return fetch(
      "/api/market/documentHistoryDetails/" +
      documentHistoryDetailId +
      "/documentHistoryReturnReasons?customer=" +
      this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async addDocumentHistoryReturn(
    documentHistoryReturnReasonId: number,
    documentHistoryLineId: number,
    quantity: number
  ): Promise<any> {
    let data = {
      documentHistoryReturnReason: {
        id: documentHistoryReturnReasonId,
      },
      documentHistoryDetail: {
        id: documentHistoryLineId,
      },
      requestQuantity: quantity,
    };

    return fetch(
      "/api/market/documentHistoryReturns?customer=" + this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getDocumentHistoryReturns(
    query: string,
    size: number | undefined,
    page: number | undefined,
    sort: string
  ): Promise<any> {
    sort = "createdDate,desc";

    let url =
      "size=" +
      size +
      "&page=" +
      page +
      "&sort=" +
      sort +
      "&customer=" +
      this.customerId;
    if (query !== "") {
      url += "&query=" + query;
    }

    return fetch("/api/market/documentHistoryReturns?" + url, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getDocumentHistoryReturnableArticles(
    query: string,
    type: string | undefined,
    size: number | undefined,
    page: number | undefined,
    sort: string | undefined
  ): Promise<any> {
    let url = "size=" + size + "&page=" + page + "&sort=" + sort + "&customer=" + this.customerId;
    if (query !== "") {
      url += "&query=" + query;
    }

    if (type && type !== '') {
      url += "&type=" + type;
    }

    return fetch("/api/market/documentHistoryReturnableArticles?" + url, {
      headers: {
        Authorization: "Bearer " + this.accessToken,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async getPdf(documentHistoryId: number): Promise<any> {
    return fetch(
      "/api/market/documentHistories/" +
      documentHistoryId +
      "/pdf?customer=" +
      this.customerId,
      {
        headers: {
          Authorization: "Bearer " + this.accessToken,
          Accept: "application/pdf",
          "Content-Type": "application/pdf;charset=UTF-8",
        },
      }
    )
      .then((response: any) => {
        return response.blob();
      })
      .then((data) => {
        return data;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
}

export default DocumentHistoryService;
