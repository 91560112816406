import React from 'react';
import '../../App.css';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Row, Col, InputNumber, Typography, Tag, Statistic } from 'antd';

import { withBasket } from '../../Utils/BasketProvider';
import IArticle from './IArticle';
import IAvailability from './IAvailability';
import FlybyUtils from '../../Utils/FlybyUtils';
import ArticleUtils from './ArticleUtils';

const { Text } = Typography;

class SuggestedQuantityForm extends React.Component<
  {
    addArticle: any,
    item: IArticle,
  },
  {
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private articleUtils: ArticleUtils = new ArticleUtils();
  private timer: any = null;

  render() {

    return (
      <Row>
        <Col span={24} >

          <Text strong style={{ paddingLeft: 5 }}>Article : </Text>{this.props.item.name}<br />
          <Text strong style={{ paddingLeft: 5 }}>Référence : </Text>{this.props.item.reference}<br />
          <br />

          <Row align='middle'>
            <Col span={7} style={{ padding: 10, border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px' }}>
              {this.props.item.availability !== undefined &&
                <div>
                  <Text strong>{this.props.item.availability.platform.name}</Text><br />
                  <Text strong>Quantité : {this.props.item.quantity}</Text><br />
                  <Tag style={{ marginTop: 5 }} color={this.flybyUtils.getAvailabilityColor(this.props.item.availability.state, this.props.item.availability.locallyAvailable,
                    this.props.item.availability.deliveredAt, this.props.item.availability.quantityBackorder, this.props.item.availability.longerAvailabilityAlert)}>
                    {this.flybyUtils.getAvailabilityText(this.props.item.availability.state, this.props.item.availability.deliveredAt,
                      this.props.item.availability.quantityBackorder, this.props.item.availability.longerAvailabilityAlert)}
                  </Tag>
                  <Statistic style={{ marginTop: 5 }} title="Montant net" value={this.flybyUtils.numberFormat(this.props.item.availability.netAmount, 2)} suffix="€ HT" />
                </div>
              }
            </Col>
            <Col span={2} style={{ textAlign: "center" }}>
              <DoubleRightOutlined style={{ fontSize: '24px' }} />
            </Col>
            <Col span={15} style={{ padding: 0, border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px' }}>

              <Row style={{ fontWeight: "bold", background: "#FAFAFA", padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
                <Col span={6} >Plateforme</Col>
                <Col span={6} style={{ paddingLeft: 5, textAlign: "right" }}>Disponible</Col>
                <Col span={6} style={{ paddingLeft: 5, textAlign: "right" }}>Proposition</Col>
                <Col span={6} style={{ paddingLeft: 5, textAlign: "right" }}>Commandée</Col>
              </Row>

              {this.props.item.availability !== undefined &&
                <Row key={this.props.item.availability.platform.id} style={{ padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
                  <Col span={6} style={{ paddingLeft: 5, paddingTop: 3 }}>{this.props.item.availability.platform.name}{this.props.item.availability.platform.attached ? '*' : ''}</Col>
                  <Col span={6} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 3 }}>{this.props.item.availability.quantityAvailable}</Col>
                  <Col span={6} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 3 }}>{this.props.item.availability.suggestedQuantity}</Col>
                  <Col span={6} style={{ paddingLeft: 5, textAlign: "right" }}>
                    <InputNumber step={this.props.item.availability.salePackaging}
                      min={0}
                      max={this.props.item.availability.backorderAccepted ? Infinity : this.props.item.availability.quantityAvailable}
                      defaultValue={this.props.item.quantity}
                      onChange={(value: any) => this.onChangeQuantity(value, this.props.item.availability)} />
                  </Col>
                </Row>
              }

              {this.props.item.moreAvailabilities !== undefined &&
                this.props.item.moreAvailabilities.map((moreAvailability: IAvailability) =>
                  <Row key={moreAvailability.platform.id} style={{ padding: 5 }}>
                    <Col span={6} style={{ paddingLeft: 5, paddingTop: 3 }}>{moreAvailability.platform.name}{moreAvailability.platform.attached ? '*' : ''}</Col>
                    <Col span={6} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 3 }}>{moreAvailability.quantityAvailable}</Col>
                    <Col span={6} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 3 }}>{moreAvailability.suggestedQuantity}</Col>
                    <Col span={6} style={{ paddingLeft: 5, textAlign: "right" }}>
                      <InputNumber step={moreAvailability.salePackaging}
                        min={0}
                        max={moreAvailability.backorderAccepted ? Infinity : moreAvailability.quantityAvailable}
                        defaultValue={0}
                        onChange={(value: any) => this.onChangeQuantity(value, moreAvailability)} />
                    </Col>
                  </Row>
                )}
            </Col>

            <Col span={9}></Col>
            <Col span={15}>*Plateforme de rattachement</Col>

          </Row>

        </Col>
      </Row>
    );
  }

  private onChangeQuantity = (value: number, availability: IAvailability | undefined) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (value !== undefined && availability !== undefined && value >= 0
        && this.articleUtils.checkQuantityVsSalePackaging(value, availability.salePackaging)) {
        availability.quantityOrdered = value;
      }
    }, 500);
  }

}

export default withBasket(SuggestedQuantityForm);        