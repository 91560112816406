import React from 'react';
import { notification } from 'antd';

import ArticleService from './ArticleService';
import IArticlePlatforms from './IArticlePlatforms';
import IArticle from './IArticle';
import IAvailability from './IAvailability';
import IArticleEquivalence from './IArticleEquivalence';
import BookmarkService from '../bookmark/BookmarkService';
import IArticleSearchResult from './IArticleSearchResult';

class ArticleUtils {

  private attachedPlatformId: number = Number(sessionStorage.getItem("attachedPlatformId"));

  private articleService: ArticleService = new ArticleService();  
  private bookmarkService: BookmarkService = new BookmarkService();

  public checkQuantityVsSalePackaging = (quantity: number, salePackaging: number): boolean => {
    let success = true;

    if (quantity > 0 && quantity % salePackaging) {
      success = false;
      notification.error({ message: 'Quantité', description: 'La quantité renseignée n\'est pas correcte. Elle doit être un multiple du conditionnement de vente.' });
    }

    return success;
  }

  public onChangeQuantity = (parent: React.Component, quantity: number, platformId: number, item: IArticle) => {

    let articlePlatform: IArticlePlatforms | undefined = item.articlePlatforms.find((x: IArticlePlatforms) => x.id === this.attachedPlatformId);
    
    if (articlePlatform !== undefined) {
      if(this.checkQuantityVsSalePackaging(quantity, articlePlatform.salePackaging)){
        this.checkArticleAvailability(parent, platformId, item, true);
      }
    }

  }

  public onChangeSelect = (parent: React.Component, platformId: number, item: IArticle) => {
    this.checkArticleAvailability(parent, platformId, item, true);
  }

  public checkArticleAvailability = (parent: React.Component, platformId: number | undefined, item: IArticle, itemRefresh: boolean) => {

    if (itemRefresh) {
      item.loading = true;
      parent.forceUpdate();
    }

    this.articleService.articleAvailability(item.id, item.quantity, undefined)
      .then((result: IAvailability[]) => {

        item.moreAvailabilitiesShow = false;
        let moreAvailabilities: IAvailability[] = [];
        item.listAvailabilities = result;

        if (result !== undefined) {

          if (platformId === undefined) {
            item.availability = result[0];

            if (item.availability.state !== "AVAILABLE") {
              item.moreAvailabilitiesShow = true;
            }

            result.forEach((availability: IAvailability) => {
              if (item.availability !== undefined && availability.platform.id !== item.availability.platform.id && availability.state !== "UNAVAILABLE") {
                moreAvailabilities.push(availability);
              }
            });
          }

          if (platformId !== undefined) {
            
            result.forEach((availability: IAvailability) => {

              if ( availability.platform.id === platformId) {
                item.availability = availability;

                if (availability.state !== "AVAILABLE") {
                  item.moreAvailabilitiesShow = true;
                }
              }
              else if (availability.platform.id !== platformId && availability.state !== "UNAVAILABLE") {
                moreAvailabilities.push(availability);
              }
            });
          }

          item.moreAvailabilities = moreAvailabilities;
          item.loading = false;

          parent.forceUpdate();
        }
        else {
          item.loading = false;
          parent.forceUpdate();
        }

      });
  }

  public findEquivalences = (parent: React.Component, item: IArticle, itemRefresh: boolean) => {

    this.articleService.findEquivalences(item.id, 50, 0)
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined && item.equivalence !== undefined) {
          item.equivalence.loading = false;
          item.equivalence.list = result.content;

          item.equivalence.list.forEach((equivalent: IArticleEquivalence) => {
            equivalent.equivalentArticle.quantity = 1;
          });

          if(itemRefresh){
            parent.forceUpdate();
          }
        }
      });

  }

  public onAddBookmark = (parent: React.Component, item: IArticleSearchResult) => {

    let customerId = sessionStorage.getItem("customerId");

    if (customerId !== null) {
      this.bookmarkService.addArticleBookmark(item.article, Number(customerId))
        .then((response: any) => {
          if (response.status !== 201) {
            notification.error({ message: 'Favoris', description: 'Impossible d\'ajout cet article à vos favoris.' });
          }
          else {
            notification.info({ message: 'Favoris', description: 'L\'article a bien été ajouté à vos favoris.', duration: 2 });

            item.bookmarked = true;

            parent.forceUpdate();
          }
        });
    }

  }
}

export default ArticleUtils;    