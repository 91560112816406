import React from 'react';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import { List, Card} from 'antd';

import ICatalogSearch from '../model/ICatalogSearch';
import CatalogService from '../CatalogService';
import IFamily from '../model/IFamily';
import { SubFamilies } from './CatalogUtils';



class CatalogVehicleTypeFamily extends React.Component<
  {
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
  },
  {
    dataLoading: boolean,
    data: IFamily[]
  }>{

  private catalogService: CatalogService = new CatalogService();



  constructor(props: { catalogSearch: ICatalogSearch, setCatalogSearch: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: []
    };
  }

  componentDidMount() {
    if (this.props.catalogSearch.queryType === 'vehicleNode' && this.props.catalogSearch.level3Id !== undefined) {
      this.loadFamily(this.props.catalogSearch.level3Id, this.props.catalogSearch.modelYear, 200, 0);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => contextValues.catalogSearch.level3Id !== undefined &&
          <List size="small" loading={this.state.dataLoading}
            grid={{ gutter: 16, column: 3 }}
            dataSource={this.state.data}
            renderItem={(item: IFamily) => (
              <List.Item>
                <Card title={item.name} headStyle={{ fontSize: '0.90em' }} bodyStyle={{padding : 0}}
                  extra={item.imageName !== null && <img src={'/api/public/catalog/' + item.imageName} alt={item.name} style={{ height: 30 }} />}>

                  <SubFamilies parent={item} childrens={item.children} onSearch={this.onSearch} />
                </Card>
              </List.Item>
            )}
          />
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = (queryType: string, parentId: number, parentName: string, childrenId: number | undefined, childrenName: string | undefined) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level4Id: parentId,
      level4Name: parentName,
      level5Id: childrenId,
      level5Name: childrenName      
    }
    this.props.setCatalogSearch(catalogSearch);
  }

  private loadFamily = (vehiculeTypeId: number, modelYear: number | undefined, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findArticleFamilyByVehicleType(undefined, 1, vehiculeTypeId, modelYear, pageSize, page, 'name')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result.content,
            dataLoading: false
          });
        }
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }


}

export default withBasket(CatalogVehicleTypeFamily);     