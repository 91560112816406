import React from 'react';
import '../../../App.css';
import { Card, List, Button } from 'antd';

import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import ICatalogSearch from '../model/ICatalogSearch';
import CatalogService from '../CatalogService';
import IFamily from '../model/IFamily';


class CatalogFamilySearch extends React.Component<
  {
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any
  },
  {
    dataLoading: boolean,
    data: IFamily[] | undefined
  }>{

  private catalogService: CatalogService = new CatalogService();

  constructor(props: { catalogSearch: ICatalogSearch, setCatalogSearch: any }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: undefined,
    };
  }

  componentDidMount() {
    if (this.props.catalogSearch.queryType === 'family' && this.props.catalogSearch.level1Id !== undefined && this.props.catalogSearch.level2Id !== undefined) {
      this.loadFamily(this.props.catalogSearch.level1Id, 200, 0);
    }
  }

  componentWillReceiveProps(newProps: { catalogSearch: ICatalogSearch, setCatalogSearch: any }) {

    if (newProps.catalogSearch.queryType === 'family' && newProps.catalogSearch.level1Id !== undefined && newProps.catalogSearch.level2Id !== undefined
      && (newProps.catalogSearch.level1Id !== this.props.catalogSearch.level1Id || newProps.catalogSearch.level1Id !== this.props.catalogSearch.level1Id)) {
      this.loadFamily(newProps.catalogSearch.level1Id, 200, 0);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) => (contextValues.catalogSearch.queryType === 'family' && contextValues.catalogSearch.level2Id !== undefined) &&
          <Card size="small" title={contextValues.catalogSearch.level1Name} style={{ marginTop: 5 }} bordered={true} headStyle={{ background: "rgb(236, 236, 236)" }}>
            <div style={{ marginLeft: -10, marginRight: -10, marginBottom: -5 }}>
              <List size="small" split={false} dataSource={this.state.data} loading={this.state.dataLoading}
                renderItem={(children: IFamily) => (
                  <List.Item style={{ border: 0, paddingTop: 4, paddingBottom: 4 }}>
                    <Button onClick={() => this.onSearch(children)}
                      style={{ paddingLeft: 4, textAlign: 'left', width: '100%', whiteSpace: 'initial', height: 'fit-content' }} type='link'>
                      {children.name}
                    </Button>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        }
      </BasketContext.Consumer>
    );

  }

  private onSearch = (children: IFamily) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level2Id: children.id,
      level2Name: children.name
    }
    this.props.setCatalogSearch(catalogSearch);

  }

  private loadFamily = (parentId: number, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findArticleFamily(parentId, 1, false, pageSize, page, 'name')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            data: result.content,
            dataLoading: false
          });
        }
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }
}

export default withBasket(CatalogFamilySearch);     