import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Tag, Skeleton, Button } from 'antd';

import IArticle from './IArticle';
import FlybyUtils from '../../Utils/FlybyUtils';
import LoginService from '../login/LoginService';
import ArticleAvailabilityRequestInformation from './component/ArticleAvailabilityRequestInformation';
import ParameterService from '../../Utils/ParameterService';
import parse from 'html-react-parser';

const { Text } = Typography;

class ArticleAvailability extends React.Component<
  {
    item: IArticle,
    loading: boolean,
    showAvailability: boolean,
    showManufacturer: boolean,
    openModalAccount?: () => void;
  },
  {
    role: string,
    availabilityText1: string
    availabilityText2: string
  }
>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private loginService: LoginService = new LoginService();
  private parameterService: ParameterService = new ParameterService();
  private showPlatformList: boolean = sessionStorage.getItem("showPlatformList") === 'false' ? false : true;

  private accessToken = sessionStorage.getItem("accessToken");

  constructor(props: { item: IArticle, loading: boolean, showAvailability: boolean, showManufacturer: boolean, openModalAccount?: () => void; }) {
    super(props);

    this.state = {
      role: '',
      availabilityText1: 'Pour connaitre le prix et la disponibilité vous devez avoir une compte client',
      availabilityText2: 'Contactez nous par mail',
    };

  }

  componentDidMount() {

    if (this.accessToken !== null) {
      this.loginService.session(this.accessToken).then((sessionData: any) => {
        this.setState({
          role: sessionData ? sessionData.user.role.name : ''
        });
      });
    }

    this.parameterService.findValue("GUEST_AVAILABILITY_TEXT")
      .then((value: any) => {
        if (value) {
          const text = JSON.parse(value);
          this.setState({
            availabilityText1: text.text1,
            availabilityText2: text.text2,
          });
        }
      });

  }

  render() {
    return (
      <Row style={{ marginLeft: 10, marginRight: 10 }}>
        <Col span={12} >
          {this.props.item.availability &&
            <>
              <Tag color={this.flybyUtils.getAvailabilityColor(this.props.item.availability.state, this.props.item.availability.locallyAvailable,
                this.props.item.availability.deliveredAt, this.props.item.availability.quantityBackorder, this.props.item.availability.longerAvailabilityAlert)}>
                {this.flybyUtils.getAvailabilityText(this.props.item.availability.state, this.props.item.availability.deliveredAt,
                  this.props.item.availability.quantityBackorder, this.props.item.availability.longerAvailabilityAlert)}
              </Tag>
              {this.flybyUtils.isSupplierAvailability(this.props.item.availability)}
              <br />
            </>
          }
          <Text strong>Référence :</Text> {this.props.item.reference}<br />
          <Text strong>EAN(s) :</Text> {this.flybyUtils.getEans(this.props.item.eans)}<br />
          {this.props.showManufacturer &&
            <><Text strong>Fabricant :</Text> {this.props.item.manufacturer.name}<br /></>
          }
        </Col>
        <Col span={12} >
          <Skeleton loading={this.props.item.loading} active>
            {this.props.item.availability !== undefined && this.state.role !== 'ROLE_GUEST' &&
              <div key={this.props.item.id}>
                <Text strong>Prix unitaire € HT : </Text>{this.flybyUtils.numberFormat(this.props.item.availability.grossUnitPrice, 2)}<br />
                <Text strong>Remise % : </Text> {this.flybyUtils.numberFormat(this.props.item.availability.discount, 2)}<br />
                <Text strong>Vendu par : </Text>{this.props.item.availability.salePackaging}<br />
                <Text strong >Date de livraison : </Text><ArticleAvailabilityRequestInformation articleId={this.props.item.id} date={this.props.item.availability.deliveredAt} /><br />
                {this.showPlatformList && this.props.item.availability.locallyAvailable &&
                  <span><Text strong >Provenance : </Text>{this.props.item.availability.description}<br /></span>
                }
                {this.showPlatformList && !this.props.item.availability.locallyAvailable &&
                  <span><Text strong >Provenance : </Text><Text strong type="danger"><WarningOutlined /> Autre plateforme</Text><br /></span>
                }
                {this.props.item.availability.consignmentPrice > 0 &&
                  <span><Text strong>Consigne : </Text>{this.flybyUtils.numberFormat(this.props.item.availability.consignmentPrice, 2)}<br /></span>
                }
                {this.props.showAvailability &&
                  <span><Text strong>Montant net € HT : </Text>{this.flybyUtils.numberFormat(this.props.item.availability.netAmount, 2)}<br /></span>
                }
              </div>
            }
            {this.props.item.availability === undefined && this.state.role !== 'ROLE_GUEST' &&
              <div key={this.props.item.id}>
                <Row>
                  <Col flex={'50px'}>
                    <Text type="warning"><WarningOutlined style={{ fontSize: 36 }} /></Text>
                  </Col>
                  <Col flex={'200px'}>
                    <Text type="warning">Impossible d'obtenir la disponibilité depuis la plateforme.</Text>
                  </Col>
                </Row>
              </div>
            }
            {this.state.role === 'ROLE_GUEST' &&
              <div key={this.props.item.id}>
                <Text type="secondary">{parse(this.state.availabilityText1)}</Text>
                <Button type='link' style={{ padding: 0 }} onClick={this.props.openModalAccount}>{parse(this.state.availabilityText2)}</Button>
              </div>
            }
          </Skeleton>
        </Col>
      </Row>
    );
  }

}

export default ArticleAvailability;