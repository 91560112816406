import React from 'react';
import { Skeleton, Tag, Tabs, Typography } from 'antd';

import IArticle from './IArticle';
import IArticleEquivalence from './IArticleEquivalence';
import FlybyUtils from '../../Utils/FlybyUtils';
import ArticleService from './ArticleService';
import IArticleLinkage from '../catalog/model/IArticleLinkage';
import CatalogArticleLinkage from './CatalogArticleLinkage';
import ArticleDocument from './ArticleDocument';
import { BasketContext, withBasket } from '../../Utils/BasketProvider';
import ICatalogSearch from '../catalog/model/ICatalogSearch';
import CatalogArticleCriterion from '../catalog/component/CatalogArticleCriterion';
import ArticleTag from './ArticleTag';
import ArticleTecdocCharacteristic from './ArticleTecdocCharacteristic';
import { MarketParameterContext } from '../../Utils/MarketParameterContext';
import CatalogArticleLinkageCaracteristic from './CatalogArticleLinkageCaracteristic';
import ParameterService from '../../Utils/ParameterService';

const { TabPane } = Tabs;
const { Text } = Typography;

interface IArticleTab {
  defaultTab: string,
  tab1: {
    id: string,
    show: boolean,
    name: string
  },
  tab2: {
    id: string,
    show: boolean,
    name: string
  },
  tab3: {
    id: string,
    show: boolean,
    name: string
  },
  tab4: {
    id: string,
    show: boolean,
    name: string
  },
  tab5: {
    id: string,
    show: boolean,
    name: string
  },
  tab6: {
    id: string,
    show: boolean,
    name: string
  },
  tab7: {
    id: string,
    show: boolean,
    name: string
  }
}

const defaultArticleTab = {
  defaultTab: "1",
  tab1: {
    id: "1",
    show: true,
    name: "Equivalence(s)"
  },
  tab2: {
    id: "2",
    show: true,
    name: "Compatibilité"
  },
  tab3: {
    id: "3",
    show: false,
    name: "Caractéristiques"
  },
  tab4: {
    id: "4",
    show: false,
    name: "Caractéristiques"
  },
  tab5: {
    id: "5",
    show: false,
    name: "Types de machines"
  },
  tab6: {
    id: "6",
    show: false,
    name: "Véhicules compatibles"
  },
  tab7: {
    id: "7",
    show: false,
    name: "Documentation"
  }
}
class ArticleEquivalence extends React.Component<
  {
    catalogSearch: ICatalogSearch,
    item: IArticle,
    isModal: boolean
    onSelect: any,
    infosTecdoc: boolean,
    infosCatalogF1: boolean,
  },
  {
    dataLoading: boolean,
    articleLinkage: IArticleLinkage[] | undefined,
    showArticleDocument: boolean,
    article: IArticle | undefined,
    articleTab: IArticleTab
  }
> {

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private articleService: ArticleService = new ArticleService();
  private parameterService: ParameterService = new ParameterService();

  private customerId = sessionStorage.getItem("customerId");

  constructor(props: { catalogSearch: ICatalogSearch, item: IArticle, isModal: boolean, onSelect: any, infosTecdoc: boolean, infosCatalogF1: boolean }) {
    super(props);

    this.state = {
      dataLoading: false,
      articleLinkage: undefined,
      showArticleDocument: false,
      article: undefined,
      articleTab: defaultArticleTab
    };
  }

  componentDidMount() {

    this.parameterService.findValue("ARTICLE_TAB")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            articleTab: JSON.parse(value),
          });
        }

        this.loadArticleDocument(this.props.item.id);

        this.setState({
          article: this.props.item,
        });
      });
  }

  componentWillReceiveProps(newProps: { item: IArticle, isModal: boolean, onSelect: any }) {
    if (newProps.item.id !== this.props.item.id) {

      this.loadArticleDocument(newProps.item.id);

      this.setState({
        article: newProps.item,
      });
    }
  }

  render() {
    return (
      <MarketParameterContext.Consumer>
        {(parameters) =>
          <BasketContext.Consumer>
            {(contextValues) =>
              <Tabs defaultActiveKey={this.state.articleTab.defaultTab} >
                {this.state.articleTab.tab1.show &&
                  <TabPane tab={this.state.articleTab.tab1.name} key={this.state.articleTab.tab1.id}>
                    <Skeleton loading={this.props.item.equivalence !== undefined && this.props.item.equivalence.loading} active>
                      {this.props.item.equivalence !== undefined && this.props.item.equivalence.list !== undefined && this.props.item.equivalence.list.length > 0 &&
                        this.props.item.equivalence.list.map((articleEquivalence: IArticleEquivalence) =>
                          <Tag key={articleEquivalence.id} onClick={this.props.isModal ? () => { } : () => this.props.onSelect(articleEquivalence.equivalentArticle.id)}
                            style={this.props.isModal ? { marginBottom: 2, padding: 3, textAlign: "center" } : { marginBottom: 2, padding: 3, textAlign: "center", cursor: "pointer" }}>
                            <span>
                              <img src={'/api/market/manufacturers/' + articleEquivalence.equivalentArticle.manufacturer.id + '/logo?customer=' + this.customerId} alt={articleEquivalence.equivalentArticle.reference} key={articleEquivalence.equivalentArticle.reference} style={{ height: 20 }} />
                              <br />
                            </span>
                            {parameters.SHOW_MANUFACTURER &&
                              <span style={{ color: this.flybyUtils.getAvailabilityEquivalenceColor(articleEquivalence.available) }}>
                                {articleEquivalence.equivalentArticle.manufacturer.name + ' :: ' + articleEquivalence.equivalentArticle.reference}
                              </span>
                            }
                            {!parameters.SHOW_MANUFACTURER &&
                              <span style={{ color: this.flybyUtils.getAvailabilityEquivalenceColor(articleEquivalence.available) }}>
                                {articleEquivalence.equivalentArticle.reference}
                              </span>
                            }
                          </Tag>
                        )}
                      {(this.props.item.equivalence === undefined || this.props.item.equivalence.list === undefined
                        || this.props.item.equivalence.list.length <= 0) &&
                        <Text type="secondary">Nous n'avons pas trouvé d'équivalence.</Text>
                      }
                    </Skeleton>
                  </TabPane>
                }

                {this.state.articleTab.tab2.show &&
                  <TabPane tab={this.state.articleTab.tab2.name} key={this.state.articleTab.tab2.id}>
                    <ArticleTag articleId={this.props.item.id} />
                  </TabPane>
                }

                {this.state.articleTab.tab3.show && this.props.infosTecdoc &&
                  <TabPane tab={this.state.articleTab.tab3.name} key={this.state.articleTab.tab3.id} >
                    <ArticleTecdocCharacteristic item={this.props.item} />
                  </TabPane>
                }
                {this.state.articleTab.tab4.show && this.props.infosCatalogF1 && contextValues.catalogSearch.queryType === 'characteristic' && contextValues.userRole !== 'ROLE_GUEST' &&
                  <TabPane tab={this.state.articleTab.tab4.name} key={this.state.articleTab.tab4.id}>
                    <CatalogArticleCriterion articleId={this.props.item.id} />
                  </TabPane>
                }
                {this.state.articleTab.tab5.show && this.props.infosCatalogF1 && contextValues.catalogSearch.queryType === 'characteristic' && contextValues.userRole !== 'ROLE_GUEST' &&
                  <TabPane tab={this.state.articleTab.tab5.name} key={this.state.articleTab.tab5.id}>
                    <CatalogArticleLinkageCaracteristic articleId={this.props.item.id} />
                  </TabPane>
                }
                {this.state.articleTab.tab6.show && this.props.infosCatalogF1 && contextValues.catalogSearch.queryType !== 'characteristic' && contextValues.userRole !== 'ROLE_GUEST' &&
                  <TabPane tab={this.state.articleTab.tab6.name} key={this.state.articleTab.tab6.id}>
                    <CatalogArticleLinkage articleId={this.props.item.id} />
                  </TabPane>
                }
                {this.state.articleTab.tab7.show && this.state.showArticleDocument &&
                  <TabPane tab={this.state.articleTab.tab7.name} key={this.state.articleTab.tab7.id}>
                    <ArticleDocument articleId={this.props.item.id} />
                  </TabPane>
                }
              </Tabs>
            }
          </BasketContext.Consumer>
        }
      </MarketParameterContext.Consumer>
    );
  }

  private loadArticleDocument = (articleId: number) => {
    this.articleService.findArticleDocuments(articleId, 1, 0).then((result) => {
      if (result && result.content.length > 0) {
        this.setState({
          showArticleDocument: true,
        });
      }
      else {
        this.setState({
          showArticleDocument: false,
        });
      }
    });
  }

}

export default withBasket(ArticleEquivalence);