import React from 'react';
import { List, Form, Row, Col, Tooltip, Button, Statistic, Input, Select, Popconfirm, Checkbox, Tag, InputNumber, Typography } from 'antd';
import { SyncOutlined, DeleteOutlined, BulbOutlined, WarningOutlined } from '@ant-design/icons';

import FlybyUtils from '../../Utils/FlybyUtils';
import IWishlist, { SelectState } from '../home/IWishlist';
import FrancoView from '../../Utils/FrancoView';
import IWishlistArticle from '../home/IWishlistArticles';
import FlybyList from '../../Utils/FlybyList';
import IWishlistTotal from '../home/IWishlistTotal';
import IStep from './IStep';
import { DefaultParameter } from '../../Utils/MarketParameterContext';

const flybyUtils: FlybyUtils = new FlybyUtils();
const { Countdown } = Statistic;
const { Text } = Typography;
const showPlatformList: boolean = sessionStorage.getItem("showPlatformList") === 'false' ? false : true;

export const ShoppingCartPlatformArticlesList = ((props: { wishlist: IWishlist, onSelectItem: any, onRefreshAvailability: any, onSuggestedQuantity: any, onChangeQuantity: any, deleteArticle: any, parameters: DefaultParameter }) => {
  return (
    <FlybyList
      dataSource={props.wishlist.wishlistArticles}
      renderItem={(item: IWishlistArticle) => (
        <List.Item style={{ padding: 0, margin: 0, border: 0 }}>
          <ShoppingCartPlatformArticle item={item} wishlist={props.wishlist} onSelectItem={props.onSelectItem}
            onRefreshAvailability={props.onRefreshAvailability} onSuggestedQuantity={props.onSuggestedQuantity}
            onChangeQuantity={props.onChangeQuantity} deleteArticle={props.deleteArticle} parameters={props.parameters} />
        </List.Item>
      )}>
    </FlybyList>
  );
});

export const ShoppingCartPlatformArticle = ((props: { item: IWishlistArticle, wishlist: IWishlist, onSelectItem: any, onRefreshAvailability: any, onSuggestedQuantity: any, onChangeQuantity: any, deleteArticle: any, parameters: DefaultParameter }) => {
  return (
    <div key={props.item.id} style={{ width: '100%' }}>

      <Row key={'item' + props.item.id} style={{ padding: 5 }}>
        <Col span={1} style={{ paddingTop: 6 }}><Checkbox checked={props.item.selected} onChange={(checkedValue: any) => props.onSelectItem(checkedValue, props.item)} /></Col>
        <Col span={2} style={{ paddingLeft: 5, paddingTop: 6 }}>{props.item.article.reference}</Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>{props.item.article.name}</Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>{props.parameters.SHOW_MANUFACTURER ? props.item.article.manufacturer.name : ''}</Col>
        <Col span={3} style={{ paddingLeft: 5, paddingTop: 6 }}>
          <Tooltip title="Interroger la plateforme">
            <Button size="small" onClick={() => props.onRefreshAvailability(props.item, props.wishlist.platform.id)} style={{ marginRight: 2 }} >
              {props.item.article.loading && <SyncOutlined spin />}
              {!props.item.article.loading && <SyncOutlined />}
            </Button>
          </Tooltip>

          <Tag color={flybyUtils.getAvailabilityColor(props.item.state, props.item.locallyAvailable, props.item.deliveredAt, props.item.quantityBackorder, props.item.longerAvailabilityAlert)}>
            {flybyUtils.getAvailabilityText(props.item.state, props.item.deliveredAt, props.item.quantityBackorder, props.item.longerAvailabilityAlert)}
          </Tag>
          <br />
          <div style={{ marginTop: 5 }}><Text strong >Date liv. estimée : </Text><br />{flybyUtils.formatJsonDate(props.item.deliveredAt)}</div>
        </Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}><InputNumber value={props.item.quantity} min={props.item.salePackaging} step={props.item.salePackaging} onChange={(value) => props.onChangeQuantity(value, props.item, props.wishlist.id)} /></Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{flybyUtils.numberFormat(props.item.grossUnitPrice, 2)}</Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{flybyUtils.numberFormat(props.item.discount, 2)}</Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{flybyUtils.numberFormat(props.item.netUnitPrice, 2)}</Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{flybyUtils.numberFormat(props.item.netAmount, 2)}</Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>

          <Tooltip title="Suggestion de commande">
            <Button loading={props.item.article.loading} style={{ marginRight: 2 }} onClick={() => props.onSuggestedQuantity(props.item, props.wishlist.platform)} >
              <BulbOutlined />
            </Button>
          </Tooltip>

          <Popconfirm title={"Supprimer l'article " + props.item.article.reference + " du panier " + props.wishlist.name + " ?"} okText="Oui" cancelText="Non" onConfirm={() => props.deleteArticle(props.item.id)}>
            <Button ><DeleteOutlined /></Button>
          </Popconfirm>
        </Col>
      </Row>

      <Row key={'availabilityDescription' + props.item.id} style={props.item.consignmentPrice > 0 ? { padding: 5 } : { padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
        <Col span={1} style={{ paddingTop: 6 }}></Col>
        <Col span={2} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
        <Col span={7} style={{ paddingLeft: 5, paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}>
          <Text strong >Vendu par : </Text>{props.item.salePackaging}<br />
          <Text strong >EAN(s) : </Text>{flybyUtils.getFirstEan(props.item.article.eans)}<br />
          <Text strong >Date interro. plateforme : </Text>{flybyUtils.formatJsonDate(props.item.lastAvailabilityUpdateDate)}<br />
          {showPlatformList && props.item.locallyAvailable &&
            <span><Text strong >Provenance : </Text>{props.item.availabilityDescription}<br /></span>
          }
          {showPlatformList && !props.item.locallyAvailable &&
            <span><Text strong >Provenance : </Text><Text strong type="danger"><WarningOutlined /> Autre plateforme</Text><br /></span>
          }
        </Col>
        <Col span={14} style={{ paddingLeft: 5, paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
      </Row>

      {props.item.consignmentPrice > 0 &&
        <Row key={'consignment' + props.item.id} style={{ padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
          <Col span={1} style={{ paddingTop: 6 }}></Col>
          <Col span={2} style={{ paddingLeft: 5, paddingTop: 6 }}></Col>
          <Col span={2} style={{ paddingLeft: 5, paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}>Consigne</Col>
          <Col span={2} style={{ paddingLeft: 5, paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
          <Col span={3} style={{ paddingLeft: 5, paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}>{props.item.quantity}</Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}>{flybyUtils.numberFormat(props.item.consignmentPrice, 2)}</Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}>{flybyUtils.numberFormat(props.item.consignmentAmountExclTaxes, 2)}</Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", borderTop: 'dashed', borderTopColor: '#e8e8e8', borderTopWidth: '1px' }}></Col>
        </Row>
      }
    </div>
  )
});

export const ShoppingCartPlatformFranco = ((props: { wishlist: IWishlist }) => {
  return (
    <Row style={{ background: "#FAFAFA", padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
      <Col span={1} style={{ paddingTop: 6 }}></Col>
      <Col span={23} style={{ paddingLeft: 5 }}>
        <FrancoView wishlist={props.wishlist} />
      </Col>
    </Row>
  );
});

export const ShoppingCartPlatformHeader = ((props: {
  wishlist: IWishlist, listShippingMethods: [], onChangeReference: any, showCutoff: boolean,
  onChangeReference2: any, onChangeShippingMethod: any, onAllRefreshAvailability: any, onDeleteWishlist: any, onSelectAllItems: any
}) => {
  return (
    <Row style={{
      fontWeight: "bold", background: "#FAFAFA", padding: 5, borderBottom: 'solid',
      borderBottomColor: '#e8e8e8', borderBottomWidth: '1px',
      borderTop: 'solid', borderTopColor: '#e8e8e8', borderTopWidth: '1px'
    }}>
      <Col span={1} style={{ paddingTop: 6 }}><Checkbox checked={props.wishlist.selectState === SelectState.ALL ? true : false} onChange={(checkedValue: any) => props.onSelectAllItems(checkedValue, props.wishlist)} /></Col>
      <Col span={2} style={{ paddingLeft: 5, paddingTop: 10 }}>
        {props.wishlist.name}
      </Col>

      <Col span={9} style={{ paddingLeft: 5 }}>
        <Form.Item style={{ padding: 0, margin: 0 }} name={'reference2' + props.wishlist.id}
          rules={[
            {
              validator(rule: any, value: string) {
                if (value && value.indexOf('%') > -1) {
                  return Promise.reject("Le carrectère % est interdit dans le commentaire.");
                }
                return Promise.resolve();
              },
              max: 39,
              len: 39
            }
          ]}>
          <Input placeholder="Commentaire" onBlur={(value) => props.onChangeReference2(value, props.wishlist)} maxLength={39} />
        </Form.Item>
      </Col>
      <Col span={6} style={{ paddingLeft: 5 }}>
        <Form.Item style={{ padding: 0, margin: 0 }} name={'shipplingMethodId' + props.wishlist.id}>
          <Select placeholder="Mode d'expédition" onChange={(value: any) => props.onChangeShippingMethod(value, props.wishlist)}>
            {props.listShippingMethods[props.wishlist.platform.id]}
          </Select>
        </Form.Item>
      </Col>
      <Col span={4} style={{ paddingLeft: 5 }}>
        {props.wishlist.shippingMethod.deadline !== undefined && !props.wishlist.shippingMethod.cutoff && props.showCutoff &&
          <div style={{ width: 150, marginTop: 0, padding: 4, border: 'solid', borderColor: '#1890FF', borderWidth: '1px', textAlign: "center" }}>
            <Countdown onFinish={props.onAllRefreshAvailability} valueStyle={{ fontSize: 14, fontWeight: "bold", color: "#1890FF" }} value={props.wishlist.shippingMethod.deadline} format="HH:mm:ss" />
          </div>
        }
      </Col>

      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>
        <Popconfirm title={"Supprimer le panier " + props.wishlist.name + " et tous les articles qu'il contient ?"} okText="Oui" cancelText="Non" onConfirm={() => props.onDeleteWishlist(props.wishlist.id)}>
          <Button ><DeleteOutlined /></Button>
        </Popconfirm>
      </Col>
    </Row>
  );
});

export const ShoppingCartHeader = ((props: { onRefreshWishlist: any, onAllRefreshAvailability: any, wishlistTotal: any, parameters: DefaultParameter }) => {
  return (
    <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5 }}>
      <Col span={1}>
        <Tooltip title="Rafraichir le panier">
          <Button size="small" onClick={props.onRefreshWishlist} style={{ marginRight: 2 }}><SyncOutlined /></Button>
        </Tooltip>                </Col>
      <Col span={2} style={{ paddingLeft: 5 }}>Référence</Col>
      <Col span={3} style={{ paddingLeft: 5 }}>Libellé</Col>
      <Col span={3} style={{ paddingLeft: 5 }}>{props.parameters.SHOW_MANUFACTURER ? 'Fabricant' : ''}</Col>
      <Col span={3} style={{ paddingLeft: 5 }}>
        {props.wishlistTotal.articleCount > 0 && props.wishlistTotal.articleCount <= 20 &&
          <Tooltip title="Interroger la plateforme">
            <Button size="small" onClick={props.onAllRefreshAvailability} style={{ marginRight: 2 }}><SyncOutlined /></Button>
          </Tooltip>
        }
        Délai
      </Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Quantité</Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Prix tarif € HT</Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Remise %</Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Prix net € HT</Col>
      <Col span={2} style={{ paddingLeft: 5, textAlign: "right" }}>Montant € HT</Col>
      <Col span={1} style={{ paddingLeft: 5, textAlign: "right" }}></Col>
    </Row>
  );
});

export const ShoppingCartFooterAllArticles = ((props: { wishlistTotal: IWishlistTotal }) => {
  return (
    <Row style={{
      background: "#FAFAFA", padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px',
      borderTop: 'solid', borderTopColor: '#e8e8e8', borderTopWidth: '1px'
    }}>
      <Col span={7} style={{ paddingTop: 3 }}>Total de tous les articles</Col>
      <Col span={3} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}>{props.wishlistTotal.totalQuantity}</Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalAmountExclTaxes, 2)}</Col>
      <Col span={2} style={{ paddingLeft: 1, textAlign: "right" }}></Col>
    </Row>
  );
});

export const ShoppingCartFooterSelectedArticles = ((props: { wishlistTotal: IWishlistTotal }) => {
  return (
    <Row style={{ fontWeight: "bold", background: "#ECECEC", padding: 5, borderBottom: 'solid', borderBottomColor: '#e8e8e8', borderBottomWidth: '1px' }}>
      <Col span={7} style={{ paddingTop: 3 }}>Total des articles sélectionnés pour la commande</Col>
      <Col span={3} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}>{props.wishlistTotal.totalQuantitySelected}</Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}></Col>
      <Col span={2} style={{ paddingLeft: 2, textAlign: "right", paddingTop: 3 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalAmountExclTaxesSelected, 2)}</Col>
      <Col span={2} style={{ paddingLeft: 1, textAlign: "right" }}></Col>
    </Row>
  );
});

export const SaleOrderResume = ((props: { keyname: string, wishlists: IWishlist[], wishlistTotal: IWishlistTotal, stepConf: IStep, onChangeReference: any | undefined }) => {

  let span: number = 4;
  if (!props.stepConf.option.shippingCost && props.stepConf.option.amountInclTaxes) {
    span = 6;
  }
  if (props.stepConf.option.shippingCost && !props.stepConf.option.amountInclTaxes) {
    span = 8;
  }
  if (!props.stepConf.option.shippingCost && !props.stepConf.option.amountInclTaxes) {
    span = 10;
  }


  return (
    <div key={props.keyname} style={{ marginBottom: 15, fontSize: '0.95em', border: 'solid', borderColor: '#e8e8e8', borderWidth: '1px' }}>
      <Row style={{ fontWeight: "bold", background: "#ECECEC" }}>
        <Col span={6} style={{ paddingLeft: 5, paddingTop: 6, paddingBottom: 6 }}>Plateforme</Col>
        <Col span={span} style={{ paddingLeft: 5, paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>Numéro commande</Col>
        <Col span={4} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6 }}>
          {!props.stepConf.option.outStandingAmount &&
            <>En cours</>
          }
        </Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6 }}>Qté</Col>
        <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>Total € HT</Col>
        {props.stepConf.option.shippingCost &&
          < Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>Frais de port</Col>
        }
        {props.stepConf.option.amountInclTaxes &&
          <>
            <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6 }}>TVA</Col>
            <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>Total € TTC</Col>
          </>
        }
      </Row>
      {props.wishlists.map((wishlist: IWishlist) =>
        <Row key={wishlist.id}>
          <Col span={6} style={{ paddingLeft: 5, paddingTop: 6 }}>{wishlist.name}</Col>
          <Col span={span} style={{ paddingLeft: 5, paddingTop: 6, paddingRight: 5 }}>
            {props.onChangeReference !== undefined &&
              <Form.Item style={{ padding: 0, margin: 0 }} name={'reference' + wishlist.id}
                rules={[
                  {
                    validator(rule: any, value: string) {
                      if ((!value || value.trim().length === 0) && wishlist.articleCountSelected > 0) {
                        return Promise.reject("La référence de commande est obligatoire.");
                      }
                      if (value && value.indexOf('%') > -1) {
                        return Promise.reject("Le carractère % est interdit dans la référence.");
                      }
                      return Promise.resolve();
                    },
                    max: 10,
                    len: 10
                  }
                ]} >
                <Input placeholder="Référence" onBlur={(value) => props.onChangeReference(value, wishlist)} maxLength={10} style={{ width: 200 }} />
              </Form.Item>
            }
            {props.onChangeReference === undefined &&
              <>{wishlist.reference}</>
            }

          </Col>
          <Col span={4} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6 }}>
            {!props.stepConf.option.outStandingAmount &&
              <>{flybyUtils.numberFormat(wishlist.platform.outstandingAmount, 2)} / {flybyUtils.numberFormat(wishlist.platform.outstandingAmountLimit, 2)}</>
            }
            {props.stepConf.option.outStandingAmount && wishlist.platform.outstandingAmountLimit && wishlist.platform.outstandingAmount && (wishlist.platform.outstandingAmountLimit - wishlist.platform.outstandingAmount) <= 0 &&
              <Text type="danger" ><WarningOutlined style={{ fontSize: 21, marginRight: 5 }} />Limite de crédit dépassée</Text>
            }
          </Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{flybyUtils.numberFormat(wishlist.totalQuantitySelected, 0)}</Col>
          <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingRight: 5 }}>{flybyUtils.numberFormat(wishlist.totalAmountExclTaxesSelected, 2)}</Col>
          {props.stepConf.option.shippingCost &&
            <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingRight: 5 }}>{flybyUtils.numberFormat(wishlist.shippingCostsExclTaxesSelected, 2)}</Col>
          }
          {props.stepConf.option.amountInclTaxes &&
            <>
              <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6 }}>{flybyUtils.numberFormat(wishlist.totalPaymentTaxesAmountSelected, 2)}</Col>
              <Col span={2} style={{ paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingRight: 5 }}>{flybyUtils.numberFormat(wishlist.totalPaymentAmountInclTaxesSelected, 2)}</Col>
            </>
          }

          <Col span={24} style={{ paddingLeft: 5 }}>
            <FrancoView wishlist={wishlist} />
          </Col>

        </Row>
      )}
      <Row style={{ fontWeight: "bold" }}>
        <Col span={6} style={{ fontWeight: "bold", paddingLeft: 5, paddingTop: 6, paddingBottom: 6 }}>Total</Col>
        <Col span={span} style={{ fontWeight: "bold", paddingLeft: 5, paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}></Col>
        <Col span={4} style={{ fontWeight: "bold", paddingLeft: 5, paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}></Col>
        <Col span={2} style={{ fontWeight: "bold", paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalQuantitySelected, 2)}</Col>
        <Col span={2} style={{ fontWeight: "bold", paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalAmountExclTaxesSelected, 2)}</Col>
        {props.stepConf.option.shippingCost &&
          <Col span={2} style={{ fontWeight: "bold", paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalShippingCostsExclTaxesSelected, 2)}</Col>
        }
        {props.stepConf.option.amountInclTaxes &&
          <>
            <Col span={2} style={{ fontWeight: "bold", paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalPaymentTaxesAmountSelected, 2)}</Col>
            <Col span={2} style={{ fontWeight: "bold", paddingLeft: 5, textAlign: "right", paddingTop: 6, paddingBottom: 6, paddingRight: 5 }}>{flybyUtils.numberFormat(props.wishlistTotal.totalPaymentAmountInclTaxesSelected, 2)}</Col>
          </>
        }
      </Row>
    </div>
  );
});