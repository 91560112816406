import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import ParameterService from './ParameterService';

interface parameter {
  name: string,
  value: string,
}

export interface DefaultParameter {
  GDPR_TITLE: string,
  GDPR_TEXT: string,
  APP_LOGO: string,
  APP_LOGO_URL: string,
  APP_HEADER: string,
  APP_FOOTER: string,
  APP_CONTACTS: string,
  SHOPPING_CART_CONFIGURATION: string,
  CATALOG_ETAI: boolean,
  CATALOG_TECDOC: boolean,
  CATALOG_F1: boolean,
  TECDOC_SHOW_INFOS: boolean,
  TECDOC_ACCOUNT_UID: string,
  OPEN_ORDER: boolean,
  ESTIMATED_SHIPPING_COSTS: string,
  FORMULE1_XML_SEND_BILLING_ADDRESS: boolean,
  FORMULE1_XML_SEND_DELIVERY_ADDRESS: boolean,
  PAYEMENT_ORDER: string,
  PHONE: string,
  CATALOG_SEARCH_MODE: string,
  XAT2V2_MULTI_SITE: string,
  SHOW_COUNTDOWN_CUTOFF: boolean,
  ADDEDIT_ADDRESS: boolean,
  XAT2_COMMENT_REQUIRED: string,
  XAT2_COMMENT_REQUIRED_MESSAGE: string,
  CUSTOM_DOCUMENTS_HISTORY: string,
  MIN_PAGINATION_NUMBER: number,
  SHOPPING_CART_TERMS_OF_SALES: string,
  SHOW_PLATFORM_LIST: boolean,
  PAYMENT_ORDER: boolean,
  DISABLE_MARKET: boolean,
  TITLE: string,
  FAVICO: string,
  SHOW_BASKET: boolean,
  DOCUMENT: boolean,
  SHOW_MENU_LOGO: boolean,
  REQUEST_ITEM_AVAILABILITY_MAIL_TO: string,
  SHOW_MANUFACTURER: boolean,
  ENABLE_IMPORT_ORDER: boolean,
  MANUFACTURERS_FILTER: string,
}

const params: DefaultParameter = {
  GDPR_TITLE: '',
  GDPR_TEXT: '',
  APP_LOGO: '',
  APP_LOGO_URL: '',
  APP_HEADER: '',
  APP_FOOTER: 'INC',
  APP_CONTACTS: '',
  SHOPPING_CART_CONFIGURATION: '',
  CATALOG_ETAI: true,
  CATALOG_TECDOC: false,
  CATALOG_F1: false,
  TECDOC_SHOW_INFOS: true,
  TECDOC_ACCOUNT_UID: '',
  OPEN_ORDER: true,
  ESTIMATED_SHIPPING_COSTS: '',
  FORMULE1_XML_SEND_BILLING_ADDRESS: false,
  FORMULE1_XML_SEND_DELIVERY_ADDRESS: false,
  PAYEMENT_ORDER: '',
  PHONE: '',
  CATALOG_SEARCH_MODE: '',
  XAT2V2_MULTI_SITE: '',
  SHOW_COUNTDOWN_CUTOFF: false,
  ADDEDIT_ADDRESS: false,
  XAT2_COMMENT_REQUIRED: '',
  XAT2_COMMENT_REQUIRED_MESSAGE: '',
  CUSTOM_DOCUMENTS_HISTORY: '',
  MIN_PAGINATION_NUMBER: 15,
  SHOPPING_CART_TERMS_OF_SALES: '',
  SHOW_PLATFORM_LIST: true,
  PAYMENT_ORDER: false,
  DISABLE_MARKET: false,
  TITLE: 'Flyby',
  FAVICO: '/public/favico.ico',
  SHOW_BASKET: false,
  DOCUMENT: false,
  SHOW_MENU_LOGO: true,
  REQUEST_ITEM_AVAILABILITY_MAIL_TO: '',
  SHOW_MANUFACTURER: true,
  ENABLE_IMPORT_ORDER: true,
  MANUFACTURERS_FILTER: '',
};

export const MarketParameterContext = createContext<DefaultParameter>(
  (null as unknown) as DefaultParameter
);

export const useMarketParameter = (): DefaultParameter =>
  useContext(MarketParameterContext);

const MarketParameterProvider = (props: {
  children: React.ReactNode;
}): React.ReactElement => {

  const parameterService: ParameterService = new ParameterService();

  const { children } = props;
  const [parameters, setParameters] = useState<DefaultParameter>(params);

  const getParameters = useCallback(async (): Promise<void> => {

    parameterService.findInitParameters().then((data: parameter[]) => {

      let newParams = params;
      if (data) {
        data.forEach(p => {
          switch (p.name) {

            case "GDPR_TITLE":
            case "GDPR_TEXT":
            case "APP_LOGO":
            case "APP_LOGO_URL":
            case "APP_HEADER":
            case "APP_FOOTER":
            case "APP_CONTACTS":
            case "SHOPPING_CART_CONFIGURATION":
            case "TECDOC_ACCOUNT_UID":
            case "ESTIMATED_SHIPPING_COSTS":
            case "PAYEMENT_ORDER":
            case "PHONE":
            case "CATALOG_SEARCH_MODE":
            case "XAT2V2_MULTI_SITE":
            case "XAT2_COMMENT_REQUIRED":
            case "XAT2_COMMENT_REQUIRED_MESSAGE":
            case "CUSTOM_DOCUMENTS_HISTORY":
            case "SHOPPING_CART_TERMS_OF_SALES":
            case "TITLE":
            case "FAVICO":
            case "REQUEST_ITEM_AVAILABILITY_MAIL_TO":
            case "MANUFACTURERS_FILTER":
              newParams[p.name] = p.value;
              break;

            case "SHOW_COUNTDOWN_CUTOFF":
            case "CATALOG_ETAI":
            case "CATALOG_TECDOC":
            case "CATALOG_F1":
            case "TECDOC_SHOW_INFOS":
            case "OPEN_ORDER":
            case "FORMULE1_XML_SEND_BILLING_ADDRESS":
            case "FORMULE1_XML_SEND_DELIVERY_ADDRESS":
            case "ADDEDIT_ADDRESS":
            case "SHOW_PLATFORM_LIST":
            case "PAYMENT_ORDER":
            case "DISABLE_MARKET":
            case "SHOW_BASKET":
            case "DOCUMENT":
            case "SHOW_MENU_LOGO":
            case "SHOW_MANUFACTURER":
            case "ENABLE_IMPORT_ORDER":
              newParams[p.name] = JSON.parse(p.value);
              break;

            case "MIN_PAGINATION_NUMBER":
              newParams[p.name] = JSON.parse(p.value);
              break;
          }
        })
      }

      setParameters(newParams);
    });

  }, [setParameters, parameterService]);

  useEffect(() => {
    getParameters();
  }, [getParameters]);

  return (
    <MarketParameterContext.Provider value={parameters}>
      {children}
    </MarketParameterContext.Provider>
  );
};

export default MarketParameterProvider;

export const withMarketParameter = (Component: any) => (props: any) => (
  <MarketParameterContext.Consumer>
    {store => <Component {...props} {...store} />}
  </MarketParameterContext.Consumer>
);
