
import React from 'react';
import '../../App.css';
import { LockOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Row, Col, Input, Divider, notification, Form } from 'antd';

import ISession from './ISession';
import LoginService from '../login/LoginService';
import FlybyLayoutForm from '../../Utils/FlybyLayoutForm';


class MyAccountForm extends React.Component<
  {
    data: ISession
  },
  {
  }
>{

  private loginService: LoginService = new LoginService();



  render() {

    return (
      <Form {...FlybyLayoutForm} onFinish={this.onSubmit}
        initialValues={{
          customer: this.props.data.customers[0].name,
          firstName: this.props.data.user.firstName,
          lastName: this.props.data.user.lastName,
          email: this.props.data.user.email,
          currentPassword: '',
          password: '',
          passwordConfirm: ''
        }}>
        <Row>
          <Col span={7} >
            <Divider orientation="left">Vos informations</Divider>
            <Form.Item label="Client" name='customer'>
              <Input placeholder="Client" readOnly />
            </Form.Item>
            <Form.Item label="Nom" name='firstName'>
              <Input placeholder="Nom" readOnly />
            </Form.Item>
            <Form.Item label="Prénom" name='lastName'>
              <Input placeholder="Prénom" readOnly />
            </Form.Item>
            <Form.Item label="Email" name='email'>
              <Input placeholder="Email" readOnly />
            </Form.Item>
          </Col>
          <Col span={4} >
          </Col>
          <Col span={7} >
            <Divider orientation="left">Modifier votre mot de passe</Divider>
            <Form.Item label="Mot de passe actuel" name='currentPassword' hasFeedback rules={[{ required: true, message: 'Le mot de passe actuel est obligatoire.' }]}>
              <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Mot de passe actuel" />
            </Form.Item>
            <Form.Item label="Nouveau mot de passe" hasFeedback name='password'
              rules={[{ required: true, message: 'Le nouveau mot de passe est obligatoire.' }]}>
              <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Nouveau mot de passe" />
            </Form.Item>
            <Form.Item label="Confirmation mot de passe" hasFeedback name='passwordConfirm'
              rules={[{ required: true, message: 'La confirmation de mot de passe est obligatoire.' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Les mots de passe ne correspondent pas.');
                },
              })
              ]}>
              <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirmation mot de passe" />
            </Form.Item>

            <Button type="primary" htmlType="submit" size="large">Valider</Button>

          </Col>

        </Row>

      </Form>
    );
  }


  private onSubmit = (values: any) => {

    this.loginService.changePassword(values.currentPassword, values.password)
      .then((response: any) => {
        if (response.status === 200) {
          notification.info({ message: 'Mon compte', description: 'Nous avons bien pris en compte votre modification de mot de passe.', duration: 2 });
        }
        else {
          notification.error({ message: 'Mon compte', description: 'Impossible de modifier votre mot de passe' });
        }
      });
  }
}

export default MyAccountForm;     