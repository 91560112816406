
import React from 'react';
import { Skeleton, Typography, List, Button } from 'antd';

import FlybyUtils from '../../Utils/FlybyUtils';
import IData from '../../Utils/IData';
import ArticleService from './ArticleService';
import IArticleDocument from './IArticleDocument';
import { LinkOutlined } from '@ant-design/icons';
import PdfViewer from '../../Utils/PdfViewer';

const { Text } = Typography;

class ArticleDocument extends React.Component<
  {
    articleId: number
  },
  {
    dataLoading: boolean,
    data: IData,
    document: IArticleDocument | undefined,
    pdfFile: boolean,
  }>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private articleService: ArticleService = new ArticleService();


  constructor(props: { articleId: number }) {
    super(props);

    this.state = {
      dataLoading: true,
      data: this.flybyUtils.getEmptyData(),
      document: undefined,
      pdfFile: false
    };
  }

  componentDidMount() {
    this.loadData(this.props.articleId, 20, 0);
  }

  componentWillReceiveProps(newProps: { articleId: number }) {
    if (newProps.articleId !== this.props.articleId) {
      this.loadData(newProps.articleId, 20, 0);
    }
  }

  render() {
    return (

      <Skeleton loading={this.state.dataLoading} active>
        {(this.state.data.content === undefined || this.state.data.content.length === 0) &&
          <Text type="secondary">Nous n'avons pas trouvé de documentation.</Text>
        }

        {this.state.data.content !== undefined && this.state.data.content.length > 0 &&
          <div >

            <List
              grid={{ gutter: 16, column: this.state.data.content.length > 5 ? 3 : 1 }}
              dataSource={this.state.data.content}
              renderItem={(item: IArticleDocument) => (
                <List.Item>
                  <Button type='link' onClick={() => this.onClick(item)}><LinkOutlined /> {item.name}</Button>
                </List.Item>
              )}
            />,
          </div>
        }

        {this.state.document &&
          <PdfViewer
            file={'/api/market/articleDocuments/' + this.state.document.id + '/link'}
            urlType={true}
            visible={this.state.pdfFile}
            onClosePrint={this.onCloseReport}
          />
        }
      </Skeleton>

    );
  }

  private onClick = (document: IArticleDocument): void => {
    if (document.path.toLocaleLowerCase().endsWith('.pdf')) {
      this.setState({
        document: document,
        pdfFile: true
      });
    }
    else {
      window.open(document.path);
    }
  };

  private onCloseReport = (): void => {
    this.setState({
      document: undefined,
      pdfFile: false
    });
  };

  private loadData = (articleId: number, pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoading: true,
    });

    this.articleService.findArticleDocuments(articleId, pageSize, page)
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false,
          });
        }
      });
  }
}

export default ArticleDocument;