import React from 'react';
import { Steps, Spin, notification } from 'antd';

import { BasketContext, withBasket } from '../../Utils/BasketProvider';
import IWishlistArticle from '../home/IWishlistArticles';
import ArticleService from '../home/ArticleService';
import WhishlistService from '../home/WishlistService';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import IWishlist, { SelectState } from '../home/IWishlist';
import Step5 from './Step5';
import Step6 from './Step6';
import IStep from './IStep';
import { ProgressConf } from './ProgressConf';
import ISalesOrder from './ISalesOrder';
import { withRouter } from 'react-router';
import SalesOrderService from './SalesOrderService';
import ISession from '../myaccount/ISession';
import CustomerService from '../mail/CustomerService';
import LoginService from '../login/LoginService';
import IAddress from './IAddress';
import IOrderPlatforms from './IOrderPlatforms';
import ParameterService from '../../Utils/ParameterService';
import ArticleUtils from '../home/ArticleUtils';

const Step = Steps.Step;


class ShoppingCart extends React.Component<
  {
    history: any,
    addArticle: any,
    updateArticle: any,
    deleteArticle: any,
    loadUserWhishList: any,
    basketContent: any,
    refreshAvailability: any,
    refreshWishlistTotal: any,
    refreshWishlist: any,
    setLoading: any,
    updateSalesOrder: any,
    clearSalesOrder: any,
    updateWhishlist: any,
    salesOrder: ISalesOrder
  },
  {
    quantityValueField: number,
    step: number,
    progressConf: IStep[],
    stepIdx: any[],
    openOrder: boolean,
    showCutoff: boolean,
  }
>{

  private salesOrderService: SalesOrderService = new SalesOrderService();
  private wishlistService: WhishlistService = new WhishlistService();
  private articleService: ArticleService = new ArticleService();
  private timer: any = null;

  constructor(props: {
    history: any,
    addArticle: any, updateArticle: any, deleteArticle: any, loadUserWhishList: any, basketContent: any, refreshAvailability: any,
    refreshWishlistTotal: any, refreshWishlist: any, setLoading: any, updateSalesOrder: any, clearSalesOrder: any, updateWhishlist: any,
    salesOrder: ISalesOrder,
  }) {
    super(props);

    this.state = {
      quantityValueField: 1,
      step: 1,
      progressConf: [],
      stepIdx: [],
      openOrder: true,
      showCutoff: false,
    };
  }

  private customerService: CustomerService = new CustomerService();
  private loginService: LoginService = new LoginService();
  private parameterService: ParameterService = new ParameterService();
  private articleUtils: ArticleUtils = new ArticleUtils();

  componentDidMount() {

    this.parameterService.findValue("SHOW_COUNTDOWN_CUTOFF")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            showCutoff: JSON.parse(value)
          });
        }
        else {
          this.setState({
            showCutoff: false
          });
        }
      });

    this.parameterService.findValue("OPEN_ORDER")
      .then((value: string) => {
        if (value !== undefined) {
          this.setState({
            openOrder: JSON.parse(value)
          });
        }
        else {
          this.setState({
            openOrder: false
          });
        }
      });

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let step: number = urlParams.get('step') === null ? 1 : Number(urlParams.get('step'));
    let responseType: string | null = urlParams.get('responseType');
    let order: string | null = urlParams.get('order');
    let deliveryId: string | null = urlParams.get('deliveryId');
    let billingId: string | null = urlParams.get('billingId');

    ProgressConf().then((conf: IStep[]) => {
      this.onPaymentCheck(responseType, order, conf, step, deliveryId, billingId);
    });
  }

  render() {

    return (
      <div>
        {!this.state.openOrder &&
          <div style={{ textAlign: "center", marginTop: 100, marginBottom: 100 }}>
            En raison d'un problème technique nous ne pouvons actuellement plus recevoir de commande via le HUB. <br />
            Nous mettons tout en oeuvre pour résoudre cet incident dans les meilleurs délais. <br />
            Merci de votre compréhension.
          </div>
        }

        {this.state.openOrder &&
          <div>
            <Steps current={this.state.stepIdx[this.state.step]} >
              {this.state.progressConf.map((step: IStep) => step.enable &&
                <Step title={step.title} key={this.state.stepIdx[step.key]} />
              )}
            </Steps>
            <br />
            <BasketContext.Consumer>
              {(values) =>
                <Spin spinning={values.loading || this.state.progressConf.length === 0} >

                  {this.state.step === 1 &&
                    <Step1 stepConf={this.state.progressConf.find((step: IStep) => step.key === 1)} values={values} onRefreshAvailability={this.onRefreshAvailability} onChangeQuantity={this.onChangeQuantity}
                      onDeleteWishlist={this.onDeleteWishlist} deleteArticle={this.deleteArticle} onSelectItem={this.onSelectItem}
                      onRefreshWishlist={this.onRefreshWishlist} onNextStep={this.onNextStep} onUpdateWhishlist={this.onUpdateWhishlist}
                      addArticle={this.props.addArticle} updateSalesOrder={this.props.updateSalesOrder} onSelectAllItems={this.onSelectAllItems}
                      showCutoff={this.state.showCutoff} />
                  }

                  {this.state.step === 2 &&
                    <Step2 stepConf={this.state.progressConf.find((step: IStep) => step.key === 2)} values={values} onNextStep={this.onNextStep} />
                  }

                  {this.state.step === 3 &&
                    <Step3 stepConf={this.state.progressConf.find((step: IStep) => step.key === 3)} values={values} onNextStep={this.onNextStep} updateSalesOrder={this.props.updateSalesOrder} />
                  }

                  {this.state.step === 4 &&
                    <Step4 stepConf={this.state.progressConf.find((step: IStep) => step.key === 4)} values={values} onNextStep={this.onNextStep} />
                  }

                  {this.state.step === 5 &&
                    <Step5 stepConf={this.state.progressConf.find((step: IStep) => step.key === 5)} values={values} onNextStep={this.onNextStep} refreshWishlist={this.props.refreshWishlist}
                      refreshWishlistTotal={this.props.refreshWishlistTotal} clearSalesOrder={this.props.clearSalesOrder} updateSalesOrder={this.props.updateSalesOrder} />
                  }

                  {this.state.step === 6 &&
                    <Step6 stepConf={this.state.progressConf.find((step: IStep) => step.key === 6)} values={values} onNextStep={this.onNextStep} refreshWishlist={this.props.refreshWishlist}
                      refreshWishlistTotal={this.props.refreshWishlistTotal} clearSalesOrder={this.props.clearSalesOrder} />
                  }

                </Spin>
              }
            </BasketContext.Consumer>

          </div>
        }
      </div>
    );
  }

  private onNextStep = (step: number) => {
    this.setState({ step: step });
  }

  private onSelectItem = (checkedValue: boolean, item: IWishlistArticle) => {
    item.selected = checkedValue;
    this.props.setLoading(true);
    this.articleService.updateWishlistArticle(item.id, item)
      .then((data: any) => {
        this.props.refreshWishlist();
        this.props.refreshWishlistTotal();
      });
  }

  private onSelectAllItems = (checkedValue: boolean, wishlist: IWishlist) => {
    let wishlistUpdate = {
      ...wishlist,
      selectState: checkedValue ? SelectState.ALL : SelectState.NONE
    }

    this.props.setLoading(true);
    this.wishlistService.updateWhishlist(wishlistUpdate)
      .then((data: any) => {
        this.props.refreshWishlist();
        this.props.refreshWishlistTotal();
      });
  }


  private onRefreshAvailability = (wishlistArticles: IWishlistArticle, platformId: number) => {

    wishlistArticles.article.loading = true;
    this.setState({ quantityValueField: 0 });

    this.articleService.wishlistArticle(wishlistArticles.id, true)
      .then((values: any) => {

        wishlistArticles.article.loading = false;
        if (values !== undefined) {
          wishlistArticles.lastAvailabilityUpdateDate = values.lastAvailabilityUpdateDate;
          wishlistArticles.state = values.state;
          wishlistArticles.deliveredAt = values.deliveredAt;
        }
        this.props.refreshAvailability();
      });

  }

  private onChangeQuantity = (value: any, item: IWishlistArticle, wishlistId: number) => {
    if (this.articleUtils.checkQuantityVsSalePackaging(value, item.salePackaging)) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.setState({ quantityValueField: value });
        this.props.updateArticle(item, wishlistId.toString(), this.state.quantityValueField);
      }, 500);
    }

  }

  private onDeleteWishlist = (wishlistId: number) => {
    this.wishlistService.deleteWishlist(wishlistId)
      .then((response: any) => {
        if (response.status !== 204) {
          notification.error({ message: 'Panier', description: 'Erreur lors de la suppression du panier.' });
        }
        this.props.loadUserWhishList();
      });
  }

  private onRefreshWishlist = () => {
    this.props.refreshWishlist();
  }

  private onUpdateWhishlist = (wishlist: IWishlist) => {
    this.props.updateWhishlist(wishlist);
  }

  private deleteArticle = (wishlistArticleId: number) => {
    this.props.deleteArticle(wishlistArticleId);
  }

  private onPaymentCheck = (responseType: string | null, order: string | null, conf: IStep[], step: number, deliveryId: string | null, billingId: string | null) => {

    if (order && responseType && responseType === 'ACCEPTED') {

      this.salesOrderService.getOrder(Number(order)).then((salesOrder: ISalesOrder) => {

        let stepConf: IStep | undefined = conf.find((o: IStep) => o.key === step);
        step = stepConf?.nextAction.nextStep !== undefined ? stepConf?.nextAction.nextStep : 1;

        this.props.updateSalesOrder(salesOrder);
        this.setNextStep(conf, step);
      });
    }
    else if (responseType && responseType !== 'ACCEPTED') {
      notification.error({ message: 'Paiement de commande', description: 'Impossible de valider votre paiement, veuillez réessayer ultérieurement ou changer de mode de paiement.' });
      this.reloadOrder(deliveryId, billingId, conf, 1);
    }
    else {
      this.reloadOrder(deliveryId, billingId, conf, 1);
    }
  }

  private reloadOrder = (deliveryId: string | null, billingId: string | null, conf: IStep[], step: number) => {

    let orderPlatforms: IOrderPlatforms[] = [];
    this.props.basketContent.forEach((wishlist: IWishlist) => {

      if (wishlist.wishlistArticles.findIndex(((item: any) => item.selected === true)) > -1) {
        orderPlatforms.push({
          wishlist: {
            id: wishlist.id
          },
          reference: wishlist.reference,
          reference2: wishlist.reference2,
        });
      }
    });

    let salesOrder: ISalesOrder = {
      ...this.props.salesOrder,
      orderPlatforms: orderPlatforms
    }

    this.loginService.session()
      .then((session: ISession) => {
        if (session !== undefined) {

          if (deliveryId !== null && Number(deliveryId) !== -1) {
            this.customerService.getAddress(Number(deliveryId))
              .then((data: IAddress) => {
                if (data !== undefined) {

                  salesOrder.deliveryId = data.id;
                  salesOrder.deliveryCompanyName = session.customers[0].name;
                  salesOrder.deliveryAddress1 = data.address1;
                  salesOrder.deliveryAddress2 = data.address2;
                  salesOrder.deliveryZipCode = data.zipCode;
                  salesOrder.deliveryCity = data.city;

                }
              });
          }
          else {
            salesOrder.deliveryId = -1;
            salesOrder.deliveryCompanyName = session.customers[0].name;
            salesOrder.deliveryAddress1 = session.customers[0].deliveryAddress1;
            salesOrder.deliveryAddress2 = session.customers[0].deliveryAddress2;
            salesOrder.deliveryZipCode = session.customers[0].deliveryZipCode;
            salesOrder.deliveryCity = session.customers[0].deliveryCity;
          }

          salesOrder.billingId = -1;
          salesOrder.billingCompanyName = session.customers[0].name;
          salesOrder.billingAddress1 = session.customers[0].billingAddress1;
          salesOrder.billingAddress2 = session.customers[0].billingAddress2;
          salesOrder.billingZipCode = session.customers[0].billingZipCode;
          salesOrder.billingCity = session.customers[0].billingCity;

          this.props.updateSalesOrder(salesOrder);
          this.onRefreshWishlist();
          this.setNextStep(conf, step);
        }

      });

  }

  private setNextStep = (conf: IStep[], step: number): void => {
    let idx: number = 0;
    let stepIdx: any[] = [];
    conf.forEach((step: IStep, index: number) => {
      if (step.enable) {
        stepIdx[step.key] = idx;
        idx++;
      }
    });

    this.setState({
      progressConf: conf,
      stepIdx: stepIdx,
      step: step,
    });

    this.props.history.push('/shopping-cart');
  }
}

export default withRouter(withBasket(ShoppingCart));        