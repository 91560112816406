import React from 'react';
import '../../App.css';
import { LockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Card } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';

import LoginService from './LoginService';
import FlybyLayoutForm from '../../Utils/FlybyLayoutForm';

class RecoveryIdentifier extends React.Component<
    {
        form: any,
    } & FormComponentProps,
    {
        loading: boolean,
        confirmDirty: boolean
        resetPasswordOk: boolean
    }
    >{

    private loginService = new LoginService();

    constructor(props: { form: any } & FormComponentProps) {
        super(props);

        this.state = {
            loading: false,
            confirmDirty: false,
            resetPasswordOk: false,
        };
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <div className="password-container">
                    <Card title="Changement de mot de passe" style={{ width: 400 }}>

                        {!this.state.resetPasswordOk &&
                            <div>
                                <p>Vous pouvez modifier votre mot de passe pour des raisons de sécurité, ou le réinitialiser si vous l'avez oublié.</p>
                                <Form {...FlybyLayoutForm} onSubmit={this.onValidResetPassword} className="login-form">
                                    <Form.Item hasFeedback>
                                        {getFieldDecorator('password', {
                                            rules: [{ required: true, message: 'Le mot de passe est obligatoire.' }, { validator: this.validateToNextPassword }],
                                            initialValue: ""
                                        })(
                                            <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Mot de passe" />
                                        )}
                                    </Form.Item>
                                    <Form.Item hasFeedback>
                                        {getFieldDecorator('passwordConfirm', {
                                            rules: [{ required: true, message: 'La confirmation de mot de passe est obligatoire.' }, { validator: this.compareToFirstPassword }],
                                            initialValue: ""
                                        })(
                                            <Input.Password prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Confirmation mot de passe" onBlur={this.handleConfirmBlur} />
                                        )}
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading}>Valider</Button>
                                        <Button type="default" className="login-form-button" onClick={this.onCancelResetPassword}>Retour au site</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        }

                        {this.state.resetPasswordOk &&
                            <div>
                                <p>Nous avons bien pris en compte votre modification de mot de passe.</p>
                                <p>Vous pouvez dès à présent vous connecter sur le site.</p>
                                <Button type="default" className="login-form-button" onClick={this.onCancelResetPassword}>Retour au site</Button>
                            </div>
                        }
                    </Card>
                </div>

            </div>
        );
    }

    private handleConfirmBlur = (e: any) => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    private compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Les mots de passe ne correspondent pas.');
        } else {
            callback();
        }
    };

    private validateToNextPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    private onValidResetPassword = (e: any) => {

        e.preventDefault();
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {

                this.setState({
                    loading: true,
                    resetPasswordOk: false
                });

                let params = new URL(window.location.href).searchParams;
                let resetPasswordToken = params.get('resetPasswordToken');
                if (resetPasswordToken != null) {
                    this.loginService.resetPassword(resetPasswordToken, values.password)
                        .then((result: any) => {
                            this.setState({
                                loading: false,
                                resetPasswordOk: true
                            });
                        });
                }
            }
        });

    }

    private onCancelResetPassword = () => {
        window.location.href = window.location.protocol + '//' + window.location.host;
    }
}

const thisForm = Form.create<{ form: any }>({ name: 'recovery_identifier_form' })(RecoveryIdentifier);

export default thisForm;        