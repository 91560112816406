import React from 'react';
import '../../App.css';
import { Button, notification, Spin, Table, Tag, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import FlybyUtils from '../../Utils/FlybyUtils';
import SalesOrderService from './SalesOrderService';
import IData from '../../Utils/IData';
import IConfirmedSalesOrder from './IConfirmedSalesOrder';
import ConfirmedSalesOrderView from './ConfirmedSalesOrderView';
import { FilePdfOutlined } from '@ant-design/icons';
import PdfViewer from '../../Utils/PdfViewer';
import { MarketParameterContext } from '../../Utils/MarketParameterContext';

const { Title } = Typography;

class SalesOrderHistory extends React.Component<{},
  {
    data: IData,
    dataLoading: boolean,
    pdfLoading: boolean;
    pdfFile: Blob | undefined,
  }
>{

  private flybyUtils: FlybyUtils = new FlybyUtils();
  private salesOrderService: SalesOrderService = new SalesOrderService();

  private columns: ColumnProps<IConfirmedSalesOrder>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend'],
      width: 50,
    },
    {
      title: 'Date commande',
      dataIndex: 'date',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      render: (text: string, record: any) => this.flybyUtils.formatJsonDate(record.date)
    },
    {
      title: 'Statut',
      dataIndex: 'state',
      sortDirections: ['descend', 'ascend'],
      width: 200,
      render: (text: string, record: any) =>
        <Tag color={this.flybyUtils.getAvailabilityColor(record.state, record.locallyAvailable, undefined, 0, false)}>{this.flybyUtils.getStateOrderText(record.state)}</Tag>
    },
    {
      title: 'Quantité',
      dataIndex: 'totalQuantity',
      sortDirections: ['descend', 'ascend'],
      width: 80,
      align: "right"
    },
    {
      title: 'Total HT',
      dataIndex: 'totalAmountExclTaxes',
      sortDirections: ['descend', 'ascend'],
      width: 80,
      align: "right"
    },
    {
      title: '',
      width: 50,
      render: (text: string, record: any) =>
        <div style={{ textAlign: "right" }}>
          <Button onClick={() => this.onOrderReport(record)} loading={record.pdfLoading}><FilePdfOutlined />PDF</Button>
        </div>,
    }
  ];

  constructor(props: {}) {
    super(props);

    this.state = {
      data: this.flybyUtils.getEmptyData(),
      dataLoading: true,
      pdfLoading: false,
      pdfFile: undefined,
    };
  }

  componentDidMount() {
    this.searchSalesOrder('', 20, 0, 'date,desc');
  }

  render() {

    return (
      <MarketParameterContext.Consumer>
        {(parameters) =>
          <div>
            <Title level={4} style={{ marginBottom: 25 }}>Mes commandes / Historiques des commandes</Title>

            <Spin spinning={this.state.pdfLoading}>
              <Table rowKey="id" loading={this.state.dataLoading} columns={this.columns} dataSource={this.state.data.content}
                onChange={this.onChange} pagination={{ current: (this.state.data.number + 1), pageSize: this.state.data.size, total: this.state.data.totalElements }}

                expandedRowRender={(record: IConfirmedSalesOrder, index: number, indent: number, expanded: boolean) =>
                  <div>
                    {expanded &&
                      <ConfirmedSalesOrderView confirmOrder={record} parameters={parameters} />
                    }
                  </div>
                }

                style={{ border: '1px solid #e8e8e8', borderWidth: '1px 1px 0px 1px' }} />
            </Spin>
            {this.state.pdfFile && (
              <PdfViewer
                file={this.state.pdfFile}
                visible={!!this.state.pdfFile}
                onClosePrint={this.onCloseReport}
              />)}
          </div>
        }
      </MarketParameterContext.Consumer>
    );
  }

  private onChange = (pagination: any, filters: Record<never, string[]>, sorter: any, extra: any) => {

    let sort = this.flybyUtils.getSort(sorter.field, sorter.order);
    pagination = this.flybyUtils.getCurrentPage(pagination);

    this.searchSalesOrder('', pagination.pageSize, pagination.current, sort);
  }

  private searchSalesOrder = (query: string, pageSize: number | undefined, current: number | undefined, sort: string) => {
    this.setState({
      dataLoading: true,
    });

    this.salesOrderService.search(query, pageSize, current, sort)
      .then((data: any) => {
        if (data !== undefined) {

          this.setState({
            data: data,
            dataLoading: false
          });
        }
      });
  }

  private onCloseReport = () => {
    this.setState({
      pdfFile: undefined,
    });
  }

  private onOrderReport = (salesOrder: IConfirmedSalesOrder) => {

    this.setState({
      pdfLoading: true,
    });

    this.salesOrderService.report(salesOrder.id)
      .then(data => {
        this.setState({
          pdfLoading: false,
          pdfFile: data
        });
      })
      .catch((err: any) => {
        notification.error({ message: 'Commande', description: 'Impossible d\'afficher le document.' });

        this.setState({
          pdfLoading: false,
        });
      });
  }
}

export default SalesOrderHistory;        