import React from 'react';
import '../../App.css';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Select, InputNumber, Button, Modal, Input } from 'antd';

import { BasketContext, withBasket } from '../../Utils/BasketProvider';
import IArticle from '../home/IArticle';
import SuggestedQuantityForm from '../home/SuggestedQuantityForm';
import IAvailability from '../home/IAvailability';
import IArticlePlatforms from '../home/IArticlePlatforms';

const Option = Select.Option;

class ArticleWishlistForm extends React.Component<
  {
    addArticle: any,
    item: IArticle,
    onSelectPlatform: any,
    onChangeQuantity: any
  },
  {
    platformsList: any,
    defaultPlatform: number | undefined,
    quantityValueField: number,
    salePackaging: number,
    showSuggestedQuantityForm: boolean,
  }>{

  private timer: any = null;
  private attachedPlatformId: number = Number(sessionStorage.getItem("attachedPlatformId"));
  private showPlatformList: boolean = sessionStorage.getItem("showPlatformList") === 'false' ? false : true;

  constructor(props: { addArticle: any, item: IArticle, onSelectPlatform: any, onChangeQuantity: any }) {
    super(props);

    this.state = {
      platformsList: [],
      defaultPlatform: undefined,
      quantityValueField: 1,
      salePackaging: 1,
      showSuggestedQuantityForm: false,
    };
  }

  componentDidMount() {
    this.loadUserPlatforms(this.props.item.articlePlatforms);
  }

  render() {

    return (
      <BasketContext.Consumer>
        {(basket) =>
          <>
            {basket.userRole !== 'ROLE_GUEST' &&
              <div style={{ textAlign: "left" }}>

                <Input.Group compact>
                  {this.showPlatformList &&
                    <Select placeholder="Plateforme" value={this.state.defaultPlatform} style={{ width: 180 }}
                      onChange={(value: any) => this.onChangeSelect(value, this.props.item)}>
                      {this.state.platformsList}
                    </Select>
                  }
                  <InputNumber min={this.state.salePackaging} step={this.state.salePackaging} value={this.state.quantityValueField} onChange={(value) => this.onChangeQuantity(Number(value), this.props.item)} />

                  <Button disabled={this.props.item.loading} onClick={() => this.addToBasket(this.props.item)} style={{ paddingTop: 8 }}><ShoppingCartOutlined /></Button>
                </Input.Group>


                <Modal
                  title="Notre suggestion de commande"
                  visible={this.state.showSuggestedQuantityForm}
                  okText={"Valider"}
                  cancelText={"Annuler"}
                  onOk={this.onSuggestedQuantityOk}
                  onCancel={this.onSuggestedQuantityCancel}
                  destroyOnClose={true}
                  bodyStyle={{ height: 300 }}
                  width={800}  >
                  <SuggestedQuantityForm item={this.props.item} />
                </Modal>
              </div>
            }
          </>
        }
      </BasketContext.Consumer>
    );
  }

  private onSuggestedQuantityOk = () => {

    if (this.props.item.availability !== undefined) {
      let quantity = this.props.item.availability.quantityOrdered === undefined ? this.props.item.availability.suggestedQuantity : this.props.item.availability.quantityOrdered;
      this.props.addArticle(this.props.item, quantity, this.props.item.availability.platform.id);
    }

    if (this.props.item.moreAvailabilities !== undefined) {
      this.props.item.moreAvailabilities.forEach((availability: IAvailability) => {
        let quantity = availability.quantityOrdered === undefined ? 0 : availability.quantityOrdered;
        this.props.addArticle(this.props.item, quantity, availability.platform.id);
      });
    }

    this.setState({ showSuggestedQuantityForm: false });
  }

  private onSuggestedQuantityCancel = () => {
    this.setState({ showSuggestedQuantityForm: false });
  }

  private addToBasket = (item: IArticle) => {
    if (item.moreAvailabilitiesShow) {
      this.setState({ showSuggestedQuantityForm: true });
    }
    else {
      this.props.addArticle(item, item.quantity, this.state.defaultPlatform);
    }
  }

  private onChangeQuantity = (value: number, item: IArticle) => {

    if (value !== undefined && value > 0) {
      item.quantity = value;

      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.setState({ quantityValueField: value });
        this.props.onChangeQuantity(this.state.quantityValueField, this.state.defaultPlatform, item);
      }, 500);
    }

  }

  private onChangeSelect = (value: any, item: IArticle) => {

    let articlePlatforms: IArticlePlatforms = this.props.item.articlePlatforms.filter(element => element.id === Number(value))[0];

    this.props.onSelectPlatform(value, "platformId", item);
    this.setState({
      defaultPlatform: value,
      salePackaging: (articlePlatforms === undefined || articlePlatforms.salePackaging === undefined) ? 1 : articlePlatforms.salePackaging,
    });

  }

  private loadUserPlatforms = (platforms: IArticlePlatforms[]) => {


    if (platforms !== undefined) {
      let platformsList: any = [];


      let defaultPlatform: number | undefined = this.attachedPlatformId;
      if (platforms.length === 1 && platforms[0].id !== this.attachedPlatformId) {
        defaultPlatform = platforms[0].id;
      }

      let salePackaging = 1;

      platforms.forEach((platform: IArticlePlatforms) => {
        platformsList.push(<Option value={platform.id} key={this.props.item.id + '-' + platform.id.toString()}>{platform.name}</Option>);
      });


      this.setState({
        platformsList: platformsList,
        defaultPlatform: defaultPlatform,
        salePackaging: platforms[0].salePackaging,
        quantityValueField: salePackaging,
      });

      this.props.item.quantity = salePackaging;
    }
  }
}

export default withBasket(ArticleWishlistForm);        