import React from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Select, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { withBasket, BasketContext } from '../../../Utils/BasketProvider';
import CatalogService from '../CatalogService';
import IVehicleNode from '../model/IVehicleNode';
import IVehicleType from '../model/IVehicleType';
import ICatalogSearch from '../model/ICatalogSearch';

import moment from "moment";
import "moment/locale/fr";

const { Option } = Select;

class CatalogVehicleNodeCardSearch extends React.Component<
  {
    history: any,
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
    clearCatalogSearch: any,
    setTypeSearch: any,
  },
  {
    dataLoading: boolean,
    manufacturers: IVehicleNode[],
    vehiculeModels: IVehicleNode[],
    vehicleTypes: IVehicleNode[],
    datesRange: number[],
  }
>{

  private catalogService: CatalogService = new CatalogService();

  constructor(props: { history: any, catalogSearch: ICatalogSearch, setCatalogSearch: any, clearCatalogSearch: any, setTypeSearch: any }) {
    super(props);

    moment.locale("fr");

    this.state = {
      dataLoading: true,
      manufacturers: [],
      vehiculeModels: [],
      vehicleTypes: [],
      datesRange: [],
    };
  }

  componentDidMount() {

    let initSearch = true;
    if (this.props.catalogSearch.level1Id !== undefined && this.props.catalogSearch.level1Name) {
      initSearch = false;
      this.loadVehicleNode('', 1, undefined, 200, 0, false);
      this.loadVehicleNode(this.props.catalogSearch.level1Name, 2, this.props.catalogSearch.level1Id, 200, 0, false);
    }

    if (this.props.catalogSearch.level3Id !== undefined && this.props.catalogSearch.level2Name && this.props.catalogSearch.level2Id) {
      initSearch = false;
      this.loadVehicleType(this.props.catalogSearch.level2Name, this.props.catalogSearch.level2Id, 200, 0, false);
    }

    if (this.props.catalogSearch.level3Id !== undefined) {
      initSearch = false;
      this.loadDateRange(this.props.catalogSearch.level3Id);
    }


    if (initSearch) {
      this.loadVehicleNode('', 1, undefined, 200, 0, true);
    }
  }

  render() {
    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          <Card bordered={false} title={<div><FontAwesomeIcon icon={faSearchLocation} /> Recherche par modèle</div>} size="small"
            bodyStyle={{ minHeight: 100, marginTop: 10 }} headStyle={{ fontWeight: "bold", background: "#FFFFFF", fontSize: "1.1em", color: "#545454" }}>
            <div>
              <Select style={{ width: 344 }}
                onChange={this.selectedManufacturer}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Marque'
                showSearch={true} optionFilterProp="children"
                value={contextValues.catalogSearch.queryType === 'vehicleNode' ? contextValues.catalogSearch.level1Id : undefined} >
                {this.state.manufacturers.map((item: IVehicleNode) => (
                  <Option key={item.id} value={item.id}>{item.description}</Option>
                ))}
              </Select>
              <Select style={{ width: 344, marginTop: 5 }}
                onChange={this.selectedVehicleModel}
                disabled={this.state.vehiculeModels.length === 0}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Modèle'
                showSearch={true} optionFilterProp="children"
                value={contextValues.catalogSearch.queryType === 'vehicleNode' ? contextValues.catalogSearch.level2Id : undefined} >
                {this.state.vehiculeModels.map((item: IVehicleNode) => (
                  <Option key={item.id} value={item.id}>{item.description}</Option>
                ))}
              </Select>
              <Select style={{ width: 344, marginTop: 5 }}
                onChange={this.selectedVehicleType}
                disabled={this.state.vehicleTypes.length === 0}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Motorisation'
                showSearch={true} optionFilterProp="children"
                value={contextValues.catalogSearch.queryType === 'vehicleNode' ? contextValues.catalogSearch.level3Id : undefined} >
                {this.state.vehicleTypes.map((item: IVehicleNode) => (
                  <Option key={item.id} value={item.id}>{item.description}</Option>
                ))}
              </Select>
              <Select style={{ width: 344, marginTop: 5 }}
                onChange={this.selectedDate}
                disabled={this.state.datesRange.length === 0}
                filterOption={(input: string, option: any) => this.filterOption(input, option)}
                loading={this.state.dataLoading} placeholder='Année'
                showSearch={true} optionFilterProp="children"
                value={contextValues.catalogSearch.queryType === 'vehicleNode' ? contextValues.catalogSearch.modelYear : undefined} >
                {this.state.datesRange.map((date: number) => (
                  <Option key={date} value={date}>{date}</Option>
                ))}
              </Select>
              <div style={{ width: '100%', marginTop: 5 }}>
                <Button type='primary' style={{ float: 'right' }} onClick={() => this.onSearch()}>Rechercher</Button>
              </div>
            </div>
          </Card>
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = () => {

    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined
    }
    this.props.setCatalogSearch(catalogSearch);


    this.props.setTypeSearch(3);
    this.props.history.push('/catalog');
  }

  private filterOption = (input: string, option: any): boolean => {

    if (Array.isArray(option.options)) {
      return option.options[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    else {
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }

  }

  private selectedManufacturer = (value: number, option: any) => {
    this.loadVehicleNode(option.children, 2, value, 200, 0, true);
  }

  private selectedVehicleModel = (value: number, option: any) => {
    this.loadVehicleType(option.children, value, 200, 0, true);
  }

  private selectedVehicleType = (value: number, option: any) => {

    this.loadDateRange(value);

    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      queryType: 'vehicleNode',
      level3Id: value,
      level3Name: option.children
    }

    this.props.setCatalogSearch(catalogSearch);

  }

  private loadDateRange = (value: number) => {
    this.catalogService.findVehicleTypeById(value)
      .then((vehicleType: IVehicleType) => {
        if (vehicleType !== undefined) {
          let startYear = moment(vehicleType.startDate, "YYYYMMDD").year();
          let endYear = moment(vehicleType.endDate, "YYYYMMDD").year();
          let datesRange: number[] = [];
          for (let i = startYear; i <= endYear; i++) {
            datesRange.push(i);
          }
          this.setState({
            datesRange: datesRange
          });
        }
      });
  }

  private selectedDate = (value: number, option: any) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      modelYear: value,
    }
    this.props.setCatalogSearch(catalogSearch);
  }

  private loadVehicleNode = (description: string, deep: number, parentId: number | undefined, pageSize: number | undefined, page: number | undefined, setCatalogSearch: boolean) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findVehicleNode(undefined, parentId, undefined, false, pageSize, page, 'rank')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined && deep === 1) {

          if (!setCatalogSearch) {
            this.setState({
              manufacturers: result.content,
              dataLoading: false,
            });
          }

          if (setCatalogSearch) {

            this.setState({
              manufacturers: result.content,
              dataLoading: false,
              vehiculeModels: [],
              vehicleTypes: [],
            });

            let catalogSearch: ICatalogSearch = {
              ...this.props.catalogSearch,
              queryType: 'vehicleNode',
              level1Id: undefined,
              level1Name: undefined,
              level2Id: undefined,
              level2Name: undefined,
              level3Id: undefined,
              level3Name: undefined,
              level4Id: undefined,
              level4Name: undefined,
              level5Id: undefined,
              level5Name: undefined,
              modelYear: undefined,
            }
            this.props.setCatalogSearch(catalogSearch);
          }

        }
        else if (result !== undefined && result.content !== undefined && deep === 2) {

          if (!setCatalogSearch) {
            this.setState({
              vehiculeModels: result.content,
              dataLoading: false,
            });
          }

          if (setCatalogSearch) {

            this.setState({
              dataLoading: false,
              vehiculeModels: result.content,
              vehicleTypes: [],
            });

            let catalogSearch: ICatalogSearch = {
              ...this.props.catalogSearch,
              queryType: 'vehicleNode',
              level1Id: parentId,
              level1Name: description,
              level2Id: undefined,
              level2Name: undefined,
              level3Id: undefined,
              level3Name: undefined,
              level4Id: undefined,
              level4Name: undefined,
              level5Id: undefined,
              level5Name: undefined,
              modelYear: undefined,
            }
            this.props.setCatalogSearch(catalogSearch);
          }
        }
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }

  private loadVehicleType = (description: string, vehicleNodeId: number, pageSize: number | undefined, page: number | undefined, setCatalogSearch: boolean) => {

    this.setState({
      dataLoading: true,
    });

    this.catalogService.findVehicleTypeByVehiculeNode(undefined, vehicleNodeId, pageSize, page, 'description')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            dataLoading: false,
            vehicleTypes: result.content,
          });

          if (setCatalogSearch) {
            let catalogSearch: ICatalogSearch = {
              ...this.props.catalogSearch,
              queryType: 'vehicleNode',
              level2Id: vehicleNodeId,
              level2Name: description,
              level3Id: undefined,
              level3Name: undefined,
              level4Id: undefined,
              level4Name: undefined,
              level5Id: undefined,
              level5Name: undefined,
              modelYear: undefined,
            }
            this.props.setCatalogSearch(catalogSearch);
          }

        }
        else {
          this.setState({
            dataLoading: false
          });
        }
      });
  }
}

export default withRouter(withBasket(CatalogVehicleNodeCardSearch));