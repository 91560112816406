import React from 'react';
import { createContext } from "react";

export const GetMenuKey = (): string => {
    let selectedMenuKey: string = '/home';
    let pathname = window.location.pathname;

    if (pathname === "/index.html") {
        pathname = "/home";
    }

    if (pathname !== "/") {
        selectedMenuKey = pathname;
    }

    return selectedMenuKey;
}

export const NavigationContext = createContext({
    selectedMenuKey: GetMenuKey(),
    updateMenuKey: (menu: string) => { }
});

class NavigationProvider extends React.Component {

    state = {
        selectedMenuKey: GetMenuKey(),
        updateMenuKey: (menu: string) => {
            this.updateMenuKey(menu);
        }
    };

    render() {
        return (
            <NavigationContext.Provider value={this.state}>
                {this.props.children}
            </NavigationContext.Provider>
        );
    }

    private updateMenuKey = (menu: string) => {
        this.setState({
            selectedMenuKey: menu,
        });
    }
}

export default NavigationProvider;

export const withAppMenu = (Component: any) => (props: any) => (
    <NavigationContext.Consumer>
        {store => <Component {...props} {...store} />}
    </NavigationContext.Consumer>
);
