import React from 'react';
import '../../App.css';
import { Row, Col, Input, Button, Card, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCarBattery } from '@fortawesome/free-solid-svg-icons';

import { BasketContext } from '../../Utils/BasketProvider';
import TecdocCardSearch from '../tecdoc/component/TecdocCardSearch';
import CatalogVehicleNodeCardSearch from '../catalog/component/CatalogVehicleNodeCardSearch';
import CatalogFamilyCardSearch from '../catalog/component/CatalogFamilyCardSearch';
import ParameterService from '../../Utils/ParameterService';
import CatalogCharacteristicCardSearch from '../catalog/component/CatalogCharacteristicCardSearch';

const Search = Input.Search;


class CardsSearch extends React.Component<
  {
    dataLoading: boolean,
    showTecdoc: boolean,
    showEtai: boolean,
    showCatalogF1: boolean,
    onSearchArticle: any,
    onAdvancedQuery: any,
    onSearchTecdoc: any,
    onSearchEtai: any
  },
  {
    CATALOG_SEARCH_MODE: string | undefined,
  }
  >{

  private parameterService: ParameterService = new ParameterService();

  constructor(props: { dataLoading: boolean, showTecdoc: boolean, showEtai: boolean, showCatalogF1: boolean, 
    onSearchArticle: any, onAdvancedQuery: any,  onSearchTecdoc: any, onSearchEtai: any
  }) {
    super(props);

    this.state = {
      CATALOG_SEARCH_MODE: undefined
    };
  }

  componentDidMount() {
    this.parameterService.findValue("CATALOG_SEARCH_MODE")
      .then((value: string) => {
        this.setState({ CATALOG_SEARCH_MODE: value });
      });
  }

  render() {

    return (
      <Spin size="large" spinning={this.props.dataLoading} >
        <BasketContext.Consumer>
          {(contextValues) =>

            <Row gutter={12} justify="center"
              style={{ marginTop: 15, marginBottom: 15 }}>
              <Col flex={'380px'} style={{ marginTop: 5 }} >
                <Card bordered={false} title={<div><FontAwesomeIcon icon={faCarBattery} /> Recherche de pièces</div>} size="small"
                  bodyStyle={{ minHeight: 100, marginTop: 10 }} headStyle={{ fontWeight: "bold", background: "#FFFFFF", fontSize: "1.1em", color: "#545454" }}>
                  <Search size="large" placeholder="EAN, référence, désignation, fabricant..."
                    onSearch={this.props.onSearchArticle} style={{ width: "100%" }} enterButton defaultValue={contextValues.query} autoFocus={true} />
                </Card>
              </Col>

              {this.props.showTecdoc && contextValues.customProfil !== undefined &&
                <Col flex={'380px'} style={{ marginTop: 5 }}  >
                  <TecdocCardSearch onSearchTecdoc={this.props.onSearchTecdoc} />
                </Col>
              }

              {this.props.showCatalogF1 && this.state.CATALOG_SEARCH_MODE !== undefined
              && (this.state.CATALOG_SEARCH_MODE === 'VEHICLE_NODE' || this.state.CATALOG_SEARCH_MODE === 'VEHICLE_NODE_FAMILY') &&
                <Col flex={'380px'} style={{ marginTop: 5 }}  >
                  <CatalogVehicleNodeCardSearch />
                </Col>
              }

              {this.props.showCatalogF1 && this.state.CATALOG_SEARCH_MODE !== undefined 
                && (this.state.CATALOG_SEARCH_MODE === 'FAMILY' || this.state.CATALOG_SEARCH_MODE === 'VEHICLE_NODE_FAMILY') &&
                <Col flex={'380px'} style={{ marginTop: 5 }}  >
                  <CatalogFamilyCardSearch />
                </Col>
              }

              {this.props.showCatalogF1 && this.state.CATALOG_SEARCH_MODE !== undefined 
                && (this.state.CATALOG_SEARCH_MODE === 'CHARACTERISTIC') &&
                <Col flex={'380px'} style={{ marginTop: 5 }}  >
                  <CatalogCharacteristicCardSearch />
                </Col>
              }

              {this.props.showEtai &&
                <Col flex={'380px'} style={{ marginTop: 5 }}  >
                  <Card bordered={false} title={<div><FontAwesomeIcon icon={faBook} /> Recherche par catalogues</div>} size="small"
                    bodyStyle={{ minHeight: 100, marginTop: 5 }} headStyle={{ fontWeight: "bold", background: "#FFFFFF", fontSize: "1.1em", color: "#545454" }}>
                    <Button type="link" style={{ margin: 0, padding: 0, height: 55 }} onClick={this.props.onSearchEtai} >
                      <img src="logo-etai.png" alt='ETAI' style={{ height: 55 }} />
                    </Button>
                  </Card>
                </Col>
              }

            </Row>
          }
        </BasketContext.Consumer>
      </Spin>
    );
  }
}

export default CardsSearch;     