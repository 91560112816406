import React from 'react';
import { withRouter } from 'react-router-dom';
import { List, Card, Typography } from 'antd';

import { withBasket } from '../../Utils/BasketProvider';
import CatalogService from './CatalogService';
import IVehicleNode from './model/IVehicleNode';
import IFamily from './model/IFamily';
import ICatalogSearch from './model/ICatalogSearch';
import { SubFamilies } from './component/CatalogUtils';
import { ColumnCount } from 'antd/lib/list';
import ParameterService from '../../Utils/ParameterService';

const { Title } = Typography;

const ChapterTitle = ((props: { title: string, marginTop: number }) => {
  return (
    <div style={{ textAlign: 'center', marginBottom: 25, marginTop: props.marginTop, paddingTop: 5 }}>
      <Title level={4}>{props.title}</Title>
    </div>
  )
});

class CatalogHome extends React.Component<
  {
    history: any,
    catalogSearch: ICatalogSearch,
    setCatalogSearch: any,
    setTypeSearch: any
  },
  {
    dataLoadingVehicleNodes: boolean,
    dataLoadingFamilies: boolean,
    vehicleNodes: IVehicleNode[],
    families: IFamily[],
    queryType: string,
  }>{

  private catalogService: CatalogService = new CatalogService();
  private parameterService: ParameterService = new ParameterService();

  constructor(props: { history: any, catalogSearch: ICatalogSearch, setCatalogSearch: any, setTypeSearch: any }) {
    super(props);

    this.state = {
      dataLoadingVehicleNodes: true,
      dataLoadingFamilies: true,
      vehicleNodes: [],
      families: [],
      queryType: 'vehicleNode'
    };
  }

  componentDidMount() {

    this.parameterService.findValue("CATALOG_SEARCH_MODE")
      .then((value: string) => {
        if (value !== undefined && value === 'CHARACTERISTIC') {
          this.setState({ queryType: 'characteristic' });
        }
      });

    this.loadVehicleNode(200, 0);
    this.loadFamily(200, 0);
  }

  render() {

    return (
      <div>
        <ChapterTitle title={this.state.queryType === 'vehicleNode' ? 'Les pièces par modèle' : 'Les pièces par type de produit'} marginTop={25} />
        <div style={{ textAlign: 'center' }}>
          <List size="small" loading={this.state.dataLoadingVehicleNodes}
            grid={{ gutter: 8, column: this.columnCount() }}
            dataSource={this.state.vehicleNodes}
            renderItem={(item: IVehicleNode) => (
              <List.Item>
                <Card hoverable bodyStyle={{ textAlign: 'center', padding: 5, cursor: "pointer" }} onClick={() => this.onSearch(this.state.queryType, item.id, item.description, undefined, undefined)}>
                  {item.imageName !== null &&
                    <img src={'/api/public/catalog/' + item.imageName} alt={item.description} style={{ height: 65 }} />
                  }
                  <br />
                  {item.description}
                </Card>
              </List.Item>

            )}
          />
        </div>

        {this.state.families && this.state.families.length > 0 &&
          <div>
            <ChapterTitle title='Toutes les pièces' marginTop={25} />
            <List size="small" loading={this.state.dataLoadingFamilies}
              grid={{ gutter: 16, column: 4 }}
              dataSource={this.state.families}
              renderItem={(item: IFamily) => (
                <List.Item>
                  <Card title={item.name} headStyle={{ fontSize: '0.90em' }} bodyStyle={{ padding: 0 }}
                    extra={item.imageName !== null && <img src={'/api/public/catalog/' + item.imageName} alt={item.name} style={{ height: 30 }} />}>
                    <SubFamilies parent={item} childrens={item.children} onSearch={this.onSearch} />
                  </Card>
                </List.Item>
              )}
            />
          </div>
        }

      </div>
    );
  }

  private columnCount = (): ColumnCount => {
    switch (this.state.vehicleNodes.length) {
      case 1: return 1;
      case 2: return 2;
      case 3: return 3;
      case 4: return 4;
      case 5: return 6;
      case 6: return 6;
      case 7: return 8;
      default: return 8;
    }
  }

  private onSearch = (queryType: string, level1Id: number, level1Name: string, level2Id: number | undefined, level2Name: string | undefined) => {
    let catalogSearch: ICatalogSearch = {
      ...this.props.catalogSearch,
      queryType: queryType,
      level1Id: level1Id,
      level1Name: level1Name,
      level2Id: level2Id,
      level2Name: level2Name,
      level3Id: undefined,
      level3Name: undefined,
      level4Id: undefined,
      level4Name: undefined,
      level5Id: undefined,
      level5Name: undefined,
      modelYear: undefined,
    }
    this.props.setCatalogSearch(catalogSearch);

    this.props.setTypeSearch(3);
    this.props.history.push('/catalog');
  }

  private loadVehicleNode = (pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoadingVehicleNodes: true,
    });

    this.catalogService.findVehicleNode(undefined, undefined, undefined, true, pageSize, page, 'rank')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            vehicleNodes: result.content,
            dataLoadingVehicleNodes: false
          });
        }
        else {
          this.setState({
            dataLoadingVehicleNodes: false
          });
        }
      });
  }

  private loadFamily = (pageSize: number | undefined, page: number | undefined) => {

    this.setState({
      dataLoadingFamilies: true,
    });

    this.catalogService.findArticleFamily(undefined, 1, true, pageSize, page, 'name')
      .then((result: any) => {
        if (result !== undefined && result.content !== undefined) {
          this.setState({
            families: result.content,
            dataLoadingFamilies: false
          });
        }
        else {
          this.setState({
            dataLoadingFamilies: false
          });
        }
      });
  }
}

export default withRouter(withBasket(CatalogHome));