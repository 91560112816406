import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Input, Typography, Card } from 'antd';

import { withBasket, BasketContext } from '../../Utils/BasketProvider';
import ITecdocSearch from './model/ITecdocSearch';
import TecdocModelSearch from './component/TecdocModelSearch';
import TecdocVehicleInfo from './component/TecdocVehicleInfo';
import TecdocVehicle from './component/TecdocVehicle';
import TecdocBreadcrumb from './component/TecdocBreadcrumb';
import TecdocManufacturer from './component/TecdocManufacturer';
import TecdocModel from './component/TecdocModel';
import TecdocTreeSearchStructure from './component/TecdocTreeSearchStructure';
import TecdocSearchStructure from './component/TecdocSearchStructure';
import TecdocArticle from './component/TecdocArticle';
import TecdocService from './TecdocService';
import FlybyUtils from '../../Utils/FlybyUtils';
import TecdocVehicleList from './component/TecdocVehicleList';
import HeadbandSearch from '../home/HeadbandSearch';

const Search = Input.Search;
const { Text } = Typography;

class TecdocSearch extends React.Component<
  {
    history: any,
    tecdocSearch: ITecdocSearch,
    setTecdocSearch: any,
    clearTecdocSearch: any,
    setTypeSearch: any,
    setCustomProfil: any
  },
  {
  }>{

  private tecdocService: TecdocService = new TecdocService();
  private flybyUtils: FlybyUtils = new FlybyUtils();

  componentDidMount() {
    this.flybyUtils.setProfil(this.props.setCustomProfil);
  }

  render() {

    return (
      <BasketContext.Consumer>
        {(contextValues) =>
          <div>

            <HeadbandSearch showEtai={false} showTecdoc={false} onSearchArticle={undefined} onAdvancedQuery={undefined}
              onSearchEtai={undefined} cancelSearch={this.onCancelSearch} title={'Recherche de pièces par Tecdoc'} />

            <Row gutter={12}>

              <Col span={5} flex={"300px"}>

                <Card size="small" title="Recherche véhicule" bordered={true} headStyle={{ background: "rgb(236, 236, 236)" }}>
                  <div style={{ marginLeft: -10, marginRight: -10, marginBottom: -5, marginTop: -5 }}>

                    {contextValues.customProfil !== undefined && contextValues.customProfil.searchByPlateNumber &&
                      <div>
                        <div style={{ marginTop: 5 }}><Text strong>Par immatriculation</Text></div>
                        <Search placeholder="AA-123-BB" onSearch={(value: any) => this.onSearch(value, 'plateNumber')} style={{ width: "100%", marginTop: 4 }} enterButton
                          defaultValue={contextValues.tecdocSearch.query !== undefined ? contextValues.tecdocSearch.query.plateNumber : ''} />
                      </div>
                    }
                    {contextValues.customProfil !== undefined && contextValues.customProfil.searchByMineType &&
                      <div>
                        <div style={{ marginTop: 5 }}><Text strong>Par type mine</Text></div>
                        <Search placeholder="AAA000AA000" onSearch={(value: any) => this.onSearch(value, 'mineType')} style={{ width: "100%", marginTop: 4 }} enterButton
                          defaultValue={contextValues.tecdocSearch.query !== undefined ? contextValues.tecdocSearch.query.mineType : ''} />
                      </div>
                    }
                    <div>
                      <div style={{ marginTop: 5 }}><Text strong>Par code moteur</Text></div>
                      <Search placeholder="150DX" onSearch={(value: any) => this.onSearch(value, 'motorCode')} style={{ width: "100%", marginTop: 4 }} enterButton
                        defaultValue={contextValues.tecdocSearch.query !== undefined ? contextValues.tecdocSearch.query.motorCode : ''} />
                    </div>
                    <TecdocModelSearch />
                  </div>
                </Card>

                {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined 
                  && contextValues.tecdocSearch.vehicle.ktypNr !== null &&
                  <Card size="small" title="Familles articles" style={{ marginTop: 5 }} bordered={true} headStyle={{ background: "rgb(236, 236, 236)" }}>
                    <div style={{ marginLeft: -10, marginRight: -10, marginBottom: -5 }}>
                      <TecdocTreeSearchStructure />
                    </div>
                  </Card>
                }

                {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined 
                    && contextValues.tecdocSearch.vehicle.ktypNr !== null &&   
                  <Card size="small" title="Véhicule sélectionné" style={{ marginTop: 5 }} bordered={true} headStyle={{ background: "rgb(236, 236, 236)" }}>
                    <div style={{ marginLeft: -16, marginRight: -16, marginBottom: -16, marginTop: -16 }}>
                      <TecdocVehicleInfo />
                    </div>
                  </Card>
                }


              </Col>

              <Col span={19}>
                <TecdocBreadcrumb />

                {(contextValues.tecdocSearch.vehicle === undefined || contextValues.tecdocSearch.vehicle.herNr === undefined)
                  && contextValues.tecdocSearch.queryType === '' &&
                  <TecdocManufacturer />
                }

                {contextValues.tecdocSearch.vehicle === undefined
                  && (contextValues.tecdocSearch.queryType === 'motorCode' || contextValues.tecdocSearch.queryType === 'mineType') &&
                  <TecdocVehicleList />
                }

                {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.herNr !== undefined
                  && contextValues.tecdocSearch.vehicle.kmodNr === undefined &&
                  <TecdocModel />
                }

                {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.kmodNr !== undefined
                  && contextValues.tecdocSearch.vehicle.ktypNr === undefined &&
                  <TecdocVehicle />
                }

                {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.vehicle.ktypNr !== undefined
                  && contextValues.tecdocSearch.vehicle.ktypNr !== null 
                  && contextValues.tecdocSearch.searchStructure === undefined &&
                  <TecdocSearchStructure />
                }

                {contextValues.tecdocSearch.vehicle !== undefined && contextValues.tecdocSearch.searchStructure !== undefined &&
                  <TecdocArticle />
                }
              </Col>
            </Row>

          </div>
        }
      </BasketContext.Consumer>
    );
  }

  private onSearch = (query: any, queryType: string) => {
    if(query !== undefined && query !== ''){
      this.tecdocService.searchVehicle(query, queryType)
      .then((tecdocSearch: ITecdocSearch) => {

        if(tecdocSearch.query !== undefined){
          switch (queryType) {
            case "plateNumber":
              tecdocSearch.query.mineType = "";
              tecdocSearch.query.motorCode = "";
              tecdocSearch.query.vin = "";
            break;
            case "mineType":
              tecdocSearch.query.plateNumber = "";
              tecdocSearch.query.motorCode = "";
              tecdocSearch.query.vin = "";              
            break;
            case "vin":
              tecdocSearch.query.plateNumber = "";
              tecdocSearch.query.mineType = "";
              tecdocSearch.query.motorCode = "";
            break;      
            case "motorCode":
              tecdocSearch.query.plateNumber = "";
              tecdocSearch.query.mineType = "";
              tecdocSearch.query.vin = "";              
            break;                          
          }
        }

        this.props.setTypeSearch(0);
        this.props.setTecdocSearch(tecdocSearch);
      });
    }
  }

  private onCancelSearch = () => {
    this.props.clearTecdocSearch();
    this.props.setTypeSearch(0);
    this.props.history.push('/home');
  }
}

export default withRouter(withBasket(TecdocSearch));     