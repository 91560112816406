import IArticle from './IArticle';
import IWishlistArticle from './IWishlistArticles';
import IAvailability from './IAvailability';
import IArticleSearchResult from './IArticleSearchResult';
import IFilterQuery from '../../Utils/IFilterQuery';
import IMasterdataSupplier from './IMasterdataSupplier';
import FlybyUtils from '../../Utils/FlybyUtils';
import IData from '../../Utils/IData';
import IArticleUsage from './IArticleUsage';
import IArticleAvailabilityRequestInformation from './IArticleAvailabilityRequestInformation';
class ArticleService {

  private flybyUtils: FlybyUtils = new FlybyUtils();

  private accessToken = sessionStorage.getItem("accessToken");
  private customerId = sessionStorage.getItem("customerId");
  private manufacturersFilter = sessionStorage.getItem("manufacturersFilter");

  public async search(query: string, filterQuery: IFilterQuery, size: number | undefined, page: number | undefined): Promise<IArticleSearchResult | IData> {

    let runSearch = false;
    let params = 'customer=' + this.customerId;

    if (filterQuery.families.length > 0) {
      params += '&families=' + filterQuery.families.toString();
    }

    if (filterQuery.manufacturers.length > 0) {
      params += '&manufacturers=' + filterQuery.manufacturers.toString();
    }

    if (query !== "") {
      params += '&query=' + query;
      params += '&advancedQuery=' + filterQuery.extended;
      runSearch = true;
    }

    if (this.manufacturersFilter !== null && this.manufacturersFilter !== '') {
      params += '&manufacturers=' + this.manufacturersFilter;
    }

    if (runSearch) {
      return fetch('/api/market/articleSearchResults?' + params + '&size=' + size + '&page=' + page, {
        headers: {
          'Authorization': 'Bearer ' + this.accessToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then((response: any) => {
          return response.json();
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    return Promise.resolve(this.flybyUtils.getEmptyData());
  }

  public async articleAvailability(articleId: number, quantity: number, platformId: number | undefined, sourceWishlistArticleId?: number): Promise<IAvailability[]> {



    let url = 'article=' + articleId + '&customer=' + this.customerId + '&quantity=' + quantity;
    if (sourceWishlistArticleId !== undefined) {
      url += '&sourceWishlistArticle=' + sourceWishlistArticleId;
    }
    if (platformId !== undefined) {
      url += '&platform=' + platformId;
    }

    return fetch('/api/market/articleAvailabilities?' + url, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error(response.status + " - " + response.statusText);
      })
      .then((response: any) => {
        return response.content;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async searchArticleById(id: number): Promise<IArticleSearchResult> {

    let url = '?article=' + id + '&customer=' + this.customerId;
    if (this.manufacturersFilter !== null && this.manufacturersFilter !== '') {
      url += '&manufacturers=' + this.manufacturersFilter;
    }

    return fetch('/api/market/articleSearchResults' + url, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleById(id: number): Promise<IArticle> {

    return fetch('/api/market/articles/' + id + '?customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findMasterDataSupplier(dlnr: number): Promise<IMasterdataSupplier> {
    return fetch('/api/masterDataSuppliers/tecdocCode/' + dlnr + '?customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async searchArticleByReference(cleanReference: string, manufacturerTeccomCode: string): Promise<IArticle> {

    let url = '?cleanReference=' + cleanReference + '&manufacturerTeccomCode=' + manufacturerTeccomCode + '&customer=' + this.customerId;
    if (this.manufacturersFilter !== null && this.manufacturersFilter !== '') {
      url += '&manufacturers=' + this.manufacturersFilter;
    }

    return fetch('/api/market/articleSearchResults' + url, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async searchArticleByEanOrReference(ean: string, cleanReference: string, manufacturerTeccomCode: string): Promise<IArticle> {

    let url = '?ean=' + ean + '&cleanReference=' + cleanReference + '&manufacturerTeccomCode=' + manufacturerTeccomCode + '&customer=' + this.customerId;
    if (this.manufacturersFilter !== null && this.manufacturersFilter !== '') {
      url += '&manufacturers=' + this.manufacturersFilter;
    }

    return fetch('/api/market/articleSearchResults' + url, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleByEan(ean: string): Promise<IArticle> {

    return fetch('/api/market/articles?ean=' + ean + '&customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleByCleanReference(cleanReference: string, manufacturerTeccomCode: string): Promise<IArticle> {

    return fetch('/api/market/articles?cleanReference=' + cleanReference + '&manufacturerTeccomCode=' + manufacturerTeccomCode + '&customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findEquivalences(articleId: number, size: number | undefined, page: number | undefined): Promise<any> {

    return fetch('/api/market/equivalences?customer=' + this.customerId + '&article=' + articleId + '&size=' + size + '&page=' + page, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findTags(articleId: number, type: string | undefined): Promise<any> {

    let url = '/api/market/articleTags?customer=' + this.customerId + '&article=' + articleId + '&size=300';
    if (type !== undefined) {
      url += '&type=' + type;
    }

    return fetch(url, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async updateWishlistArticle(wishlistArticleId: number, wishlistArticles: IWishlistArticle): Promise<IWishlistArticle> {

    return fetch('/api/market/wishlistArticles/' + wishlistArticleId + '?customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: "PUT",
      body: JSON.stringify(wishlistArticles)
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async wishlistArticle(wishlistArticleId: number, refreshAvailability: boolean): Promise<IWishlistArticle> {

    return fetch('/api/market/wishlistArticles/' + wishlistArticleId + '?customer=' + this.customerId + '&refreshAvailability=' + refreshAvailability, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async transferWishlistArticle(wishlistArticleId: number, platformId: number, transferQuantity: number): Promise<IWishlistArticle> {

    let data = {

      "sourceWishlistArticle": {
        "id": wishlistArticleId
      },
      "quantity": transferQuantity
    };

    return fetch('/api/market/transferWishlistArticles?customer=' + this.customerId + '&platform=' + platformId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: "POST",
      body: JSON.stringify(data)
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findLogo(manufacturerId: number): Promise<string> {
    return fetch('/api/market/manufacturers/' + manufacturerId + '/logo', {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'image/jpeg',
        'Content-Type': 'image/jpeg',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob()
            .then(images => {
              let objectURL = URL.createObjectURL(images);
              return objectURL;
            })
        }
        else {
          return '';
        }
      })
      .catch((err: any) => {
        return '';
      });
  }

  public async findArticlePicture(articleId: number): Promise<string> {
    return fetch('/api/market/articles/' + articleId + '/image', {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'image/jpeg',
        'Content-Type': 'image/jpeg',
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.blob()
            .then(images => {
              let objectURL = URL.createObjectURL(images);
              return objectURL;
            })
        }
        else {
          return '';
        }
      })
      .catch((err: any) => {
        return '';
      });
  }

  public async searchCatalogArticle(vehicleTypeId: number | undefined, familyId: number | undefined, modelYear: number | undefined, size: number | undefined, page: number | undefined): Promise<IArticleSearchResult> {

    let uri = '/api/market/articleSearchResults?customer=' + this.customerId + '&size=' + size + '&page=' + page;

    if (vehicleTypeId !== undefined) {
      uri += '&vehicleType=' + vehicleTypeId
    }

    if (familyId !== undefined) {
      uri += '&family=' + familyId
    }

    if (modelYear !== undefined) {
      uri += '&articleCriterionFilter=(criterion.code==DDATE and value=le=' + modelYear + '0101)&articleCriterionFilter=(criterion.code==FDATE and value=ge=' + modelYear + '1231)'
    }

    if (this.manufacturersFilter !== null && this.manufacturersFilter !== '') {
      uri += '&manufacturers=' + this.manufacturersFilter;
    }

    return fetch(uri, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: any) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleUsage(id: number): Promise<IArticleUsage> {

    return fetch('/api/market/articles/' + id + '/usage?customer=' + this.customerId, {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        if (response.status === 200) {
          return response.json();
        }
        else {
          return undefined;
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async findArticleDocuments(articleId: number, size: number | undefined, page: number | undefined): Promise<any> {

    return fetch('/api/articles/' + articleId + '/articleDocuments?size=' + size + '&page=' + page + '&sort=name', {
      headers: {
        'Authorization': 'Bearer ' + this.accessToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then((response: Response) => {
        return response.json();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  public async postRequest(articleId: number, articleAvailabilityRequestInformation: IArticleAvailabilityRequestInformation): Promise<any> {

    return fetch('/api/market/articleAvailabilities/' + articleId + '/requestInformation', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      method: "POST",
      body: JSON.stringify(articleAvailabilityRequestInformation)
    })
      .then((response: any) => {
        return response;
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

}

export default ArticleService;