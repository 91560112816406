import React from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Link,
  Route,
  Switch as SwitchRouter,
} from "react-router-dom";
import "./App.css";

import {
  ContainerOutlined,
  FileDoneOutlined,
  HddOutlined,
  HistoryOutlined,
  HomeOutlined,
  IdcardOutlined,
  LogoutOutlined,
  MailOutlined,
  ReadOutlined,
  RollbackOutlined,
  ShoppingCartOutlined,
  StarOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Badge,
  Tooltip,
  ConfigProvider,
  Empty,
  Spin,
} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import frFR from "antd/es/locale/fr_FR";

import Home from "./containers/home/Home";
import Login from "./containers/login/Login";
import MyAccount from "./containers/myaccount/MyAccount";
import Mail from "./containers/mail/Mail";
import Bookmark from "./containers/bookmark/Bookmark";
import DocumentHistory from "./containers/document/DocumentHistory";
import ShoppingCart from "./containers/shoppingcart/ShoppingCart";
import Wishlist from "./containers/Wishlist";
import RecoveryIdentifier from "./containers/login/RecoveryIdentifier";
import BasketProvider, {
  BasketContext,
  withBasket,
} from "./Utils/BasketProvider";
import Footer from "./containers/Footer";
import LoginService from "./containers/login/LoginService";
import SalesOrderHistory from "./containers/shoppingcart/SalesOrderHistory";
import ImportSalesOrder from "./containers/shoppingcart/ImportSalesOrder";
import Return from "./containers/document/Return";
import ReturnRequest from "./containers/document/ReturnRequest";
import TecdocSearch from "./containers/tecdoc/TecdocSearch";
import NavigationProvider, {
  NavigationContext,
  GetMenuKey,
} from "./Utils/NavigationProvider";
import CatalogSearch from "./containers/catalog/CatalogSearch";
import PageRequest from "./containers/PageRequest";
import FlybyHeader from "./containers/Header";
import MarketParameterProvider, { MarketParameterContext } from "./Utils/MarketParameterContext";
import Title from "./Utils/Title";
import HeaderLogo from "./Utils/HeaderLogo";
import HeaderPhone from "./Utils/HeaderPhone";
import DocumentHistoryCustom from "./containers/document/DocumentHistoryCustom";
import BasketSwitch from "./Utils/BasketSwitch";
import Library from "./containers/library/Library";

const { Header, Content, Sider } = Layout;

const customizeRenderEmpty = () => <Empty description={"Pas de résultat"} />;

class App extends React.Component<
  {
    clearTecdocSearch: any;
  },
  {
    showWishlist: boolean;
    isAuthenticated: number;
    page: string | null;
    role: string;
  }
> {
  private loginService: LoginService = new LoginService();

  constructor(props: { clearTecdocSearch: any }) {
    super(props);

    this.state = {
      showWishlist: false,
      isAuthenticated: 0,
      page: "",
      role: ''
    };
  }

  componentDidMount() {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let token: string | null = urlParams.get("token");
    let page: string | null = urlParams.get("page");

    if (token !== null && page !== null) {
      this.externalAuthentication(token, page);
    } else {
      this.localAuthentication();
    }

  }

  render() {

    const selectedMenuKey = GetMenuKey();

    if (selectedMenuKey === "/recovery-identifier") {
      return (<MarketParameterProvider><Title /><RecoveryIdentifier /></MarketParameterProvider>);
    }

    if (this.state.isAuthenticated === 1) {
      return (<MarketParameterProvider><Login onLogin={this.onLogin} /></MarketParameterProvider>);
    }

    if (this.state.isAuthenticated === 2) {
      return (
        <Router>
          <MarketParameterProvider>
            <NavigationProvider>
              <ConfigProvider renderEmpty={customizeRenderEmpty} locale={frFR}>

                <Title />
                <BasketProvider>

                  <Layout style={{ height: "100vh" }}>
                    <Layout style={{ overflowY: "hidden", background: "#fff" }}>
                      <FlybyHeader />

                      <Header
                        style={{
                          background: "#fff",
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 5,
                        }}
                      >
                        <HeaderLogo />

                        <MarketParameterContext.Consumer>
                          {(appParms) => (

                            <NavigationContext.Consumer>
                              {(appMenu) => (
                                <Menu
                                  onClick={(selectedMenu) =>
                                    appMenu.updateMenuKey(selectedMenu.key)
                                  }
                                  selectedKeys={[appMenu.selectedMenuKey]}
                                  mode="horizontal"
                                >
                                  <Menu.Item key="/home">
                                    <Link to="/home">
                                      <HomeOutlined />
                                      Accueil
                                    </Link>
                                  </Menu.Item>
                                  <Menu.Item key="/mail">
                                    <Link to="/mail">
                                      <MailOutlined />
                                      Nous contacter
                                    </Link>
                                  </Menu.Item>
                                  <SubMenu
                                    key="/acccount"
                                    title={
                                      <span className="submenu-title-wrapper">
                                        <IdcardOutlined />
                                        Mon compte
                                      </span>
                                    }
                                    disabled={this.state.role === 'ROLE_GUEST'}
                                  >
                                    <Menu.Item key="/my-acccount">
                                      <Link to="/my-acccount">
                                        <UserOutlined />
                                        Mes informations
                                      </Link>
                                    </Menu.Item>
                                    <Menu.Item key="/documents-history">
                                      <Link to="/documents-history">
                                        <ReadOutlined />
                                        Mes documents
                                      </Link>
                                    </Menu.Item>

                                    {appParms.CUSTOM_DOCUMENTS_HISTORY !== null && appParms.CUSTOM_DOCUMENTS_HISTORY !== '' &&
                                      <Menu.Item key="/custom-documents-history">
                                        <Link to="/custom-documents-history">
                                          <ContainerOutlined />
                                          {appParms.CUSTOM_DOCUMENTS_HISTORY}
                                        </Link>
                                      </Menu.Item>
                                    }

                                  </SubMenu>

                                  <Menu.Item key="/returns" disabled={this.state.role === 'ROLE_GUEST'}>
                                    <Link to="/returns">
                                      <RollbackOutlined />
                                      Demandes de retour
                                    </Link>
                                  </Menu.Item>


                                  {appParms.DOCUMENT &&
                                    <Menu.Item key="/library" disabled={this.state.role === 'ROLE_GUEST'}>
                                      <Link to="/library">
                                        <HddOutlined />
                                        Documentation
                                      </Link>
                                    </Menu.Item>
                                  }
                                  <Menu.Item key="/bookmark" hidden={appParms.DISABLE_MARKET} disabled={this.state.role === 'ROLE_GUEST'}>
                                    <Link to="/bookmark">
                                      <StarOutlined />
                                      Mes favoris
                                    </Link>
                                  </Menu.Item>
                                  {!appParms.DISABLE_MARKET &&
                                    <SubMenu
                                      key="/sales-order"
                                      title={
                                        <span className="submenu-title-wrapper">
                                          <FileDoneOutlined />
                                          Mes commandes
                                        </span>
                                      }
                                      disabled={this.state.role === 'ROLE_GUEST'}
                                    >
                                      <Menu.Item key="/sales-order-history">
                                        <Link to="/sales-order-history">
                                          <HistoryOutlined />
                                          Historique des commandes
                                        </Link>
                                      </Menu.Item>
                                      {appParms.ENABLE_IMPORT_ORDER &&
                                        <Menu.Item key="/import-sales-order" disabled={this.state.role === 'ROLE_GUEST'}>
                                          <Link to="/import-sales-order">
                                            <UploadOutlined />
                                            Importer une commande
                                          </Link>
                                        </Menu.Item>
                                      }
                                    </SubMenu>
                                  }
                                  <Menu.Item key="/shopping-cart" hidden={appParms.DISABLE_MARKET} disabled={this.state.role === 'ROLE_GUEST'}>
                                    <BasketContext.Consumer>
                                      {(value) => (
                                        <Link to="/shopping-cart">
                                          <Badge
                                            count={value.wishlistTotal.totalQuantity}
                                            overflowCount={999}
                                            offset={[20, 0]}
                                            showZero={false}
                                          >
                                            <ShoppingCartOutlined />
                                            Mon panier
                                          </Badge>
                                        </Link>
                                      )}
                                    </BasketContext.Consumer>
                                  </Menu.Item>
                                  <Menu.Item style={{ float: "right" }} disabled hidden={appParms.DISABLE_MARKET}>
                                    <Tooltip title="Voir mon panier">
                                      <BasketSwitch onChange={this.onShowBasket} checked={this.state.showWishlist} role={this.state.role} />
                                    </Tooltip>
                                  </Menu.Item>
                                  <Menu.Item key="/logout" style={{ float: "right" }} onClick={this.onLogout}>
                                    <Tooltip title="Déconnexion">
                                      <span >
                                        <LogoutOutlined style={{ margin: 0 }} />
                                      </span>
                                    </Tooltip>
                                  </Menu.Item>
                                  <HeaderPhone />
                                </Menu>
                              )}
                            </NavigationContext.Consumer>

                          )}
                        </MarketParameterContext.Consumer>
                      </Header>

                      <Layout>
                        <Content
                          id="mainContent"
                          ref={"mainContent"}
                          style={{
                            overflowY: "auto",
                            background: "#fff",
                            paddingTop: 20,
                          }}
                        >
                          <div style={{ paddingRight: 20, paddingLeft: 20, }}>
                            <SwitchRouter>

                              {(this.state.role === 'ROLE_GUEST' && selectedMenuKey !== "/home" && selectedMenuKey !== "/mail") &&
                                <Redirect push to="/" />
                              }

                              <Route exact path="/" component={Home} />
                              <Route path="/index.html" component={Home} />
                              <Route path="/home" component={Home} />
                              <Route path="/my-acccount" component={MyAccount} />
                              <Route path="/mail" component={Mail} />
                              <Route path="/bookmark" component={Bookmark} />
                              <Route
                                path="/sales-order-history"
                                component={SalesOrderHistory}
                              />
                              <Route
                                path="/shopping-cart"
                                component={ShoppingCart}
                              />
                              <Route
                                path="/import-sales-order"
                                component={ImportSalesOrder}
                              />
                              <Route
                                path="/documents-history"
                                component={() => <DocumentHistory />}
                              />
                              <Route
                                path="/custom-documents-history"
                                component={() => <DocumentHistoryCustom />}
                              />
                              <Route
                                path="/returns"
                                component={() => <Return externalRequest={false} />}
                              />
                              <Route
                                path="/return-request"
                                component={() => (
                                  <ReturnRequest externalRequest={false} />
                                )}
                              />
                              <Route path="/library" component={Library} />
                              <Route path="/tecdoc" component={TecdocSearch} />
                              <Route path="/catalog" component={CatalogSearch} />
                            </SwitchRouter>
                          </div>
                          <Footer />
                        </Content>

                        <Sider
                          collapsed={this.state.showWishlist}
                          collapsedWidth={0}
                          style={{ background: "#fff" }}
                          width={250}
                        >
                          <Wishlist />
                        </Sider>
                      </Layout>
                    </Layout>
                  </Layout>

                </BasketProvider>


              </ConfigProvider>
            </NavigationProvider>
          </MarketParameterProvider>
        </Router>
      );
    }

    if (this.state.isAuthenticated === 3) {
      return (
        <Router>
          <ConfigProvider renderEmpty={customizeRenderEmpty} locale={frFR}>
            <MarketParameterProvider>
              <Title />
              <BasketProvider>
                <SwitchRouter>
                  <Route
                    path="/"
                    component={() => <PageRequest page={this.state.page} />}
                  />
                </SwitchRouter>
              </BasketProvider>
            </MarketParameterProvider>
          </ConfigProvider>
        </Router>
      );
    }

    if (this.state.isAuthenticated === 4) {
      return (
        <MarketParameterProvider>
          <Title />
          <div style={{ textAlign: "center", paddingTop: 40 }}>
            <Spin tip="Impossible de vous identifier..." size="large" />
          </div>
        </MarketParameterProvider>
      );
    }

    return (
      <MarketParameterProvider>
        <Title />
        <div style={{ textAlign: "center", paddingTop: 40 }}>
          <Spin tip="Chargement..." size="large" />
        </div>
      </MarketParameterProvider>
    );
  }

  private onLogin = (isAuthenticated: boolean) => {

    let accessToken = sessionStorage.getItem("accessToken");

    if (accessToken !== null) {
      this.loginService.session(accessToken).then((sessionData: any) => {

        this.setState({
          isAuthenticated: 2,
          role: sessionData ? sessionData.user.role.name : '',
        });
      });
    }

  };

  private onShowBasket = (checked: any) => {
    this.setState({ showWishlist: !checked });
  };

  private onLogout = () => {
    sessionStorage.clear();
    this.setState({
      isAuthenticated: 1,
    });
  };

  private localAuthentication = () => {
    let accessToken = sessionStorage.getItem("accessToken");

    if (accessToken !== null) {
      this.loginService.session(accessToken).then((sessionData: any) => {
        let isAuthenticated = 1;

        if (sessionData !== undefined) {
          isAuthenticated = 2;
        } else {
          sessionStorage.clear();
        }

        setTimeout(() => {
          this.setState({
            isAuthenticated: isAuthenticated,
            role: sessionData ? sessionData.user.role.name : '',
          });
        }, 500);
      });
    } else {
      this.setState({
        isAuthenticated: 1,
        role: '',
      });
    }
  };

  private externalAuthentication = (token: string, page: string) => {
    sessionStorage.setItem("accessToken", token);

    this.loginService.session(token).then((sessionData: any) => {
      let isAuthenticated = 4;

      if (sessionData !== undefined && token !== null) {
        this.loginService.setUserParams(token).then((success: any) => {
          if (success) {
            isAuthenticated = 3;
          }
        });
      } else {
        isAuthenticated = 4;
        sessionStorage.clear();
      }

      setTimeout(() => {
        this.setState({
          isAuthenticated: isAuthenticated,
          page: page,
          role: sessionData ? sessionData.user.role.name : '',
        });
      }, 500);
    });
  };
}

export default withBasket(App);
